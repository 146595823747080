<template>
  <div>
    <n-modal ref="modal" custom-class="branding-popup" title="" no-title>
      <div class="flex">
        <img
          class="hidden md:block"
          :style="{ width: '800px', maxWidth: '100%' }"
          src="../../assets/img/branding-popup.svg"
        />
        <img
          class="md:hidden"
          :style="{ maxWidth: '100%' }"
          src="../../assets/img/branding-popup-mobile.svg"
        />
      </div>
    </n-modal>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

const modal = ref(null);

onMounted(() => {
  // Calculate the end date as 8 weeks from August 1st.
  const eightWeeksFromLaunch = new Date("2023-08-01");
  eightWeeksFromLaunch.setDate(eightWeeksFromLaunch.getDate() + 8 * 7);

  let visitCount = localStorage.getItem("brandingPopupVisitCount");

  visitCount === null ? (visitCount = 1) : (visitCount = parseInt(visitCount));

  if (visitCount <= 2 && new Date() <= eightWeeksFromLaunch) {
    modal.value.open();

    localStorage.setItem(
      "brandingPopupVisitCount",
      (visitCount + 1).toString(),
    );
  }
});
</script>

<style lang="scss">
.branding-popup {
  line-height: 1.5;

  .n-button-icon {
    transform: scale(2);
    margin-right: 10px;
    margin-top: 10px;
    &:hover {
      .material-design-icon {
        color: lighten(#b8336a, 10%);
      }
      background: none !important;
    }
    &:active {
      .material-design-icon {
        color: lighten(#b8336a, 20%);
      }
      background: none !important;
    }
  }
  .material-design-icon {
    color: #b8336a;
  }
  .n-modal-container {
    border-radius: 0;
    padding: 0 !important;
    width: auto;
  }
  .n-modal-body {
    padding: 0 !important;
  }
}
</style>
