<template>
  <section
    :style="`background-color: ${colors.faqBg}!important`"
    class="text-white pt-8 md:pt-12 pb-5"
  >
    <div class="inner-container px-3 md:px-8">
      <h2
        :class="companyName == 'Humana' ? 'lg:text-xl' : ''"
        class="text-center mb-4 font-bold md:text-lg leading-5 mt-5"
      >
        Frequently asked questions
      </h2>
      <landing-faq-item question="What is Nymbl?" :answer="faqAnswer1" />
      <landing-faq-item question="Who is Nymbl for?" :answer="faqAnswer2" />

      <landing-faq-item question="How does Nymbl work?" :answer="faqAnswer3" />

      <landing-faq-item question="Why am I being offered balance training?">
        <div v-if="companyName === 'fallsfree' || companyName === 'Sales Demo'">
          <p>
            Your care team is committed to helping you reduce your risk of
            falling. Together, with Nymbl, our goal is to help you live your
            best life, free of falls. Nymbl can help improve your balance and
            decrease your fall risk.
          </p>
        </div>
        <div v-else-if="companyName === 'Blue KC'">
          <p>
            Your well-being is our top priority, and we’re always looking for
            opportunities to help you achieve your best health. That’s why Blue
            KC teamed up with Nymbl, a scientifically proven² balance training
            program that can improve balance by 30%², at home. Our goal is to
            help you live your best life, free of falls.
          </p>
        </div>
        <div v-else-if="companyName === 'John Hancock'">
          Your well-being is our top priority, and we’re always looking for
          opportunities to help you achieve your best health. That’s why John
          Hancock teamed up with Nymbl to offer you Nymbl’s balance training
          program. Our goal is to help you live your best life.
        </div>
        <div v-else-if="companyName === 'Blue MI'">
          We’re always looking for opportunities to help you achieve your best
          health, and your balance is an important part of your health. Nymbl is
          scientifically proven² balance training program that can improve
          balance by 30%², at home. Our goal is to help you live your best life.
        </div>
        <div v-else-if="companyName === 'Humana'">
          At Humana, we’re always looking for opportunities to help you on your
          journey to achieve your best health. That’s why we teamed up with
          Nymbl, a scientifically proven<sup>2</sup> balance training program
          that can improve balance by 30%<sup>1</sup>, at home. Our goal is to
          help you live your best life.
        </div>
        <div v-else>
          {{
            companyName === "DRCOG"
              ? "Here in Colorado"
              : "At " + companyDisplayName
          }}
          we’re always looking for opportunities to help you on your journey to
          achieve your best health. That’s why we teamed up with Nymbl, a
          scientifically-proven<sup>1</sup> balance training program that can
          improve balance by 30%<sup>1</sup>, at home. Our goal is to help you
          live your best life, free of falls.
        </div>
      </landing-faq-item>

      <landing-faq-item
        question="What kind of exercises will I be doing with Nymbl?"
        :answer="faqAnswer5"
      />
      <landing-faq-item
        question="What special equipment do I need for balance training?"
        :answer="faqAnswer6"
      />

      <landing-faq-item
        v-if="companyName === 'John Hancock'"
        question="What data is being collected from Nymbl and how will it be used?"
      >
        Nymbl collects your personal information, such as name, date of
        birth,email and zip code at the time of enrollment, as well as
        engagement data within the Nymbl balance training app. This data is used
        to improve the Nymbl product, enhance the user experience, track
        progress, measure outcomes, and deliver meaningful insights. Nymbl will
        only share aggregated information with John Hancock to administer and
        evaluate the fall prevention program. Nymbl does not sell or share
        member data with any advertisers or marketers. For Nymbl’s full Privacy
        Policy, click
        <a
          class="underline hover:no-underline text-white hover:text-gray-100"
          href="https://nymblscience.com/privacy/"
          target="_blank"
          rel="noopener"
          >here.</a
        >
      </landing-faq-item>

      <div v-if="!isEnrollmentDisabled" class="text-center">
        <n-button
          :style="getCTAstyle()"
          rounded-xxl
          variant="white"
          class="mt-2 mb-4 md:mb-8"
          @click="$emit('cta')"
        >
          Get started with Nymbl
        </n-button>
      </div>
    </div>
  </section>
</template>

<script>
import LandingFaqItem from "./LandingFaqItem.vue";
export default {
  components: {
    LandingFaqItem,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    isEnrollmentDisabled: Boolean,
    colors: Object,
  },
  computed: {
    faqAnswer1() {
      const companyName = this.companyName;
      if (companyName === "fallsfree" || companyName === "Sales Demo") {
        return "Nymbl is an easy, personalized balance training app that you can do in the privacy and comfort of your home - in just 10 minutes a day. All you need is a smartphone or tablet! It’s free and fully covered through select organizations.";
      }
      if (companyName === "DRCOG") {
        return "Nymbl is an easy, personalized balance training app that you can do in the privacy and comfort of your home - in just 10 minutes a day. It’s fully covered by the State of Colorado and is free to Coloradans ages 60 and older! Older adults who sign up will receive a customized balance training program delivered conveniently on their smartphone or tablet.";
      }
      if (companyName === "Blue KC") {
        return "Nymbl is an easy, personalized balance training app that you can use in the privacy and comfort of your home – in just 10 minutes a day. All you need is a smartphone or tablet! Don’t have a smartphone or tablet? No problem! Nymbl can still help you reduce your risk of falling with educational content delivered to you via email. Nymbl is fully covered by Blue KC and available at no cost to you.";
      }
      if (companyName === "Blue MI") {
        return "Nymbl is an easy, personalized balance training app that you can do in the privacy and comfort of your home – in just 10 minutes a day. All you need is a smartphone or tablet! Don’t have a smartphone or tablet? No problem! You have instant access to an online balance assessment and weekly educational content via email. It’s fully covered by your health plan and free to you.";
      }
      if (companyName === "John Hancock") {
        return "Nymbl is a simple balance training app that you can use in the privacy of your home for just 10 minutes a day. All you need is a smartphone or tablet! Don’t have a smartphone or tablet? No problem! Nymbl can still help you reduce your risk of falling with educational content via email. Access to Nymbl is made available to you by John Hancock at no additional cost.";
      }
      if (companyName === "Humana") {
        return "Nymbl is an easy, personalized balance training app that you can do in the privacy and comfort of your home – in just 10 minutes a day. All you need is a smartphone or tablet! Don’t have a smartphone or tablet? No problem! You have instant access to an online balance test and weekly educational content via email. It’s offered to you by Humana and available through your Humana plan at no additional cost to you.";
      }
      return `Nymbl is an easy, personalized balance training app that you can do in the privacy and comfort of your home - in just 10 minutes a day. All you need is a smart phone or tablet. It’s fully covered by ${this.companyDisplayName} and available at no additional cost to you.`;
    },
    faqAnswer2() {
      // Who is Nymbl for?
      const companyName = this.companyName;
      if (
        companyName === "fallsfree" ||
        companyName === "DRCOG" ||
        companyName === "Blue KC" ||
        companyName === "Sales Demo"
      ) {
        return "Nymbl is a great choice for you if you’re looking to improve or maintain your balance in the comfort and safety of your home. To do Nymbl Training, all you need is a smartphone or tablet! If you don’t have a smartphone or tablet, you can still receive valuable educational content with helpful balance tips, sent straight to your email.";
      }
      if (companyName === "John Hancock") {
        return "Nymbl is a great choice for those looking to improve or maintain balance and stay active. It is also for anyone with a history of falling who wants to prevent additional falls.";
      }
      if (companyName === "Blue MI") {
        return "Nymbl is free to you, 100% paid by your health plan. It’s a great choice for you if you’re looking to improve or maintain your balance and stay active.";
      }
      if (companyName === "Humana") {
        return "Nymbl is available to eligible Humana members at no additional cost. It’s a great choice for you if you’re looking to improve or maintain your balance and stay active.";
      }
      return `Nymbl is available to eligible ${this.companyDisplayName} members at no additional cost. It’s a great choice for you if you’re looking to improve or maintain your balance in the comfort and safety of your home. All you need is a smart phone or tablet! `;
    },
    faqAnswer3() {
      // How does Nymbl work?
      const companyName = this.companyName;
      if (
        companyName === "fallsfree" ||
        companyName === "DRCOG" ||
        companyName === "Blue KC" ||
        companyName === "Sales Demo"
      ) {
        return "Research shows that falls happen when we are distracted, not when we are focused on our balance¹. Nymbl helps prepare you for these real-life experiences and distractions by combining simple body exercises (that you normally do in your daily routine, such as lifting your foot over a rug) with brain games (like trivia) to challenge your body and mind at the same time. Nymbl’s scientifically proven² app can improve balance by 30%²!";
      }

      if (companyName === "John Hancock") {
        return "Research shows that falls happen when we are distracted, not when we are focused on our balance¹. Nymbl helps prepare you for these real-life experiences and distractions by combining simple body movements (that you normally do in your daily routine, such as lifting your foot over a rug) with brain games (like trivia) to challenge your body and mind at the same time.";
      }
      if (companyName === "Blue MI") {
        return "Research shows that falls happen when we are distracted, not when we are focused on our balance¹. Nymbl helps prepare you for these real-life experiences and distractions by combining simple body exercises (that you normally do in your daily routine, such as lifting your foot over a rug) with brain games (like trivia) to challenge your body and mind at the same time. Nymbl’s scientifically proven² app can improve balance by 30%²!";
      }
      if (companyName === "Humana") {
        return "Research shows that falls happen when we are distracted, not when we are focused on our balance². Nymbl helps prepare you for these real-life experiences and distractions by combining simple body exercises (that you normally do in your daily routine, such as lifting your foot over a rug) with brain games (like trivia) to challenge your body and mind at the same time. Nymbl’s scientifically proven¹ app can improve balance by 30%¹!";
      }
      return "Research shows that falls happen when we are distracted, not when we are focused². Nymbl helps prepare you for these real-life experiences and distractions by combining simple body exercises (that you normally do in your daily routine, such as lifting your foot over a rug) with brain games (like trivia) to challenge your body and mind at the same time. Nymbl’s scientifically-proven¹ app can improve balance by 30%¹!";
    },
    faqAnswer5() {
      const companyName = this.companyName;
      if (companyName === "fallsfree" || companyName === "Sales Demo") {
        return "Nymbl’s physical component involves simple body movements that you do in your daily routine (such as raising your foot over a rug). The more you do Nymbl, the more it adjusts with your progress, keeping you safe, challenged, and improving your balance.";
      }

      if (this.companyName === "John Hancock") {
        return "Nymbl involves simple body exercises such as marching in place and shifting your weight to each leg. For those who are seated, Nymbl offers exercises to best suit your needs. These simple body exercises are done in combination with fun brain games, like trivia, to challenge your body and mind at the same time.";
      }
      if (this.companyName === "Blue MI") {
        return "Nymbl involves simple body exercises such as marching in place and shifting your weight to each leg. For those who are seated, Nymbl offers exercises to best suit your needs. These simple body exercises are done in combination with fun brain games, like trivia.";
      }
      if (this.companyName === "Humana") {
        return "Nymbl involves simple body exercises such as marching in place and shifting your weight to each leg. For those who are seated, Nymbl offers exercises to best suit your needs. These simple body exercises are done in combination with fun brain games, like trivia.";
      }
      return "Nymbl’s physical component involves simple body movements that you do in your daily routine (such as raising your foot over a rug). The more you do Nymbl, the more it adjusts with your progress, keeping you safe, challenged, and improving your balance. If you think you may have a medical emergency during use of the program, call your doctor or emergency medical phone number immediately.";
    },
    faqAnswer6() {
      if (
        this.companyName === "John Hancock" ||
        this.companyName === "Blue MI" ||
        this.companyName === "Humana"
      ) {
        return "To do Nymbl’s balance training app, all you need is a smartphone or tablet. No weights or other exercise equipment is needed!";
      }

      return "To do Nymbl’s balance training app, all you need is a smartphone or tablet! Click on the button below to enroll, and you will receive easy, step-by-step instructions on how to download and use Nymbl. If you have any questions, please contact Nymbl’s friendly support team. We’re here to help you every step of the way.";
    },
  },
  methods: {
    getCTAstyle() {
      if (this.companyName === "Blue KC") {
        return "";
      }
      if (this.companyName === "John Hancock") {
        return `color: #fff!important; background: #ec6453!important`;
      }
      return `color: ${this.colors.ctaButton}!important`;
    },
  },
};
</script>
