<template>
  <section
    :style="`background-color: #fff!important;`"
    class="text-center my-2 pb-12 rounded-xl mx-0 md:mx-10 border-2 border-green-100 mt-3 relative z-50"
  >
    <h2
      class="pt-6 mb-4 mt-4 font-bold leading-6 md:leading-8 md:pt-10 text-lg px-2"
    >
      {{ title }}
    </h2>

    <div
      :style="`color: ${colors.text}!important; max-width: 1300px; margin: 0 auto;`"
      class="lg:grid lg:grid-cols-3 lg:gap-8 lg:pt-10 lg:mt-2"
    >
      <LandingReviewBlueMi
        :colors="colors"
        class="md:mb-12 md:mt-12 lg:m-0"
        :img="getReviewImage(0)"
        name="Brenda W."
        review="Before Nymbl, my body was stiff. Nymbl helps me move and makes my body feel better."
      />
      <LandingReviewBlueMi
        :colors="colors"
        class="md:mb-12 lg:m-0"
        :img="getReviewImage(1)"
        name="Hunter L."
        review="I am a new man after two weeks of using Nymbl. I’m having fun again."
      />
      <LandingReviewBlueMi
        :colors="colors"
        class="md:mb-2 lg:m-0"
        :img="getReviewImage(2)"
        name="Dianne E. "
        review="Nymbl is a fun experience. I never thought playing Simon would improve my balance."
      />
    </div>
  </section>
</template>

<script>
import LandingReviewBlueMi from "./LandingReviewBlueMi.vue";

export default {
  components: {
    LandingReviewBlueMi,
  },
  props: {
    colors: Object,
    companyName: String,
  },
  computed: {
    title() {
      return "See why people like you love Nymbl";
    },
  },
  methods: {
    getReviewImage(index) {
      if (index === 0) {
        return "Nymbl-testimonial-CarePlus-1.jpg";
      }
      if (index === 1) {
        return "Nymbl-testimonial-CarePlus-2.jpg";
      }
      if (index === 2) {
        return "Nymbl-testimonial-CarePlus-3.jpg";
      }
    },
  },
};
</script>
