<template>
  <div
    id="landing-page-drcog"
    class="m-auto font-roboto"
    style="max-width: 3800px"
  >
    <landing-embedded-video
      :company-name="companyName"
      @trackEvent="createEvent"
    />

    <landing-nav
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('nav')"
    />

    <landing-header
      id="top"
      class="mt-10 md:mt-20"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="'DRCOG'"
      :video-id="$i18n.locale === 'es' ? '849266412' : '849266118'"
      :company-display-name="companyDisplayName"
      :colors="colors"
      :anonymous-feature-flags="anonymousFeatureFlags"
      :anonymous-feature-flags-loaded="anonymousFeatureFlagsLoaded"
      @cta="callToAction('header')"
      @track-event="createEvent"
    />

    <landing-how
      id="about"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('how it works')"
      @ctaSpot="callToAction('reserve spot')"
    />

    <landing-reviews
      id="testimonials"
      :company-name="companyName"
      :colors="colors"
    />

    <landing-reasons
      id="why"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      hide-sub-header
      :colors="colors"
      @cta="callToAction('reason icons')"
    />

    <landing-faq
      id="faq"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('faq')"
    />

    <landing-support
      :company-name="companyName"
      :text-color="colors.supportText"
      :title-color="colors.supportTitle"
    />

    <landing-partners id="partners" @track-event="createEvent" />

    <landing-horizontal-line :color="colors.line" />

    <landing-terms
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :text-color="colors.text"
    />

    <footer class="flex justify-center">
      <landing-logo is-footer :company-name="companyName" />
    </footer>

    <transition name="fade">
      <div
        v-show="scrollTopY > 300"
        id="pagetop"
        class="fixed left-0 bottom-0 cursor-pointer ml-4 mb-4 z-50"
        @click="toTop"
      >
        <div
          style="
            background: #fff;
            width: 58px;
            height: 58px;
            border-radius: 50%;
          "
          class="flex items-center justify-center rounded-full"
        >
          <ArrowUpDropCircle
            size="66"
            :fill-color="colors.textOrange"
            @click="handleArrowClick('prev')"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import tailwindConfig from "nymbl-ui/tailwind.config";
import LandingFaq from "./LandingFAQDRCOG.vue";
import LandingLogo from "../LandingLogo.vue";
import LandingHow from "./LandingHowDRCOG.vue";
import LandingPartners from "./LandingPartnersDRCOG.vue";

import LandingReasons from "../LandingReasons.vue";
import LandingReviews from "./LandingReviewsDRCOG.vue";
import LandingTerms from "./LandingTermsDRCOG.vue";
import LandingSupport from "../LandingSupport.vue";
import LandingHeader from "./LandingHeaderDRCOG.vue";
import LandingNav from "./LandingNavDRCOG.vue";

import LandingEmbeddedVideo from "../LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "../LandingHorizontalLine.vue";

import { useStore } from "@/store.js";

import LandingPageBase from "../LandingPageBase.vue";

import ArrowUpDropCircle from "vue-material-design-icons/ArrowUpDropCircle.vue";
import { showChatWidget, hideChatWidget } from "@/helpers";

export default {
  components: {
    LandingReviews,
    LandingReasons,
    LandingFaq,
    LandingHow,
    LandingPartners,
    LandingLogo,
    LandingNav,
    LandingSupport,
    LandingTerms,
    LandingHeader,

    LandingEmbeddedVideo,
    LandingHorizontalLine,
    ArrowUpDropCircle,
  },
  extends: LandingPageBase,
  setup() {
    const store = useStore();
    return { store };
  },

  data() {
    return {
      companyName: "DRCOG",
      anonymousFeatureFlagsLoaded: false,
      anonymousSettings: null,
      mobileDevice: null,
      scrollTopTimer: 0,
      scrollTopY: 0,
    };
  },
  computed: {
    colors() {
      const colors = {
        text: "#000",
        textOrange: "#B9336A",
        green: "#084D33",
        title: "#000",
        supportText: "#000",
        supportTitle: "#000",
        howItWorksBorder: "#084D33",
        howItWorksTitle: "#000",
        howItWorksTitleSecond: "#084D33",
        ctaButton: "#B9336A",
        ctaButtonSecondary: "#B9336A",
        reviewsBg: "#084D33",
        bg: "#084D33",
        bgLight: "#F7F9F0",
        faqBg: "#084D33",
        line: "#084D33",
      };

      return colors;
    },
    colorPalette() {
      return tailwindConfig.theme.extend.colors;
    },
    isEnrollmentDisabled() {
      return false;
    },
  },
  unmounted() {
    hideChatWidget();
  },
  mounted() {
    this.$nextTick(() => {
      showChatWidget();
    });

    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll: function () {
      if (this.scrollTopTimer) return;
      this.scrollTopTimer = setTimeout(() => {
        this.scrollTopY = window.scrollY;
        clearTimeout(this.scrollTopTimer);
        this.scrollTopTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.new-ui {
  background: #fff !important;
  font-size: 24px !important;
}

#landing-page-drcog {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
