<script setup>
import { computed, onBeforeMount, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import Web from "vue-material-design-icons/Web.vue";

const route = useRoute();

let { locale, availableLocales } = useI18n();

onBeforeMount(() => {
  if (route.query.lang === "es" || route.meta.lang === "es") {
    locale.value = "es";
  }
});

const options = computed(() => {
  return availableLocales.map((locale) => ({
    label: locale === "en" ? "English" : "Español",
    value: locale,
  }));
});

watch(
  () => locale.value,
  (lang) => {
    route.query.lang = lang;
    if (lang !== "en") {
      window.document.title = "Obtenga Nymbl ahora";

      locale.value = "es";
    } else {
      window.document.title = "Get Nymbl now";
    }
  },
);
</script>
<template>
  <div class="locale-changer flex items-center text-gray-400">
    <Web class="mr-2 ml-2" />

    <div>
      <n-select
        v-model="$i18n.locale"
        class="mb-0"
        container-classes="language-switcher-select"
        style="min-width: 100px"
        :placeholder="$t('eligibility.select')"
        name="Language"
        size="sm"
        :options="options"
      />
    </div>
  </div>
</template>
<style lang="scss">
.language-switcher-select {
  font-size: 20px;
  height: 1.9rem !important;
  min-height: 1.9rem !important;

  .n-select__display {
    font-size: 20px;
  }
  .n-select-option__basic {
    font-size: 20px;
  }
  .n-select__display-value {
    font-size: 20px;
  }
  svg {
    width: 20px;
    height: 20px;
  }
}
</style>
