<template>
  <header>
    <nav
      class="flex flex-wrap items-center justify-between w-full py-0 md:py-0 px-1 lg:px-4 text-base text-green-100 font-bold transition duration-500 ease-in-out lg:fixed top-0 border-b-4"
      :style="{
        borderColor: colors.green,
        zIndex: 99999,
        background: colors.bgLight,
      }"
    >
      <div
        :style="{ maxWidth: '1800px' }"
        class="mx-auto px-0 w-full flex justify-center items-center lg:justify-start lg:items-center flex-col lg:flex-row"
      >
        <div
          class="flex items-center justify-center lg:justify-start lg:items-start w-full lg:w-auto"
        >
          <a
            v-smooth-scroll="{
              duration: 1000,
              offset: -150,
              updateHistory: false,
            }"
            href="#top"
            class="flex flex-col lg:flex-row items-center"
          >
            <img
              src="./lsfl-logo.png"
              style="max-width: 250px !important"
              class="md:mb-0 lg:mr-2"
            />
            <img
              src="./acc-logo-full.png"
              style="max-width: 250px !important"
              class="md:mb-0 lg:mr-4"
            />
            <a href="https://nymblscience.com/" target="_blank" rel="noopener">
              <img
                style="max-width: 190px !important; min-width: 190px !important"
                class="my-3 lg:my-0 lg:mr-6"
                src="../../../assets/img/nymblscience-logo.svg"
            /></a>
          </a>
          <a
            class="absolute top-4 right-4 xl:hidden cursor-pointer"
            @click="toggleMenu"
            ><Menu
          /></a>
        </div>

        <transition name="fade">
          <div
            class="w-full xl:flex md:items-center"
            :class="isMenuShown ? 'flex' : 'hidden'"
          >
            <ul
              class="w-full mb-4 px-4 sm:px-0 lg:mb-0 text-sm sm:flex sm:justify-between sm:items-center sm:pt-0"
            >
              <nav-item
                v-for="item in navItems"
                :key="item.url"
                :text="item.text"
                :url="item.url"
              />

              <li class="flex items-center">
                <n-button
                  class="mt-2 sm:mt-0 sm:ml-2 xl:ml-4"
                  container-classes="py-2"
                  :style="{
                    paddingTop: '7px!important',
                    paddingBottom: '7px!important',
                    backgroundColor: `${colors.ctaButtonSecondary}!important`,
                    color: '#fff',
                  }"
                  rounded-xxl
                  @click="$emit('cta')"
                >
                  Get Nymbl
                </n-button>
              </li>
            </ul>
          </div>
        </transition>
      </div>
      <landing-horizontal-line :color="colors.line" />
    </nav>
  </header>
</template>

<script setup>
import { ref } from "vue";
import NavItem from "../NavItem.vue";
import LandingHorizontalLine from "../LandingHorizontalLine.vue";
import Menu from "vue-material-design-icons/Menu.vue";

const isMenuShown = ref(false);

const toggleMenu = () => {
  isMenuShown.value = !isMenuShown.value;
};

const navItems = [
  {
    text: "About Nymbl",
    url: "#about",
  },
  {
    text: "Testimonials",
    url: "#testimonials",
  },
  {
    text: "Why Nymbl",
    url: "#why",
  },

  {
    text: "FAQ",
    url: "#faq",
  },
];

defineProps({
  companyName: {
    type: String,
    required: true,
  },
  colors: {
    type: Object,
    required: true,
  },
});

defineEmits(["cta"]);
</script>
