<template>
  <div style="max-width: 10rem" class="text-center mt-4 m-auto">
    <div
      style="max-width: 6rem"
      class="h-20 flex justify-center items-center mx-auto"
    >
      <img
        class="m-auto mb-2"
        :class="padding ? 'p-3' : ''"
        :src="getImgUrl(img)"
      />
    </div>
    <p
      :class="{
        'leading-4': companyName === 'Humana',
        'leading-5': companyName !== 'Humana',
        'mt-2': !isPadding,
        'mt-6': isPadding,
      }"
    >
      <span v-html="text" /><sup v-if="sup">{{ sup }}</sup>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    img: String,
    text: String,
    sup: [String, Boolean],
    padding: Boolean,
    companyName: String,
  },
  computed: {
    isPadding() {
      const companies = ["DRCOG", "ACC", "Blue MI"];
      return companies.includes(this.companyName);
    },
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/img/FallsFree/" + pic);
    },
  },
};
</script>
