<template>
  <div
    v-if="isLoaded"
    id="landing-page"
    class="m-auto"
    style="max-width: 3800px"
    :style="`color: ${colors.text}!important`"
  >
    <landing-embedded-video
      :company-name="companyName"
      @trackEvent="createEvent"
    />

    <div
      class="inner-container flex-col md:flex-row flex justify-center items-center"
    >
      <landing-logo :company-name="companyName" />
      <LanguageSwitcher class="mr-0 lg:mr-4" />
    </div>

    <landing-horizontal-line :color="colors.line" />

    <landing-header-care-plus
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :company-display-name="companyDisplayName"
      :colors="colors"
      :video-id="$i18n.locale == 'es' ? '846306914' : '849266118'"
      @cta="callToAction('header')"
      @trackEvent="createEvent"
    />

    <landing-reviews :company-name="companyName" :colors="colors" />

    <landing-reasons
      hide-sub-header
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('reason icons')"
    />

    <landing-how
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('how it works')"
      @ctaSpot="callToAction('reserve spot')"
    />

    <landing-faq
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('faq')"
    />

    <landing-support
      :company-name="companyName"
      :text-color="colors.supportText"
      :title-color="colors.supportTitle"
    />
    <div class="flex justify-center">
      <landing-logo is-footer :company-name="companyName" />
    </div>

    <landing-horizontal-line :color="colors.line" />

    <landing-terms
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :text-color="colors.text"
    />

    <footer>
      <div class="inner-container">
        <p
          v-if="DINNumberFooter"
          class="text-sm md:text-base md:leading-4 my-10 text-center"
        >
          {{ DINNumberFooter() }}
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import tailwindConfig from "nymbl-ui/tailwind.config";
import LandingFaq from "./LandingFAQCarePlus.vue";
import LandingLogo from "../LandingLogo.vue";
import LandingHow from "./LandingHowCarePlus.vue";
import LandingReasons from "./LandingReasonsCarePlus.vue";
import LandingReviews from "./LandingReviewsCarePlus.vue";
import LandingTerms from "./LandingTermsCarePlus.vue";
import LandingSupport from "./LandingSupportCarePlus.vue";
import LandingHeaderCarePlus from "./LandingHeaderCarePlus.vue";
import LandingEmbeddedVideo from "../LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "../LandingHorizontalLine.vue";
import { useStore } from "@/store.js";
import LandingPageBase from "../LandingPageBase.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  components: {
    LandingReviews,
    LandingReasons,
    LandingFaq,
    LandingHow,
    LandingLogo,
    LanguageSwitcher,
    LandingSupport,
    LandingTerms,
    LandingHeaderCarePlus,
    LandingEmbeddedVideo,
    LandingHorizontalLine,
  },
  extends: LandingPageBase,

  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      companyName: "CarePlus",
      anonymousFeatureFlagsLoaded: false,
      anonymousSettings: null,
      mobileDevice: null,
      isLoaded: false,
    };
  },
  computed: {
    colors() {
      const colors = {
        text: "#000",
        title: "#000",
        supportText: "#000",
        supportTitle: "#000",
        howItWorksBorder: this.colorPalette.green[150],
        howItWorksTitle: "#000",
        howItWorksTitleSecond: this.colorPalette.green[150],

        ctaButton: this.colorPalette.green[150],
        reviewsBg: this.colorPalette.green[150],
        faqBg: this.colorPalette.green[150],
        line: this.colorPalette.green[150],
      };

      if (this.companyName === "CarePlus") {
        colors.title = "#0062a6";
        colors.howItWorksTitleSecond = "#0062a6";
        colors.ctaButton = "#0062a6";
        colors.supportTitle = "#0062a6";
        colors.howItWorksBorder = "#0062a6";
        colors.reviewsBg = "#0062a6";
        colors.line = "#c3202f";
        colors.faqBg = "#0062a6";
      }

      return colors;
    },
    colorPalette() {
      return tailwindConfig.theme.extend.colors;
    },
    isEnrollmentDisabled() {
      return false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoaded = true;
    });
  },

  methods: {
    DINNumberFooter() {
      return "H1019_SMCPNymblLP2022_C";
    },
    getLogoPath() {
      return require("../../../assets/img/FallsFree/" +
        "2023_LOGO_CAREPLUS_NYMBL.png");
    },
  },
};
</script>

<style lang="scss">
.new-ui {
  background: #fff !important;
  font-size: 24px !important;
}
#landing-page {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
