<template>
  <section class="text-center my-4 pt-2 md:pt-5">
    <div class="inner-container px-2 md:px-4">
      <h2
        :style="`color: ${colors.title}`"
        class="font-bold md:leading-6 mb-3 md:mb-6 md:text-lg"
      >
        {{ title }}
      </h2>

      <div class="flex flex-col lg:flex-row mt-6">
        <landing-icon
          :padding="true"
          :img="valuePropIcon1"
          :text="valuePropText1"
          :company-name="companyName"
        />
        <landing-icon
          :company-name="companyName"
          :padding="true"
          :img="valuePropIcon2"
          :text="valuePropText2"
        />
        <landing-icon
          :company-name="companyName"
          :padding="true"
          :sup="'1'"
          :img="valuePropIcon3"
          :text="valuePropText3"
        />

        <landing-icon
          :company-name="companyName"
          :padding="true"
          :sup="'1'"
          :img="valuePropIcon4"
          :text="valuePropText4"
        />
        <landing-icon
          :company-name="companyName"
          :padding="true"
          :img="valuePropIcon5"
          :text="valuePropText5"
        />
      </div>
      <n-button
        v-if="!isEnrollmentDisabled"
        :style="`background-color: ${colors.ctaButton}!important`"
        class="mt-8"
        variant="teal"
        :rounded-xxl="false"
        @click="$emit('cta')"
      >
        {{ ctaText }}
      </n-button>
    </div>
  </section>
</template>

<script>
import LandingIcon from "../LandingIcon.vue";
export default {
  components: {
    LandingIcon,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    colors: Object,
    isEnrollmentDisabled: Boolean,
    hideSubHeader: {
      default: false,
      type: Boolean,
    },
    isVariantB: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    title() {
      return this.$t("careplus.reasons-title");
    },
    ctaText() {
      return this.$t("careplus.get-started-with-nymbl");
    },
    valuePropText1() {
      return this.$t("careplus.value-prop-1");
    },
    valuePropText2() {
      return this.$t("careplus.value-prop-2");
    },
    valuePropText3() {
      return this.$t("careplus.value-prop-3");
    },
    valuePropText4() {
      return this.$t("careplus.value-prop-4");
    },
    valuePropText5() {
      return this.$t("careplus.value-prop-5");
    },

    valuePropIcon1() {
      return "money-careplus.png";
    },
    valuePropIcon2() {
      return "house-careplus.png";
    },
    valuePropIcon3() {
      return "thumbs-careplus.png";
    },
    valuePropIcon4() {
      return "shoe-careplus.png";
    },
    valuePropIcon5() {
      return "clock-careplus.png";
    },
  },
};
</script>
