import { createI18n } from "vue-i18n";

import en from "@/intl/en.json";
import es from "@/intl/es.json";

const messages = {
  en,
  es,
};

export const i18n = createI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
  globalInjection: true,
  legacy: false,
});
