<template>
  <div class="max-w-6xl mx-auto grid grid-cols-1">
    <landing-embedded-video
      no-margins
      :company-name="companyName"
      @trackEvent="createEvent"
    >
      <header>
        <landing-logo v-if="isCompanyLogoEnabled" :company-name="companyName" />
        <a
          v-else
          href="https://nymblscience.com/"
          target="_blank"
          rel="noopener"
        >
          <img
            class="w-20 md:w-48 mx-auto my-6"
            src="../../assets/img/nymblscience-logo.svg"
        /></a>
      </header>

      <landing-horizontal-line />
    </landing-embedded-video>
  </div>
</template>

<script>
import LandingLogo from "./LandingLogo.vue";
import LandingEmbeddedVideo from "./LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "./LandingHorizontalLine.vue";
import getCompanyName from "./getCompanyName";
import { useStore } from "@/store.js";
import { trackEvent } from "@/common/events";

export default {
  components: {
    LandingLogo,
    LandingEmbeddedVideo,
    LandingHorizontalLine,
  },

  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      companyName: "fallsfree",
    };
  },
  computed: {
    isCompanyLogoEnabled() {
      if (this.companyName === "Humana") {
        return false;
      }
      return true;
    },
  },
  created() {
    this.companyName = getCompanyName();

    window.document.title = "Get Nymbl now";
    this.createEvent("viewed Full Screen branded video page /Video");
    // Add temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.add("new-ui");
    body.classList.add("new-ui");
  },
  methods: {
    createEvent(event, props = {}) {
      // Add segment if Sales Demo company
      if (this.externalSegmentL1) {
        props.ExternalSegmentL1 = this.externalSegmentL1;
      }
      event = event + " on Branded Video landing page.";

      trackEvent({ event, ...props }, this.$gtm);
    },
  },
};
</script>

<style lang="scss">
.new-ui {
  background: #fff !important;
  font-size: 24px !important;
}
#landing-page {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
