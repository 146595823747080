<template>
  <div class="w-full md:w-auto md:max-w-6xl mx-auto eligibility-solera">
    <div class="flex justify-center items-center md:p-10 w-full p-4">
      <img src="../../../assets/img/solera-nymbl-logo.png" />
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-4">
      <div class="hidden lg:flex justify-end items-start pt-14 max-w-2xl px-4">
        <img src="../../../assets/img/nymbl-walking-illustration.png" />
      </div>
      <div
        class="col-span-1 lg:col-span-2 py-3 md:py-10 px-2 md:px-10 lg:px-32 break-words"
      >
        <Headline class="leading-6 md:leading-10 tracking-wide">
          {{ $t("eligibility.welcome-to-better-balance-with-nymbl") }}
        </Headline>
        <router-view />
      </div>
      <div class="hidden lg:flex justify-start items-start pt-4 max-w-2xl px-4">
        <img src="../../../assets/img/nymbl-gardening-illustration.png" />
      </div>
    </div>
  </div>
</template>

<script setup>
import Headline from "@/components/Headline.vue";
import { useStore } from "@/store.js";
import { onMounted } from "vue";
const store = useStore();
store.loading = true;
onMounted(() => {
  store.loading = false;
});
</script>
<style lang="scss">
.eligibility-solera {
  color: #333 !important;
  font-size: 24px !important;
  letter-spacing: -0.17px !important;
  padding-bottom: 0;
  min-height: 100%;
  font-family: "Roboto" !important;
  // Add 1px height to trick min-height 100&
  height: 1px;

  .n-textbox .n-textbox__label-text {
    letter-spacing: -0.17px !important;
    color: #000 !important;
  }
  .n-textbox__input {
    letter-spacing: 1.5px !important;
  }
  .n-textbox__input::placeholder {
    font-style: italic !important;
    letter-spacing: 1.5px;
    color: #828282 !important;
  }
  .n-button__primary.is-disabled {
    color: #fff !important;
    background: #bdbdbd !important;
    box-shadow: none !important;
    opacity: 1;
  }
  .n-button__transparent .n-loading-circle.n-button__loading circle {
    stroke: #0c6a69;
  }
}
</style>
