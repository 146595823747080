<template>
  <section class="my-5 px-2 pb-4">
    <div class="inner-container">
      <div class="lg:grid lg:grid-cols-2 md:px-5">
        <div
          class="flex justify-center items-start flex-col text-center lg:text-left"
        >
          <h1
            :style="`color: ${colors.title}!important`"
            class="my-2 md:my-4 text-xl md:text-4xl leading-6 md:leading-12 font-bold"
          >
            {{ title }}
          </h1>
          <p class="leading-5 pb-4 md:pb-6 md:leading-7 md:text-lg">
            <span v-html="body" />
          </p>

          <n-button
            v-if="!isEnrollmentDisabled"
            :style="`background-color: ${colors.ctaButton}!important`"
            variant="teal"
            class="md:mt-4 w-full sm:w-auto mx-auto lg:mx-0 mb-4 sm:mb-6 xl:mt-0 lg:w-auto"
            @click="$emit('cta')"
          >
            {{ $t("careplus.get-started-with-nymbl") }}
          </n-button>
        </div>
        <div class="max-w-sm sm:mx-auto md:mx-auto lg:m-0 select-none">
          <div class="lg:h-full">
            <landing-video
              v-if="isLoaded"
              :key="$i18n.locale"
              :video-id="videoId"
              :video-key="videoKeyComputed"
              :company-name="companyName"
              @track-event="trackEvent"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LandingVideo from "../LandingVideo.vue";
export default {
  components: {
    LandingVideo,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    colors: Object,
    isEnrollmentDisabled: Boolean,
    videoId: {
      default: null,
      type: String,
    },
    videoKey: {
      default: null,
      type: String,
    },
    isVariantA: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  computed: {
    title() {
      return this.$t("careplus.good-health-starts-with-your-balance");
    },
    body() {
      return this.$t("careplus.header-desc");
    },

    videoIdComputed() {
      if (this.videoId) {
        return this.videoId;
      }
      if (this.videoKey) {
        return this.videoKey;
      }
      return "846306914";
    },
    videoKeyComputed() {
      return null;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoaded = true;
    });
  },
  created() {
    console.log(this.videoId);
    console.log(this.$i18n.locale);
  },

  methods: {
    trackEvent(event, props) {
      this.$emit("trackEvent", event, props);
    },
  },
};
</script>
<style lang="scss">
.font-bigger {
  font-size: 1.5rem !important;
  @media (max-width: 850px) {
    font-size: 1rem !important;
  }
}
</style>
