<template>
  <div
    class="top-0 bottom-0 right-0 left-0 z-10 absolute flex justify-center items-center"
    v-if="!loaded"
  >
    <n-loading-circle :size="64" :stroke="2" />
  </div>

  <iframe
    style="min-height: 70vh"
    class="w-full"
    :src="url"
    :title="title"
    :onload="onFrameLoaded"
  />
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: "Nymbl Science",
    },
  },
  data() {
    return {
      loaded: false,
      onFrameLoaded: () => {
        this.loaded = true;
      },
    };
  },
  created() {
    const body = document.body;
    body.classList.add("no-scrolling");
  },
  beforeUnmount() {
    const body = document.body;
    body.classList.remove("no-scrolling");
  },
};
</script>
<style>
.no-scrolling {
  overflow: hidden !important;
}
</style>
