export const validVideoIds = [
  // First batch of educational videos
  "456677944",
  "475519053",
  "475520397",
  "475520983",
  "415647248",
  "415649501",
  "415690461",
  "415672425",
  "415697669",
  "415692561",
  "415678339",
  // Second batch of educational videos
  "414895047",
  "414867285",
  "414889055",
  "414873137",
  "414861179",
  "414879742",
  "414884076",
  "414903416",
  "414906744",
  "414923838",
  "574526587",
  "574527690",
  "574527097",
  "574530131",
  "574528808",
  "574532345",
  "574531416",
  "574534235",
  "574533287",
  "574536079",
  "574533287",
  "574537707",
  "574536915",
  "574539893",
  "574538885",
  "574541519",
  "574538885",
  // Blue KC update,
  "456677944",
  "574540933",
  // Diet update
  "574535458",
  // Fallsfree experiment
  "607585277",
  "612971203",
  // Overview Video
  "635466334",
  "738993303",
  // Re-branding
  "849266118", // anout nymbl
  "849266412", // about nymbl spanish
  "849470912", // how to download nymbl
  "846306914",
];
