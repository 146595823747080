<template>
  <section class="my-5 px-2 pb-4">
    <div class="inner-container">
      <div class="lg:grid lg:grid-cols-2 md:px-5">
        <div
          class="text-center flex justify-center items-center flex-col"
          :class="isVariantA ? 'mt-10' : 'mt-0'"
        >
          <h1
            v-if="!isVariantA"
            :class="companyName === 'Humana' ? 'font-bold' : ''"
            :style="`color: ${colors.title}!important`"
            class="text-lg my-2 md:my-4 md:text-lg leading-6 md:leading-8 font-bold"
          >
            {{ title }}
          </h1>
          <p
            class="leading-5 pb-4 md:pb-6 md:leading-7"
            :class="
              companyName === 'John Hancock' ? 'font-bigger' : ' md:text-lg'
            "
          >
            <span v-html="body" />
          </p>

          <n-button
            v-if="!isEnrollmentDisabled"
            :style="`background-color: ${colors.ctaButton}!important`"
            variant="teal"
            class="md:mt-4 w-full sm:w-auto mb-4 sm:mb-6 lg:ml-4 xl:mt-0 lg:w-auto"
            rounded-xxl
            @click="$emit('cta')"
          >
            {{ getCtaCopy() }}
          </n-button>
        </div>
        <div class="max-w-sm sm:mx-auto md:mx-auto lg:m-0 select-none">
          <div v-if="companyName === 'John Hancock'">
            <img
              class="w-full p-8"
              src="../../assets/img/FallsFree/image-026.png"
            />
          </div>
          <div v-else class="lg:h-full">
            <landing-video
              v-if="anonymousFeatureFlagsLoaded"
              :video-id="videoIdComputed"
              :video-key="videoKeyComputed"
              :company-name="companyName"
              @track-event="trackEvent"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LandingVideo from "./LandingVideo.vue";
export default {
  components: {
    LandingVideo,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    colors: Object,
    anonymousFeatureFlags: Object,
    anonymousFeatureFlagsLoaded: Boolean,
    isEnrollmentDisabled: Boolean,
    videoId: {
      default: null,
      type: String,
    },
    videoKey: {
      default: null,
      type: String,
    },
    isVariantA: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    title() {
      if (this.companyName === "John Hancock") {
        return "We’re sorry! The sign up period for Nymbl has closed.";
      }
      return "Good health starts with your balance";
    },
    body() {
      const companyName = this.companyName;

      if (companyName === "fallsfree" || companyName === "Sales Demo") {
        return "Your care team is excited to offer you Nymbl, a free <nobr>at-home</nobr> balance solution";
      }
      if (companyName === "DRCOG") {
        return `Nymbl and The Denver Regional Council of Governments (DRCOG) are excited to offer you Nymbl, a free at-home balance improvement program`;
      }
      if (companyName === "Humana") {
        return `${this.companyDisplayName} is excited to let you know about Nymbl, an <nobr>at-home</nobr> balance solution available through your Humana plan at no additional cost to eligible members.`;
      }
      if (companyName === "Blue KC") {
        return "Blue Cross and Blue Shield of Kansas City (Blue KC) is excited to offer you Nymbl, an <nobr>at-home</nobr> balance solution, at no cost to you!";
      }
      if (companyName === "John Hancock") {
        return `Thank you for your interest in signing up for Nymbl’s balance training program. As of December 31, 2021 at 11:59 p.m. (ET), John Hancock members can no longer enroll in Nymbl. We apologize for any inconveniences and look forward to offering you more health and wellness improvement opportunities in the future. If you have any questions, please contact John Hancock’s support team by calling 888-884-0881, available from 8:00 a.m. - 6:00 p.m. (ET), Monday through Friday.`;
      }
      if (companyName === "Blue MI") {
        return `Your health plan is excited to offer you Nymbl, a free at-home balance solution`;
      }
      return `${this.companyDisplayName} is excited to offer you Nymbl, an <nobr>at-home</nobr>
                balance solution available at no additional cost to you`;
    },
    videoIdComputed() {
      if (this.videoId) {
        return this.videoId;
      }
      if (this.videoKey) {
        return this.videoKey;
      }
      return "849266118";
    },
    videoKeyComputed() {
      return null;
    },
  },
  methods: {
    trackEvent(event, props) {
      this.$emit("trackEvent", event, props);
    },
    getCtaCopy() {
      if (
        this.companyName === "Blue KC" ||
        this.companyName === "Blue MI" ||
        this.companyName === "John Hancock" ||
        this.companyName === "Humana"
      ) {
        return "Get started with Nymbl";
      }
      if (this.companyName === "fallsfree") {
        return "Check eligibility";
      }
      return "Get Nymbl";
    },
  },
};
</script>
<style lang="scss">
.font-bigger {
  font-size: 1.35rem !important;
  @media (max-width: 850px) {
    font-size: 1rem !important;
  }
}
</style>
