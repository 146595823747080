//  Eligibility Flow

const EligibilityNotFound = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityNotFound.vue");

const EligibilityNotFoundArvada = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityNotFoundArvada.vue");

const EligibilityNotFoundDRCOG = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityNotFoundDRCOG.vue");

const EligibilityNotFoundJH = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityNotFoundJH.vue");

const EligibilityForm = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityForm.vue");

const EligibilityChooseProvider = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityChooseProvider.vue");

const EligibilityTestQuestion = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestQuestion.vue");

const EligibilityTestHow = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestHow.vue");

const EligibilityTestHowText = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestHowText.vue");

const EligibilityTestGetReady = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestGetReady.vue");

const EligibilityTestStandups = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestStandups.vue");

const EligibilityTestInput = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestInput.vue");

const EligibilityTestAverage = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestAverage.vue");

const EligibilityTestIncreased = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityTestIncreased.vue");

const EligibilitySignUp = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilitySignUp.vue");

const EligibilitySignUpControl = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilitySignUpControl.vue");

const EligibilityGetAppText = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityGetAppText.vue");

const EligibilityGetAppTextSuccess = () =>
  import(
    "@/views/Eligibility/EligibilityFlow/EligibilityGetAppTextSuccess.vue"
  );

const EligibilityGetAppStore = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityGetAppStore.vue");

const EligibilityInstructionsAndroid = () =>
  import(
    "@/views/Eligibility/EligibilityFlow/EligibilityInstructionsAndroid.vue"
  );
const EligibilityInstructionsApple = () =>
  import(
    "@/views/Eligibility/EligibilityFlow/EligibilityInstructionsApple.vue"
  );

const EligibilityFlow = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityFlow.vue");

const childrenRoutes = [
  {
    path: "Verify",
    name: "EligibilityForm",
    component: EligibilityForm,
    props: true,
  },

  {
    path: "Provider",
    name: "EligibilityChooseProvider",
    component: EligibilityChooseProvider,
    props: true,
  },

  {
    path: "TestQuestion",
    name: "EligibilityTestQuestion",
    component: EligibilityTestQuestion,
  },

  {
    path: "Test/How",
    name: "EligibilityTestHow",
    component: EligibilityTestHow,
  },
  {
    path: "Test/HowText",
    name: "EligibilityTestHowText",
    component: EligibilityTestHowText,
  },
  {
    path: "Test/GetReady",
    name: "EligibilityTestGetReady",
    component: EligibilityTestGetReady,
  },
  {
    path: "Test/Standups",
    name: "EligibilityTestStandups",
    component: EligibilityTestStandups,
  },

  {
    path: "Test/Input",
    name: "EligibilityTestInput",
    component: EligibilityTestInput,
  },
  {
    path: "Test/Average",
    name: "EligibilityTestAverage",
    component: EligibilityTestAverage,
  },
  {
    path: "Test/Increased",
    name: "EligibilityTestIncreased",
    component: EligibilityTestIncreased,
  },

  {
    path: "NotFound",
    name: "EligibilityNotFound",
    component: EligibilityNotFound,
  },
  {
    path: "NotFoundArvada",
    name: "EligibilityNotFoundArvada",
    component: EligibilityNotFoundArvada,
  },
  {
    path: "NotFoundDRCOG",
    name: "EligibilityNotFoundDRCOG",
    component: EligibilityNotFoundDRCOG,
  },
  {
    path: "NotFoundJH",
    name: "EligibilityNotFoundJH",
    component: EligibilityNotFoundJH,
  },
  {
    path: "SignUp",
    name: "EligibilitySignUp",
    component: EligibilitySignUp,
  },
  {
    path: "SignUpC",
    name: "EligibilitySignUpControl",
    component: EligibilitySignUpControl,
  },
  {
    path: "AppText",
    name: "EligibilityGetAppText",
    component: EligibilityGetAppText,
  },
  {
    path: "AppTextSuccess",
    name: "EligibilityGetAppTextSuccess",
    component: EligibilityGetAppTextSuccess,
  },
  {
    path: "AppStore",
    name: "EligibilityGetAppStore",
    component: EligibilityGetAppStore,
  },
  {
    path: "AppStoreAndroid",
    name: "EligibilityInstructionsAndroid",
    component: EligibilityInstructionsAndroid,
  },
  {
    path: "AppStoreApple",
    name: "EligibilityInstructionsApple",
    component: EligibilityInstructionsApple,
  },
];

const routes = [
  {
    path: "/Eligibility",
    redirect: "/EligibilityFlow",
  },
  {
    path: "/EligibilityFlow",
    component: EligibilityFlow,
    children: childrenRoutes,
  },
];

export default routes;
