<template>
  <div id="landing-page" class="m-auto" style="max-width: 3800px">
    <landing-embedded-video
      :company-name="companyName"
      @trackEvent="createEvent"
    />

    <div class="shadow p-5 max-w-4xl rounded-xl mx-auto mt-4">
      <header>
        <landing-logo :company-name="companyName" />
      </header>

      <landing-horizontal-line />

      <router-view prefix="Landing" @trackEvent="createEvent" />
    </div>
  </div>
</template>

<script>
import LandingLogo from "./LandingLogo.vue";
import LandingEmbeddedVideo from "./LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "./LandingHorizontalLine.vue";
import getCompanyName from "./getCompanyName";
import { trackEvent } from "@/common/events";

export default {
  components: {
    LandingLogo,
    LandingEmbeddedVideo,
    LandingHorizontalLine,
  },
  data() {
    return {
      companyName: "fallsfree",
    };
  },
  created() {
    this.companyName = getCompanyName();

    window.document.title = "Get Nymbl now";

    // Add temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.add("new-ui");
    body.classList.add("new-ui");
  },
  methods: {
    createEvent(event, props = {}) {
      // Add segment if Sales Demo company
      if (this.externalSegmentL1) {
        props.ExternalSegmentL1 = this.externalSegmentL1;
      }
      event = event + " on Enrolled Video landing page.";

      trackEvent({ event, ...props }, this.$gtm);
    },
  },
};
</script>

<style lang="scss">
.new-ui {
  background: #fff !important;
  font-size: 24px !important;
}
#landing-page {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
