<script setup>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import api from "@/api";
import Headline from "@/components/Headline.vue";
import ButtonFull from "@/components/ButtonFull.vue";
import { getMobileDevice } from "@/scripts/getMobileDevice.js";
import { trackEvent } from "@/common/events";

const isOnMobileDevice = getMobileDevice() === null ? false : true;

const route = useRoute();

const user = ref(null);

const goToDeepLink = () => {
  let url = `https://${
    process.env.VUE_APP_ENV === "production" ? "my" : "test"
  }.nymblscience.com/InApp`;
  window.location.href = url;
};

const lookupUser = (internalId) => {
  return api.get(`Users/Lookup/${internalId}/Video`);
};

const startTraining = () => {
  trackEvent(
    {
      event: "web_link_clicked",
      name: "video reactivation",
      type: "button",
      purpose: "launch or download app",
      label: "start todays training",
    },
    this.$gtm,
  );

  goToDeepLink();
};

onMounted(() => {
  trackEvent(
    {
      event: "web_screen_viewed",
      name: "video reactivation",
    },
    this.$gtm,
  );

  lookupUser(route.query.internalId)
    .then(({ data }) => {
      user.value = data;
    })
    .catch((err) => {
      console.log(err);
    });
});
</script>

<template>
  <div class="flex justify-center mt-8 sm:mt-12 font-roboto px-4">
    <a href="https://nymblscience.com/" target="_blank" rel="noopener">
      <img
        style="max-width: 250px"
        class="logo"
        src="../../assets/img/nymblscience-logo.svg"
    /></a>
  </div>
  <div v-if="user" class="max-w-xl mx-auto mt-8 sm:mt-16 font-roboto px-4">
    <div style="position: relative; padding-bottom: 62.5%; height: 0">
      <iframe
        :src="`https://www.loom.com/embed/${user.loomVideoId}?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true.`"
        frameborder="0"
        webkitallowfullscreen
        mozallowfullscreen
        allowfullscreen
        style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
      />
    </div>
    <Headline class="text-xl mt-7 leading-8">
      Hi, {{ user.firstName }}!
    </Headline>
    <Headline class="text-xl mt-5 leading-8">
      A message from Dr. Estrada
    </Headline>
    <ButtonFull
      v-if="isOnMobileDevice"
      class="mt-12 text-lg"
      container-classes="px-2 sm:px-5 leading-7"
      @click="startTraining"
    >
      Start today's training
    </ButtonFull>
  </div>
</template>
