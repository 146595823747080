<template>
  <div class="w-full shadow text-center bg-white pl-8 pr-8 mt-12 md:mt-0">
    <div class="transform -translate-y-8">
      <img
        class="rounded-full m-auto"
        style="max-width: 200px !important"
        :src="getImgUrl(img)"
      />
      <p class="leading-5 mt-3" style="height: 140px">“{{ review }}”</p>
      <h3 class="text-sm leading-6 mt-4">
        {{ name }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    img: String,
    review: String,
  },
  methods: {
    getImgUrl(pic) {
      return require("../../../assets/img/FallsFree/" + pic);
    },
  },
};
</script>
