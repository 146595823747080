<template>
  <section class="px-3 md:px-6 pt-6 whitespace-pre-wrap">
    <div class="inner-container">
      <div class="font-bold mb-3">
        <span>Sources:</span>
      </div>
      <p class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words">
        <span
          >{{ $t("careplus.1-papi-e-chiou-s") }}
          {{
            $t(
              "careplus.and-mcgregor-a-h-2020-feasibility-and-acceptability-study-on-the-use-of-a-smartphone-application-to-facilitate-balance-training-in-the-ageing-population-bmj-open-10-e039054-doi-10-1136-bmjopen-2020-039054",
            )
          }}</span
        >
        <a
          class="underline"
          :style="`color: ${textColor}!important`"
          href="https://bmjopen.bmj.com/content/10/12/e039054"
          >https://bmjopen.bmj.com/content/10/12/e039054</a
        >.
        <br />
        <br />

        <span>{{
          $t(
            "careplus.2-mirelman-a-herman-t-brozgol-m-dorfman-m-sprecher-e-schweiger-a-giladi-n-hausdorff-j-m-2012-executive-function-and-falls-in-older-adults-new-findings-from-a-five-year-prospective-link-fall-risk-to-cognition-plos-one-vol-7-6-e40297-doi-10-1371-journal-pone-0040297",
          )
        }}</span>
        <a
          class="underline"
          :style="`color: ${textColor}!important`"
          href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
          >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
        >.
        <br />
      </p>
      <p class="mt-4 mb-2 leading-3 text-sm md:text-base md:leading-4">
        <span>{{
          $t(
            "careplus.nymbls-technology-is-intended-to-improve-balance-strength-and-coordination-and-to-help-individuals-who-have-experienced-or-are-at-risk-for-weakness-or-loss-of-balance-or-mobility-it-is-important-to-work-with-a-medical-professional-if-you-notice-that-you-are-developing-weakness-mobility-or-balance-problems-as-this-can-indicate-a-serious-medical-condition-nymbl-is-not-intended-to-be-a-substitute-for-professional-medical-advice-diagnosis-or-treatment-the-information-provided-by-nymbl-is-for-general-purposes-and-is-not-intended-to-constitute-medical-advice-you-are-encouraged-to-confirm-any-information-provided-by-nymbl-with-other-sources-and-your-healthcare-provider-if-you-think-you-may-have-a-medical-emergency-during-use-of-the-program-call-your-doctor-or-emergency-medical-phone-number-immediately",
          )
        }}</span>
      </p>
      <div class="mb-3 leading-3 text-sm md:text-base md:leading-4 break-words">
        <p class="font-bold mb-1">
          <span>{{ $t("careplus.important") }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t("careplus.at-careplus-it-is-important-you-are-treated-fairly")
          }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t(
              "careplus.careplus-health-plans-inc-complies-with-applicable-federal-civil-rights-laws-and-does-not-discriminate-on-the-basis-of-race-color-national-origin-ancestry-ethnicity-sex-sexual-orientation-gender-gender-identity-disability-age-marital-status-religion-or-language-in-their-programs-and-activities-including-in-admission-or-access-to-or-treatment-or-employment-in-their-programs-and-activities",
            )
          }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t(
              "careplus.the-following-department-has-been-designated-to-handle-inquiries-regarding-carepluss-non-discrimination-policies-member-services-po-box-277810-miramar-fl-33027-1-800-794-5907-tty-711",
            )
          }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t(
              "careplus.auxiliary-aids-and-services-free-of-charge-are-available-to-you-1-800-794-5907-tty-711",
            )
          }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t(
              "careplus.careplus-provides-free-auxiliary-aids-and-services-such-as-qualified-sign-language-interpreters-video-remote-interpretation-and-written-information-in-other-formats-to-people-with-disabilities-when-such-auxiliary-aids-and-services-are-necessary-to-ensure-an-equal-opportunity-to-participate",
            )
          }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t(
              "careplus.this-information-is-available-for-free-in-other-languages-please-call-our-member-services-number-at-1-800-794-5907-hours-of-operation-from-october-1-march-31-we-are-open-7-days-a-week-8-a-m-to-8-p-m-from-april-1-september-30-we-are-open-monday-friday-8-a-m-to-8-p-m-you-may-always-leave-a-voicemail-after-hours-saturdays-sundays-and-holidays-and-we-will-return-your-call-within-one-business-day",
            )
          }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t(
              "careplus.espanol-spanish-esta-informacion-esta-disponible-de-forma-gratuita-en-otros-idiomas-favor-de-llamar-a-servicios-para-afiliados-al-numero-que-aparece-anteriormente",
            )
          }}</span>
        </p>
        <p class="mb-1">
          <span>{{
            $t(
              "careplus.kreyol-ayisyen-french-creole-enfomasyon-sa-a-disponib-gratis-nan-lot-lang-tanpri-rele-nimewo-sevis-pou-manm-nou-yo-ki-nan-lis-anwo-an",
            )
          }}</span>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    companyName: String,
    companyDisplayName: String,
    textColor: String,
  },
};
</script>
