<template>
  <div id="app">
    <div class="auth-container">
      <!-- This is needed for modal plugin to work. -->
      <div v-show="false" class="n-navbar"></div>

      <full-screen-loading v-if="store.loading" :is-transparent="false" />
      <router-view v-show="!store.loading" />

      <branding-popup />
    </div>
  </div>
</template>

<script>
import anonymousFeatureFlags from "@/anonymousFeatureFlags";
import axiosSetup from "@/axiosSetup";
import api from "@/api";
import { useStore } from "@/store.js";
import getCompanyName from "@/views/FallsFree/getCompanyName";
import { spanishEnabledCompanies } from "./store";
import BrandingPopup from "@/views/FallsFree/BrandingPopup.vue";
import { useHead } from "@vueuse/head";
import FullScreenLoading from "@/components/FullScreenLoading.vue";
export default {
  name: "App",
  components: {
    FullScreenLoading,
    BrandingPopup,
  },
  mixins: [axiosSetup, anonymousFeatureFlags],
  setup() {
    // Disable web crawling for Solera
    if (getCompanyName() === "Blue Shield of California") {
      useHead({
        meta: [
          {
            name: `robots`,
            content: "noindex,nofollow",
          },
        ],
      });
    }

    const store = useStore();
    return { store };
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    query() {
      return this.$route.query;
    },
  },

  mounted() {
    this.removeSplashScreen();
    this.getAnonymousDefaults();
    this.setLanguage();
  },

  created() {
    // SetUp Axios interceptors
    this.interceptorsSetup();

    // add id for body and html for specific companies which have enabled new Nymbl branding
    const companiesWithBrandingEnabled = ["ACC", "Blue MI", "DRCOG"];

    if (companiesWithBrandingEnabled.includes(getCompanyName())) {
      document.querySelector("html").setAttribute("id", "body");
      document.querySelector("body").setAttribute("id", "html");
    }
  },
  methods: {
    getAnonymousDefaults() {
      api.get("/System/AnonymousDefaults").then(({ data }) => {
        this.store.anonymousDefaults = data;
      });
    },
    setLanguage() {
      // Set locale to Spanish if user's browser language is Spanish
      if (!spanishEnabledCompanies.includes(getCompanyName())) {
        return;
      }

      const userLanguage = navigator.language || navigator.userLanguage;

      if (userLanguage.includes("es")) {
        this.$i18n.locale = "es";
      }
    },

    removeSplashScreen() {
      // Remove Nymbl splash screen when vue is loaded.
      const splashScreen = document.getElementById("splash-screen");

      // Don't remove if /InApp route
      if (
        window.location.pathname === "/InApp" ||
        window.location.pathname === "/InApp/authcode"
      ) {
        return;
      }

      if (document.contains(splashScreen)) {
        splashScreen.classList.add("fade-out");
        setTimeout(() => {
          splashScreen.remove();
        }, 600);
      }

      setTimeout(() => {
        if (document.contains(document.getElementById("splash-screen-style"))) {
          document.getElementById("splash-screen-style").remove();
        }
      }, 4000);
    },
  },
};
</script>
<style lang="scss">
#body,
#html {
  background: #f7f9f0 !important;
}
</style>
