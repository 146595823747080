import axios from "axios";
import { i18n } from "@/intl/useI18n";

const getCurrentLocale = () => {
  return i18n.global.locale.value;
};

const createHeaders = () => {
  return {
    "Content-Type": "application/json",
    "Accept-Language": getCurrentLocale(),
  };
};

export default {
  get(url, params) {
    return axios.get(
      url,
      {
        params,
        headers: createHeaders(),
      },
      {},
    );
  },
  post(url, data, params, isErrorMessageDisabled) {
    return axios.post(url, JSON.stringify(data), {
      params,
      headers: createHeaders(),

      isErrorMessageDisabled,
    });
  },
  put(url, data, params, isErrorMessageDisabled) {
    return axios.put(url, JSON.stringify(data), {
      params,
      headers: createHeaders(),

      isErrorMessageDisabled,
    });
  },
  patch(url, data, params) {
    return axios.patch(url, JSON.stringify(data), {
      params,
      headers: createHeaders(),
    });
  },
  delete(url) {
    return axios.delete(url);
  },
};
