<template>
  <div
    id="landing-page"
    class="m-auto"
    style="max-width: 3800px"
    :style="`color: ${colors.text}!important`"
  >
    <landing-embedded-video
      :company-name="companyName"
      @trackEvent="createEvent"
    />

    <header class="flex justify-center">
      <landing-logo :company-name="companyName" />
    </header>

    <landing-horizontal-line :color="colors.line" />

    <landing-header
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :company-display-name="companyDisplayName"
      :colors="colors"
      :anonymous-feature-flags="anonymousFeatureFlags"
      :anonymous-feature-flags-loaded="anonymousFeatureFlagsLoaded"
      @cta="callToAction('header')"
      @trackEvent="createEvent"
    />

    <landing-reviews :company-name="companyName" :colors="colors" />

    <landing-reasons
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('reason icons')"
    />

    <landing-how
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('how it works')"
      @ctaSpot="callToAction('reserve spot')"
    />

    <landing-faq
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('faq')"
    />

    <landing-support
      :company-name="companyName"
      :text-color="colors.supportText"
      :title-color="colors.supportTitle"
    />

    <landing-horizontal-line :color="colors.line" />

    <landing-terms
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :text-color="colors.text"
    />

    <footer class="flex justify-center">
      <landing-logo is-footer :company-name="companyName" />
      <p
        v-if="DINNumberFooter"
        class="text-center text-sm md:text-base md:leading-4 my-10"
      >
        {{ DINNumberFooter() }}
      </p>
    </footer>
  </div>
</template>

<script>
import tailwindConfig from "nymbl-ui/tailwind.config";
import LandingFaq from "./LandingFAQ.vue";
import LandingLogo from "./LandingLogo.vue";
import LandingHow from "./LandingHow.vue";
import LandingReasons from "./LandingReasons.vue";
import LandingReviews from "./LandingReviews.vue";
import LandingTerms from "./LandingTerms.vue";
import LandingSupport from "./LandingSupport.vue";
import LandingHeader from "./LandingHeader.vue";
import LandingEmbeddedVideo from "./LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "./LandingHorizontalLine.vue";
import { useStore } from "@/store.js";

import LandingPageBase from "./LandingPageBase.vue";

export default {
  components: {
    LandingReviews,
    LandingReasons,
    LandingFaq,
    LandingHow,
    LandingLogo,
    LandingSupport,
    LandingTerms,
    LandingHeader,
    LandingEmbeddedVideo,
    LandingHorizontalLine,
  },
  extends: LandingPageBase,
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      companyName: "fallsfree",
      anonymousFeatureFlagsLoaded: false,
      anonymousSettings: null,
      mobileDevice: null,
    };
  },
  computed: {
    colors() {
      const colors = {
        text: "#000",
        title: "#000",
        supportText: "#000",
        supportTitle: "#000",
        howItWorksBorder: this.colorPalette.green[150],
        howItWorksTitle: "#000",
        howItWorksTitleSecond: this.colorPalette.green[150],

        ctaButton: this.colorPalette.green[150],
        reviewsBg: this.colorPalette.green[150],
        faqBg: this.colorPalette.green[150],
        line: this.colorPalette.green[150],
      };

      if (this.companyName === "Humana") {
        colors.title = "#547634";
        colors.text = "#53575A";
        colors.howItWorksTitleSecond = "#547634";
        colors.ctaButton = "#102F54";
        colors.howItWorksBorder = "#88C545";
        colors.reviewsBg = "#547634";
        colors.line = "#88C545";
        colors.faqBg = "#102F54";
      }

      if (this.companyName === "Blue KC") {
        colors.howItWorksTitleSecond = "#2275ce";
        colors.ctaButton = "#2275ce";
      }

      if (this.companyName === "John Hancock") {
        colors.line = "#0027c3";
        colors.ctaButton = "#ec6453";
        colors.reviewsBg = "#0027c3";
        colors.howItWorksBorder = "#0027c3";
        colors.howItWorksTitleSecond = "#0027c3";
        colors.faqBg = "#0027c3";
      }

      return colors;
    },
    colorPalette() {
      return tailwindConfig.theme.extend.colors;
    },

    isEnrollmentDisabled() {
      if (this.companyName === "John Hancock") {
        return true;
      }
      return false;
    },
  },

  methods: {
    DINNumberFooter() {
      return null;
    },
  },
};
</script>

<style lang="scss">
.new-ui {
  background: #fff !important;
  font-size: 24px !important;
}
#landing-page {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
