<script>
import { useStore } from "@/store.js";
import { removeSplashScreen } from "@/helpers";
import api from "@/api";
import getCompanyName from "./getCompanyName";
import { getMobileDevice } from "@/scripts/getMobileDevice.js";
import { trackEvent } from "@/common/events";

export default {
  name: "LandingPageBase",
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      companyName: "fallsfree",
      anonymousFeatureFlagsLoaded: false,
      anonymousSettings: null,
      mobileDevice: null,
    };
  },
  computed: {
    companyDisplayName() {
      if (!this.anonymousSettings) {
        return this.companyName;
      }
      return this.anonymousSettings.companyDisplayName;
    },
    isTestEnv() {
      return this.store.isTestEnv;
    },
    anonymousFeatureFlagsReady() {
      return (
        this.anonymousFeatureFlagsLoaded &&
        this.anonymousFeatureFlags &&
        Object.keys(this.anonymousFeatureFlags).length
      );
    },
    anonymousFeatureFlags() {
      return this.store.anonymousFeatureFlags;
    },
    anonymousUserCent() {
      return this.anonymousFeatureFlagsReady
        ? this.anonymousFeatureFlags.anonymousCent
        : null;
    },
    confirmationCode() {
      const codeQuery = this.$route.query.c;
      if (codeQuery) {
        return codeQuery;
      }
      return null;
    },
    externalSegmentL1() {
      return this.store.externalSegmentL1;
    },
  },
  watch: {
    anonymousFeatureFlags(anonymousFeatureFlags) {
      this.anonymousFeatureFlagsLoaded = true;
      // Wait for anonymousFeatureFlags to load before firing CT events
      if (anonymousFeatureFlags) {
        this.createEvent("Viewed the welcome/landing page", {
          provider: getCompanyName(),
          AnonymousUserCent: this.anonymousUserCent,
          ExternalSegmentL1: this.externalSegmentL1,
        });
      }
    },
  },

  created() {
    const that = this;

    this.getAnonymousSettings();

    this.companyName = getCompanyName();

    window.document.title = "Get Nymbl now";

    if (this.anonymousFeatureFlags) {
      this.anonymousFeatureFlagsLoaded = true;
    }

    setTimeout(() => {
      that.anonymousFeatureFlagsLoaded = true;
    }, 3000);

    // Add temporary classes to complement Tailwind CSS
    const root = document.getElementsByTagName("html")[0];
    const body = document.body;
    root.classList.add("new-ui");
    body.classList.add("new-ui");

    // Is Mobile? Android or Apple.
    this.isMobileDevice = getMobileDevice() === null ? false : true;
  },
  methods: {
    getAnonymousSettings() {
      const params = {
        companyName: getCompanyName(),
      };
      api.get(`/Company/AnonymousSettings`, params).then((result) => {
        this.anonymousSettings = result.data;
      });
    },

    createEvent(event, props = {}) {
      // Add segment if Sales Demo company
      if (this.externalSegmentL1) {
        props.ExternalSegmentL1 = this.externalSegmentL1;
      }

      trackEvent({ event, ...props }, this.$gtm);
    },

    callToAction(name, variant, interestCategory = 3) {
      this.createEvent("Clicked on any of the buttons on the landing page", {
        provider: getCompanyName(),
        ExternalSegmentL1: this.externalSegmentL1,
        AnonymousUserCent: this.anonymousUserCent,
        button: name,
        interestCategory: interestCategory,
      });

      const company = this.companyName.split(" ").join("");
      const region = this.companyName === "ACC" ? "nz" : null;

      const url =
        `/EligibilityFlow/Verify?&interestCategory=${interestCategory}` +
        (company === "fallsfree" ? "" : `&company=${company}`) +
        (region === "fallsfree" ? "" : `&region=${region}`) +
        (this.confirmationCode ? `&c=${this.confirmationCode}` : "");
      this.$router.push(url);
    },
    removeSplashScreen() {
      removeSplashScreen();
    },
  },
};
</script>
<style lang="scss">
.new-ui {
  background: #fff;
  font-size: 24px !important;

  .n-select.n-select.is-active:not(.is-disabled) .n-select__display {
    border-bottom-color: #084D33 !important;
  }
  .n-select.is-active:not(.is-disabled) .n-select__display {
    border-bottom-color: #084D33 !important;
    border-bottom-width: 2px;
  }

  .n-select-option.is-selected,
  .n-select-option.is-selected .n-select-option__checkbox {
    color: #084D33 !important;
  }
  .n-select-option.is-selected,
  .n-select-option.is-selected .n-select-option__checkbox {
    color: #084D33 !important;
  }
}

.new-ui-enrollment {
  .n-select.n-select.is-active:not(.is-disabled) .n-select__display {
    border-bottom-color: #084D33 !important;
  }
  .n-select.is-active:not(.is-disabled) .n-select__display {
    border-bottom-color: #084D33 !important;
    border-bottom-width: 2px;
  }

  .n-select-option.is-selected,
  .n-select-option.is-selected .n-select-option__checkbox {
    color: #084D33 !important;
  }
  .n-select-option.is-selected,
  .n-select-option.is-selected .n-select-option__checkbox {
    color: #084D33 !important;
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
