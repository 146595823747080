<template>
  <div
    id="landing-page-acc"
    class="m-auto font-roboto mb-2 md:mb-12"
    :style="{ maxWidth: '3800px', background: colors.bgLight }"
  >
    <landing-embedded-video
      :company-name="companyName"
      @trackEvent="createEvent"
    />

    <landing-nav
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('nav')"
    />

    <landing-header
      id="top"
      class="mt-3 lg:mt-20"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="'DRCOG'"
      video-id="849266118"
      :company-display-name="companyDisplayName"
      :colors="colors"
      @cta="callToAction('header')"
      @track-event="createEvent"
    />

    <landing-how
      id="about"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      class="mb-5"
      :colors="colors"
      @cta="callToAction('how it works')"
      @ctaSpot="callToAction('reserve spot')"
    />

    <section class="mt-4">
      <div class="grid grid-cols-1 xl:grid-cols-2">
        <div class="order-last xl:order-first">
          <img src="./soccer.png" alt="Free Tips" />
        </div>
        <div
          class="p-6 landing-section-acc"
          :style="{
            background: colors.lightBlue,
          }"
        >
          <h2 class="font-bold mt-2 xl:text-left lg:text-4xl mb-1">
            ACC and Nymbl. <br />
            Another way to improve your quality of life.
          </h2>

          <p
            class="max-w-sm mx-auto md:max-w-md lg:max-w-xl xl:mx-0 xl:text-left"
          >
            To help New Zealanders lead the lives they love, we’re passionate
            about finding ways to enhance people’s balance. As part of our Live
            Stronger for Longer programme, we’re partnering with Nymbl, the
            in-home balance app that’s helped thousands of people across the
            world get better balance, lead active lives and live falls free. If
            there’s anything else we can help you with,
            <span class="italic">we’re here for you.</span>
          </p>
        </div>
      </div>
    </section>

    <landing-reviews
      id="testimonials"
      :company-name="companyName"
      :colors="colors"
    />

    <landing-reasons
      id="why"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      hide-sub-header
      :colors="colors"
      @cta="callToAction('reason icons')"
    />

    <landing-support
      class="py-3 md:py-10"
      style="background: #084d33"
      :company-name="companyName"
      text-color="#fff"
    />

    <landing-faq
      id="faq"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('faq')"
    />

    <landing-horizontal-line :color="colors.line" />

    <landing-terms
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :text-color="colors.text"
    />

    <transition name="fade">
      <div
        v-show="scrollTopY > 300"
        id="pagetop"
        class="fixed left-0 bottom-0 cursor-pointer ml-4 mb-4 z-50"
        @click="toTop"
      >
        <div
          :style="{ background: colors.bgLight }"
          style="
            background: #fff;
            width: 58px;
            height: 58px;
            border-radius: 50%;
          "
          class="flex items-center justify-center rounded-full"
        >
          <ArrowUpDropCircle size="66" :fill-color="colors.textOrange" />
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import tailwindConfig from "nymbl-ui/tailwind.config";
import { useStore } from "@/store.js";
import ArrowUpDropCircle from "vue-material-design-icons/ArrowUpDropCircle.vue";

import LandingPageBase from "../LandingPageBase.vue";

import LandingFaq from "./LandingFAQACC.vue";
import LandingHow from "./LandingHowACC.vue";
import LandingReasons from "../LandingReasons.vue";
import LandingReviews from "./LandingReviewsACC.vue";
import LandingTerms from "./LandingTermsACC.vue";
import LandingSupport from "./LandingSupportACC.vue";
import LandingHeader from "./LandingHeaderACC.vue";
import LandingNav from "./LandingNavACC.vue";
import LandingEmbeddedVideo from "../LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "../LandingHorizontalLine.vue";

export default {
  components: {
    LandingReviews,
    LandingReasons,
    LandingFaq,
    LandingHow,
    LandingNav,
    LandingSupport,
    LandingTerms,
    LandingHeader,
    LandingEmbeddedVideo,
    LandingHorizontalLine,
    ArrowUpDropCircle,
  },
  extends: LandingPageBase,
  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      companyName: "ACC",
      anonymousFeatureFlagsLoaded: false,
      anonymousSettings: null,
      mobileDevice: null,
      scrollTopTimer: 0,
      scrollTopY: 0,
    };
  },
  computed: {
    colorPalette() {
      return tailwindConfig.theme.extend.colors;
    },
    colors() {
      const colors = {
        text: "#000",
        textOrange: "#B9336A",
        textBlue: "#B9336A",
        green: "#084D33",
        title: "#000",
        supportText: "#000",
        supportTitle: "#000",
        howItWorksBorder: this.colorPalette.green[150],
        howItWorksTitle: "#000",
        howItWorksTitleSecond: "#000",
        ctaButton: "#B9336A",
        ctaButtonSecondary: "#B9336A",
        reviewsBg: "#F7F9F0",
        bg: "#084D33",
        bgLight: "#F7F9F0",
        faqBg: "#F7F9F0",
        line: "#084D33",
      };

      return colors;
    },

    isEnrollmentDisabled() {
      return false;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.scrollTopTimer) return;
      this.scrollTopTimer = setTimeout(() => {
        this.scrollTopY = window.scrollY;
        clearTimeout(this.scrollTopTimer);
        this.scrollTopTimer = 0;
      }, 100);
    },
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
#landing-page-acc {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.landing-section-acc {
  @media (min-width: 1200px) {
    p {
      padding-top: 10px;
      font-size: 24px !important;
      line-height: 1.5;
    }
  }
  @media (min-width: 1524px) {
    p {
      padding-top: 50px;
      font-size: 29px !important;
      line-height: 1.5;
    }
  }
  @media (min-width: 2000px) {
    p {
      padding-top: 60px;
      font-size: 38px !important;
      line-height: 1.5;
    }
  }
}
</style>
