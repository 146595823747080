<template>
  <div :id="videoId" :key="videoId" class="vimeo-video select-none lg:ml-2">
    <div class="relative">
      <div
        v-if="!autoplay"
        class="vimeo-video-overlay rounded-xl flex justify-center items-center"
        @click="startVideo()"
      >
        <div class="vimeo-video-play">
          <play title="Play Video" class="text-white" :size="40" />
        </div>
      </div>

      <vue-vimeo-player
        v-if="loaded"
        v-bind="playerOptions"
        ref="player"
        :autoplay="autoplay"
      />
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import Play from "vue-material-design-icons/Play.vue";
import { getExternalSegment } from "@/views/FallsFree/getExternalSegment.js";

export default {
  components: { vueVimeoPlayer, Play },
  props: {
    companyName: String,
    videoId: {
      type: String,
      default: "456677944",
    },
    videoKey: {
      type: String,
      default: null,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    event: {
      type: [Boolean, String],
      default: "welcome/landing page",
    },
  },
  emits: ["trackEvent"],
  data() {
    return {
      loaded: false,
      playerOptions: {
        videoId: this.videoId,
        playerWidth: undefined,
        playerHeight: undefined,
        controls: true,
        options: {
          responsive: true,
        },
      },
    };
  },
  created() {
    // Handle private videos.
    if (this.videoKey) {
      delete this.playerOptions.videoId;
      this.playerOptions.videoUrl = `https://vimeo.com/${this.videoId}/${this.videoKey}`;
    }

    this.$nextTick(() => {
      this.loaded = true;
    });
  },
  mounted() {
    const el = this.$el;
    // Wait for iframe to render to add rounded class and show overlays
    var existCondition = setInterval(function () {
      const targetDiv = el.getElementsByTagName("iframe")[0];

      if (targetDiv) {
        var list = el.getElementsByClassName("vimeo-video-overlay");
        if (list && list.length > 0) {
          list[0].classList.add("show");
        }
        clearInterval(existCondition);
        targetDiv.classList.add("rounded-xl");
      }
    }, 100);
  },
  methods: {
    startVideo() {
      if (this.event) {
        this.$emit("trackEvent", `Clicked play on the ${this.event} video.`, {
          provider: this.companyName,
          ExternalSegmentL1: getExternalSegment(),
          videoId: this.videoId,
        });
      }

      this.playerOptions.controls = true;
      document
        .getElementsByClassName("vimeo-video-overlay")[0]
        .classList.add("hidden");
      this.$refs.player.play();
    },
  },
};
</script>

<style lang="scss">
.vimeo-video {
  @media (min-width: 1024px) {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
  }
}
.hidden {
  display: none;
}
.vimeo-video-overlay.show {
  opacity: 1 !important;
}

.vimeo-video-overlay {
  cursor: pointer;
  z-index: 999;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out 0s;

  .vimeo-video-play {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    background: #ec313f;
    opacity: 0.9;
    border-radius: 99999px;
    transition: all 0.4s ease-in-out 0s;
  }
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    .vimeo-video-play {
      box-shadow:
        0px 6px 6px rgba(0, 0, 0, 0.25),
        0px 2px 6px rgba(0, 0, 0, 0.25);

      opacity: 1;
      &:active {
        opacity: 0.8;
        box-shadow: none;
      }
    }
  }
}
</style>
