<template>
  <div
    class="loading-fullscreen is-fullscreen fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center"
    :class="{ 'is-transparent': isTransparent }"
  >
    <n-loading-circle :size="64" :stroke="2" />
  </div>
</template>

<script>
export default {
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/colors.scss";
.loading-fullscreen {
  z-index: 1999;
  background: $bg-color;
  transition: opacity 300ms;
}

.is-transparent {
  background: rgba(255, 255, 255, 0);
}
</style>
