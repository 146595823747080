<template>
  <div class="min-h-screen min-w-full flex justify-center items-center">
    <h1 class="text-xl text-green-100">Thank you for downloading the file</h1>
    <a id="download" href="/example.pdf" download style="opacity: 0"
      >Download PDF</a
    >
  </div>
</template>
<script setup>
import { onMounted, onBeforeMount } from "vue";
onBeforeMount(() => {
  window.document.title = "Download PDF";
});
onMounted(() => {
  document.getElementById("download").click();
});
</script>
