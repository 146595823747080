<template>
  <section
    :style="`color: ${textColor}`"
    class="my-5 md:my-10 text-center text-sm md:text-base leading-5 md:leading-5 px-2"
  >
    <p
      class="font-bold text-lg md:text-4xl mt-4 md:mb-4"
      :style="`color: ${titleColor}`"
    >
      {{ title }}
    </p>
    <p class="mb-3 md:text-lg">
      {{ desc }}
    </p>

    <div class="md:text-lg mt-4 mb-6">
      <a :style="`color: ${textColor}`" :href="`mailto:${supportEmail}`">
        {{ supportEmail }}</a
      >
    </div>
  </section>
</template>

<script>
export default {
  props: {
    companyName: String,
    textColor: String,
    titleColor: String,
  },
  computed: {
    title() {
      return "Need help?";
    },
    desc() {
      return "Nymbl’s friendly support team is here for you:";
    },

    supportEmail() {
      return "support@nymblscience.com";
    },
  },
};
</script>
