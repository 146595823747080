<template>
  <header>
    <nav
      :style="{
        backgroundColor: colors.clay,
        color: colors.primary,
        zIndex: 99999,
      }"
      class="flex flex-wrap items-center justify-between w-full py-8 sm:py-2 md:py-6 px-1 lg:px-4 text-lg font-bold transition duration-500 ease-in-out lg:fixed top-0"
    >
      <div
        class="inner-container px-0 w-full flex justify-center items-center lg:justify-start lg:items-center flex-col lg:flex-row"
      >
        <div
          class="flex items-center justify-center lg:justify-start lg:items-start w-full lg:w-auto"
        >
          <a
            v-smooth-scroll="{
              duration: 1000,
              offset: -150,
              updateHistory: false,
            }"
            href="#top"
            class="flex items-center"
          >
            <landing-logo
              style="max-width: 375px !important"
              :company-name="companyName"
              class="lg:mr-6"
            />
          </a>
          <a
            class="absolute right-4 sm:hidden cursor-pointer"
            @click="toggleMenu"
            ><Menu
          /></a>
        </div>

        <transition name="fade">
          <div
            class="w-full sm:flex md:items-center"
            :class="isMenuShown ? 'flex' : 'hidden'"
          >
            <ul
              class="w-full px-4 sm:px-0 lg:mb-0 text-lg sm:flex sm:justify-between sm:items-center sm:pt-0"
            >
              <nav-item
                v-for="item in navItems"
                :key="item.url"
                :text="item.text"
                :url="item.url"
              />
            </ul>
          </div>
        </transition>
      </div>
    </nav>
  </header>
</template>

<script setup>
import { ref } from "vue";
import NavItem from "./NavItem.vue";
import Menu from "vue-material-design-icons/Menu.vue";

const isMenuShown = ref(false);

const toggleMenu = () => {
  isMenuShown.value = !isMenuShown.value;
};

const navItems = [
  {
    text: "Get free tips",
    url: "#tips",
  },
  {
    text: "Testimonials",
    url: "#testimonials",
  },
  {
    text: "Community Partners",
    url: "#partners",
  },
  {
    text: "Get free Nymbl app",
    url: "#get",
  },
  {
    text: "About DRCOG",
    url: "#about",
  },
];

defineProps({
  companyName: {
    type: String,
    required: true,
  },
  colors: {
    type: Object,
    required: true,
  },
});

defineEmits(["cta"]);
</script>
