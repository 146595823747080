<template>
  <section
    :style="`background-color: ${colors.faqBg}!important`"
    class="text-white pt-8 md:pt-12 pb-5"
  >
    <div class="inner-container max-w-3xl px-3 md:px-8 text-base">
      <h2
        :class="companyName == 'Humana' ? 'lg:text-xl' : ''"
        class="text-center mb-6 font-bold md:text-lg leading-5 mt-5"
      >
        Frequently asked questions
      </h2>
      <landing-faq-item small question="What is Nymbl?" :answer="faqAnswer1" />
      <landing-faq-item
        small
        question="Who is Nymbl for?"
        :answer="faqAnswer2"
      />

      <landing-faq-item
        small
        question="How does Nymbl work?"
        :answer="faqAnswer3"
      />
      <landing-faq-item
        small
        question="Why am I being offered balance training?"
        :answer="faqAnswer4"
      />
      <landing-faq-item
        small
        question="What kind of exercises will I be doing with Nymbl?"
        :answer="faqAnswer5"
      />
      <landing-faq-item
        small
        question="What special equipment do I need for balance training?"
        :answer="faqAnswer6"
      />
      <landing-faq-item
        small
        question="What if I don’t have a phone or tablet?"
        :answer="faqAnswer7"
      />

      <landing-faq-item small question="What will Nymbl do with my data?">
        <p class="mb-6">
          Nymbl collects your personal information and engagement data within
          the Nymbl app to improve the product, track progress and enhance your
          member experience.
        </p>
        <p>
          Nymbl’s commitment to you: We promise to never sell your information
          or spam you. We value you and your privacy. Our goal is to help you
          improve your balance and mobility, so we take the responsibility of
          protecting your data seriously. Your information is safe and secure
          with us.
        </p>
      </landing-faq-item>

      <div v-if="!isEnrollmentDisabled" class="text-center">
        <n-button
          rounded-xxl
          variant="white"
          :style="{
            background: `${colors.ctaButtonSecondary}!important`,
            color: '#fff!important',
          }"
          class="mt-2 mb-4 md:mb-8"
          @click="$emit('cta')"
        >
          Get the free Nymbl app
        </n-button>
      </div>
    </div>
  </section>
</template>

<script>
import LandingFaqItem from "../LandingFaqItem.vue";
export default {
  components: {
    LandingFaqItem,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    isEnrollmentDisabled: Boolean,
    colors: Object,
  },
  computed: {
    faqAnswer1() {
      // What is Nymbl?
      return `Nymbl is an easy, fun, and personalized balance training app that you can do in the privacy and comfort of your home - in just 10 minutes a day. All you need is a phone or tablet. Don’t have a phone or tablet? Enroll in Nymbl (with your email address) and gain access to Nymbl’s weekly educational email program. It’s free and fully covered through select organizations.`;
    },
    faqAnswer2() {
      // Who is Nymbl for?
      return `Nymbl is a great choice for you if you’re looking to improve or maintain your balance and stay active.`;
    },
    faqAnswer3() {
      // How does Nymbl work?
      return "Research shows that falls happen when we are distracted, not when we are focused on our balance.² Nymbl helps prepare you for these real-life experiences and distractions by giving you a simple balance exercise to do at the same time as you play a fun brain game on your device. Challenging your brain and body to work together is what helps improve your balance reflex. Nymbl’s scientifically proven¹ app can improve balance by 30%!¹ Nymbl’s scientifically proven app improves your balance, steadiness in walking, and confidence. In fact, 7 out of 10 Nymbl members report improved confidence in their walking and balance since using Nymbl, and 9 out of 10 Nymbl members report they have maintained or improved their engagement with activities they enjoy since using Nymbl. With Nymbl, you can keep moving and participating in activities you enjoy.";
    },
    faqAnswer4() {
      // Why am I being offered balance training?
      return "Your care team is committed to helping you reduce your risk of falling. Together, with Nymbl, our goal is to help you live your best life, free of falls.";
    },
    faqAnswer5() {
      // What kind of exercises will I be doing with Nymbl?
      return "Nymbl is enjoyable and doesn’t require strenuous activity. It involves simple body exercises such as marching in place and shifting your weight to each leg. For those who are seated, Nymbl offers exercises to best suit your needs. These simple body exercises are done in combination with fun brain games, like trivia.";
    },
    faqAnswer6() {
      // What special equipment do I need for balance training?
      return "To do Nymbl’s balance training app, all you need is a phone or tablet. No weights or other exercise equipment is needed!";
    },
    faqAnswer7() {
      // What if I don’t have a phone or tablet?
      return "No problem! Enroll in Nymbl (with your email address) and gain access to Nymbl’s weekly educational email program; it covers topics ranging from trip hazards to dizziness to how to make the most out of your doctor visits. It’s free and fully covered through select organizations.";
    },
  },
};
</script>
