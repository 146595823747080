<template>
  <section
    :style="`background-color: ${colors.faqBg}!important`"
    class="text-white pt-8 md:pt-12 pb-5"
  >
    <div class="inner-container px-3 md:px-8">
      <h2 class="text-center mb-4 font-bold text-xl md:text-4xl leading-5 mt-8">
        {{ $t("careplus.frequently-asked-questions") }}
      </h2>
      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-1')"
        :answer="faqAnswer1"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-2')"
        :answer="faqAnswer2"
      />

      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-3')"
        :answer="faqAnswer3"
      />

      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-4')"
        :answer="faqAnswer4"
      />

      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-5')"
        :answer="faqAnswer5"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-6')"
        :answer="faqAnswer6"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-7')"
        :answer="faqAnswer7"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        :question="$t('careplus.faq-question-8')"
        :answer="faqAnswer8"
      />

      <div v-if="!isEnrollmentDisabled" class="text-center">
        <n-button
          :style="getCTAstyle()"
          variant="white"
          class="mt-2 mb-4 md:mb-8"
          @click="$emit('cta')"
        >
          {{ $t("careplus.get-started-with-nymbl") }}
        </n-button>
      </div>
    </div>
  </section>
</template>

<script>
import LandingFaqItem from "../LandingFaqItem.vue";
export default {
  components: {
    LandingFaqItem,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    isEnrollmentDisabled: Boolean,
    colors: Object,
  },
  computed: {
    faqAnswer1() {
      return this.$t(
        "careplus.nymbl-is-an-easy-fun-and-personalized-balance-training-app-that-you-can-do-in-the-privacy-and-comfort-of-your-home-in-just-10-minutes-a-day-all-you-need-is-a-phone-or-tablet-with-internet-access-dont-have-a-phone-or-tablet-no-problem-enroll-in-nymbl-with-your-email-address-and-gain-access-to-nymbls-weekly-educational-email-program-its-offered-to-you-by-less-than-span-class-font-semibold-greater-than-careplus-less-than-span-greater-than-and-available-through-your-less-than-span-class-font-semibold-greater-than-careplus-less-than-span-greater-than-plan-at-no-additional-cost-to-you"
      );
    },
    faqAnswer2() {
      // Who is Nymbl for?

      return this.$t(
        "careplus.nymbl-is-available-to-eligible-less-than-span-class-font-semibold-greater-than-careplus-less-than-span-greater-than-members-at-no-additional-cost-its-a-great-choice-for-you-if-youre-looking-to-improve-or-maintain-your-balance-and-stay-active"
      );
    },
    faqAnswer3() {
      // How does Nymbl work?

      return this.$t(
        "careplus.research-shows-that-falls-happen-when-we-are-distracted-not-when-we-are-focused-on-our-balance-nymbl-helps-prepare-you-for-these-real-life-experiences-and-distractions-by-combining-simple-body-exercises-that-you-normally-do-in-your-daily-routine-such-as-lifting-your-foot-over-a-rug-with-brain-games-like-trivia-to-challenge-your-body-and-mind-at-the-same-time-nymbls-scientifically-proven-app-can-improve-balance-by-30"
      );
    },
    faqAnswer4() {
      return this.$t("careplus.faq-answer-4");
    },
    faqAnswer5() {
      return this.$t(
        "careplus.nymbl-is-enjoyable-and-doesnt-require-strenuous-activity-it-involves-simple-body-exercises-such-as-marching-in-place-and-shifting-your-weight-to-each-leg-for-those-who-are-seated-nymbl-offers-exercises-to-best-suit-your-needs-these-simple-body-exercises-are-done-in-combination-with-fun-brain-games-like-trivia"
      );
    },
    faqAnswer6() {
      return this.$t(
        "careplus.to-do-nymbls-balance-training-app-all-you-need-is-a-phone-or-tablet-with-internet-access-no-weights-or-other-exercise-equipment-is-needed"
      );
    },
    faqAnswer7() {
      return this.$t(
        "careplus.no-problem-enroll-in-nymbl-with-your-email-address-and-gain-access-to-nymbls-weekly-educational-email-program-it-covers-topics-ranging-from-trip-hazards-to-dizziness-to-how-to-make-the-most-out-of-your-doctor-visits-its-offered-to-you-by-less-than-span-class-font-semibold-greater-than-careplus-less-than-span-greater-than-and-available-through-your-less-than-span-class-font-semibold-greater-than-careplus-less-than-span-greater-than-plan-at-no-additional-cost-to-you"
      );
    },
    faqAnswer8() {
      return this.$t(
        "careplus.nymbl-collects-your-personal-information-and-engagement-data-within-the-nymbl-app-to-improve-the-product-track-progress-and-enhance-your-member-experience-nymbl-does-not-sell-or-share-your-data-with-any-advertisers-or-marketers"
      );
    },
  },
  methods: {
    getCTAstyle() {
      return `color: ${this.colors.ctaButton}!important`;
    },
  },
};
</script>
