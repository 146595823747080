<template>
  <section
    :style="`background-color: ${colors.bg}!important`"
    class="transform translate-y-10 p-5 md:pt-10 md:pb-10 text-white"
  >
    <div class="inner-container">
      <h2
        class="font-bold text-lg md:text-4xl md:leading-8 mb-6 max-w-3xl md:pl-10"
      >
        A personalized app for your unique balance needs
      </h2>
      <p class="mb-4 md:text-base md:leading-5 max-w-3xl md:pl-10">
        Whether you’re seated or standing, Nymbl adapts to your unique balance
        needs - while having fun! The Nymbl Training app lets you choose fun
        games to play on your device and gives you simple balance exercises to
        do at the same time. This is what helps reconnect your brain and body to
        improve your balance reflex.
      </p>
      <div class="flex flex-col lg:flex-row">
        <div class="max-w-3xl md:pl-10">
          <h3 class="font-bold md:text-base mb-6 mt-4">
            Nymbl is a great choice if:
          </h3>
          <ul class="leading-5">
            <landing-list-item small :company="companyName" :text="listText1" />
            <landing-list-item small :company="companyName" :text="listText2" />
            <landing-list-item small :company="companyName" :text="listText3" />
            <landing-list-item small :company="companyName" :text="listText4" />
            <landing-list-item small :company="companyName" :text="listText5" />
            <landing-list-item small :company="companyName" :text="listText6" />
          </ul>
          <n-button
            variant="teal"
            :style="`background-color: ${colors.ctaButtonSecondary}!important; color: #fff!important;border-color: ${colors.ctaButtonSecondary}!important`"
            rounded-xxl
            :class="
              companyName === 'DRCOG' && isVariantB ? 'transform scale-110' : ''
            "
            class="mt-3 md:mt-0 lg:ml-6"
            @click="$emit('cta')"
          >
            Get the free Nymbl app
          </n-button>
        </div>
        <div class="lg:ml-3 lg:max-w-lg">
          <img
            class="m-auto w-2/4 mt-4 md:mt-0"
            src="../../../assets/img/FallsFree/Nymbl_Game_Which_One_Does_Not_Belong.png"
          />
          <div class="flex flex-col mb-8 mt-2 md:mt-4 md:mb-0">
            <a
              :href="appStoreLink"
              target="_blank"
              rel="noopener"
              class="cursor-pointer"
            >
              <img
                style="max-width: 200px"
                class="m-auto mb-2"
                src="../../../assets/img/FallsFree/appstore.svg"
            /></a>
            <a
              :href="playStoreLink"
              target="_blank"
              rel="noopener"
              class="cursor-pointer"
            >
              <img
                style="max-width: 200px"
                class="m-auto"
                src="../../../assets/img/FallsFree/googleplay.svg"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LandingListItem from "../LandingListItem.vue";
export default {
  components: {
    LandingListItem,
  },
  props: {
    companyName: String,
    colors: Object,
    isVariantB: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    appStoreLink:
      "https://apps.apple.com/app/nymbl-balance-training/id1155575069",
    playStoreLink:
      "https://play.google.com/store/apps/details?id=com.b4g.nymbl",
  }),
  computed: {
    listText1() {
      return "You enjoy playing games to sharpen your memory";
    },
    listText2() {
      return "You want to improve your balance";
    },
    listText3() {
      return "You want to maintain your independent lifestyle";
    },
    listText4() {
      return "You are worried about falling";
    },
    listText5() {
      return "You have experienced a fall";
    },
    listText6() {
      return "You are looking for an alternative to an in-person or online balance class";
    },
  },
};
</script>
