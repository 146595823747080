<template>
  <section
    :style="`background-color: ${colors.faqBg}!important`"
    class="text-white pt-8 md:pt-12 pb-5"
  >
    <div class="inner-container px-3 md:px-8">
      <h2 class="text-center mb-4 font-bold text-xl md:text-4xl leading-5 mt-8">
        Frequently asked questions
      </h2>
      <landing-faq-item
        class="mt-6 md:mt-12"
        question="What is Nymbl?"
        :answer="faqAnswer1"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        question="Who is Nymbl for?"
        :answer="faqAnswer2"
      />

      <landing-faq-item
        class="mt-6 md:mt-12"
        question="How does Nymbl work?"
        :answer="faqAnswer3"
      />

      <landing-faq-item
        class="mt-6 md:mt-12"
        question="Why am I being offered balance training?"
      >
        <div>
          At Humana, we’re always looking for opportunities to help you on your
          journey to achieve your best health. That’s why we teamed up with
          Nymbl, a scientifically proven<sup>1</sup> balance training program
          that can improve balance by 30%,<sup>1</sup> at home. Our goal is to
          help you live your best life.
        </div>
      </landing-faq-item>

      <landing-faq-item
        class="mt-6 md:mt-12"
        question="What kind of exercises will I be doing with Nymbl?"
        :answer="faqAnswer5"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        question="What special equipment do I need for balance training?"
        :answer="faqAnswer6"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        question="What if I don’t have a phone or tablet with internet access?"
        :answer="faqAnswer7"
      />
      <landing-faq-item
        class="mt-6 md:mt-12"
        question="What will Nymbl do with my data?"
        :answer="faqAnswer8"
      />

      <div v-if="!isEnrollmentDisabled" class="text-center">
        <n-button
          :style="getCTAstyle()"
          variant="white"
          class="mt-2 mb-4 md:mb-8"
          @click="$emit('cta')"
        >
          Get started with Nymbl
        </n-button>
      </div>
    </div>
  </section>
</template>

<script>
import LandingFaqItem from "../LandingFaqItem.vue";
export default {
  components: {
    LandingFaqItem,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    isEnrollmentDisabled: Boolean,
    colors: Object,
  },
  computed: {
    faqAnswer1() {
      return "Nymbl is an easy, fun, and personalized balance training app that you can do in the privacy and comfort of your home – in just 10 minutes a day. All you need is a phone or tablet with internet access. Don’t have a phone or tablet? No problem! Enroll in Nymbl (with your email address) and gain access to Nymbl’s weekly educational email program. It’s offered to you by Humana and available through your Humana plan at no additional cost to you.";
    },
    faqAnswer2() {
      // Who is Nymbl for?

      return "Nymbl is available to eligible Humana members at no additional cost. It’s a great choice for you if you’re looking to improve or maintain your balance and stay active.";
    },
    faqAnswer3() {
      // How does Nymbl work?

      return "Research shows that falls happen when we are distracted, not when we are focused on our balance.² Nymbl helps prepare you for these real-life experiences and distractions by combining simple body exercises (that you normally do in your daily routine, such as lifting your foot over a rug) with brain games (like trivia) to challenge your body and mind at the same time. Nymbl’s scientifically proven¹ app can improve balance by 30%!¹";
    },
    faqAnswer5() {
      return "Nymbl is enjoyable and doesn’t require strenuous activity. It involves simple body exercises such as marching in place and shifting your weight to each leg. For those who are seated, Nymbl offers exercises to best suit your needs. These simple body exercises are done in combination with fun brain games, like trivia.";
    },
    faqAnswer6() {
      return "To do Nymbl’s balance training app, all you need is a phone or tablet with internet access. No weights or other exercise equipment is needed!";
    },
    faqAnswer7() {
      return "No problem! Enroll in Nymbl (with your email address) and gain access to Nymbl’s weekly educational email program; it covers topics ranging from trip hazards to dizziness to how to make the most out of your doctor visits. It’s offered to you by Humana and available through your Humana plan at no additional cost to you.";
    },
    faqAnswer8() {
      return "Nymbl collects your personal information and engagement data within the Nymbl app to improve the product, track progress and enhance your member experience. Nymbl does not sell or share your data with any advertisers or marketers.";
    },
  },
  methods: {
    getCTAstyle() {
      return `color: ${this.colors.ctaButton}!important`;
    },
  },
};
</script>
