<template>
  <div
    style="background: #93cc78; color: #084d33"
    class="rounded-xl text-xs md:text-sm sm:text-base relative mt-4 pb-5"
    :class="isPlain ? 'p-4' : 'mx-20 p-4'"
  >
    <p class="font-bold mb-2 mt-1">
      {{ $t("eligibility.nymbls-commitment-to-you") }}
    </p>
    <p class="font-normal">
      <span class="font-bold"
        >{{
          $t(
            "eligibility.we-promise-to-never-sell-your-information-or-spam-you"
          )
        }}
      </span>
      {{
        $t(
          "eligibility.we-value-you-and-your-privacy-our-goal-is-to-help-you-improve-your-balance-and-mobility-so-we-take-the-responsibility-of-protecting-your-data-seriously-your-information-is-safe-and-secure-with-us"
        )
      }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    isPlain: { type: Boolean, default: false },
  },
};
</script>
