<template>
  <section class="my-5 px-2 pb-4 mt-2 md:mt-32">
    <div class="inner-container" style="max-width: 1300px">
      <div class="lg:grid lg:grid-cols-2 gap-24 md:px-5">
        <div class="flex flex-col max-w-sm sm:mx-auto md:mx-auto">
          <h1
            :style="`color: ${colors.textGreen}!important`"
            class="my-2 md:my-4 md:text-xl leading-6 md:leading-8 font-bold"
          >
            {{ title }}
          </h1>
          <p
            class="leading-6 pb-4 md:pb-6 md:leading-8 md:text-lg"
            style="max-width: 500px"
          >
            <span v-html="body" />
          </p>

          <div v-if="!isEnrollmentDisabled" class="text-center sm:text-left">
            <n-button
              :style="`background-color: ${colors.ctaButtonSecondary}!important`"
              class="lg:mt-24 sm:w-auto mb-4 sm:mb-6 xl:mt-0 lg:w-auto self-start"
              rounded-xxl
              @click="$emit('cta')"
            >
              Get the free Nymbl app
            </n-button>
          </div>

          <div
            :style="`color: ${colors.plum}!important`"
            class="hidden md:flex flex-col text-left font-bold mt-12 self-start mr-2"
          >
            <span class="text-6xl mb-1">4.8 ★</span>
            <span>Average app rating</span>
          </div>
        </div>
        <div class="max-w-sm sm:mx-auto md:mx-auto lg:m-0 select-none">
          <div>
            <landing-video
              :video-id="videoIdComputed"
              :video-key="videoKeyComputed"
              :company-name="companyName"
              class="transform translate-y-7"
              @track-event="trackEvent"
            />
            <div class="mt-14 mb-4">
              <div class="flex">
                <span
                  class="text-6xl font-bold"
                  :style="{
                    color: colors.ctaButton,
                  }"
                  >“</span
                >
                <p
                  class="font-bold text-base leading-6 md:leading-7 md:text-lg px-2"
                >
                  Nymbl has improved my confidence in my mobility,<br />
                  in and out of my home.
                </p>
                <span
                  class="text-6xl font-bold self-end transform -translate-x-2 md:-translate-x-8"
                  :style="{
                    color: colors.ctaButton,
                    lineHeight: '0!important',
                  }"
                  >”</span
                >
              </div>
              <span class="ml-6 mt-4">- Fraser M.</span>
            </div>
            <div
              :style="`color: ${colors.plum}!important`"
              class="mx-8 md:mx-0 flex md:hidden flex-col text-left font-bold mt-6 self-start mr-2"
            >
              <span class="text-2xl md:text-6xl mb-1">4.8 ★</span>
              <span>Average app rating</span>
            </div>
            <div
              :style="`color: ${colors.plum}!important`"
              class="mx-8 md:mx-0 flex flex-col text-left font-bold mt-6"
            >
              <span class="text-2xl md:text-6xl font-bold">51,000+</span>
              <span class="text-base mt-1"
                >Older adults doing Nymbl in the US</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LandingVideo from "../LandingVideo.vue";
export default {
  components: {
    LandingVideo,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    colors: Object,
    isEnrollmentDisabled: Boolean,
    videoId: {
      default: null,
      type: String,
    },
    videoKey: {
      default: null,
      type: String,
    },
  },
  emits: ["cta", "trackEvent"],
  computed: {
    title() {
      return "Improve your balance at home, for free";
    },
    body() {
      return `Your health plan is excited to offer your Nymbl, a free and fun at-home balance improvement app`;
    },

    videoIdComputed() {
      if (this.videoId) {
        return this.videoId;
      }
      if (this.videoKey) {
        return this.videoKey;
      }

      return "849266118";
    },
    videoKeyComputed() {
      return null;
    },
  },
  methods: {
    trackEvent(event, props) {
      this.$emit("trackEvent", event, props);
    },
  },
};
</script>
<style lang="scss">
.font-bigger {
  font-size: 1.35rem !important;

  @media (max-width: 850px) {
    font-size: 1rem !important;
  }
}
</style>
