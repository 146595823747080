<template>
  <section class="px-3 md:px-6 pt-6 whitespace-pre-wrap">
    <div class="inner-container">
      <div class="font-bold mb-3">Sources:</div>

      <div
        v-if="companyName === 'John Hancock'"
        class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words"
      >
        <p class="mb-5">
          1.Mirelman, et al. Executive Function and Falls in Older Adults: New
          Findings from a Five-Year Prospective Study Link Fall Risk to
          Cognition. (2012). PLoS One, Vol 7:6, e40297. DOI: 10.1371/journal.
          pone.0040297,
          <a
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
          >
        </p>
        <p class="mb-5">
          Nymbl’s technology is intended to improve balance, strength and
          coordination, and to help individuals who have experienced, or are at
          risk for, weakness or loss of balance or mobility. It is important to
          work with a medical professional if you notice that you are developing
          weakness, mobility or balance problems, as this can indicate a serious
          medical condition. Nymbl is not intended to be a substitute for
          professional medical advice, diagnosis or treatment. The information
          provided by Nymbl is for general purposes, and is not intended to
          constitute medical advice by either John Hancock or Nymbl Science. You
          are encouraged to confirm any information provided by Nymbl with other
          sources and your healthcare provider. If you think you may have a
          medical emergency during use of the program, call your doctor or
          emergency medical phone number immediately.
        </p>
        <p class="mb-5">
          Nymbl Science, Inc. is the provider of the Nymbl program and access is
          being made available to you by John Hancock at no cost until December
          15, 2022. After this date, you will no longer have access to the Nymbl
          program through John Hancock. Access to the Nymbl program through John
          Hancock is not available in all states.
        </p>
        <p class="mb-5">
          John Hancock insurance products issued by: John Hancock Life Insurance
          Company (U.S.A.), Boston, MA 02116.
        </p>
        <p>*Testimonials are not from John Hancock customers.</p>
      </div>
      <div v-else>
        <p
          v-if="
            companyName === 'DRCOG' ||
            companyName === 'fallsfree' ||
            companyName === 'Blue MI'
          "
          class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words"
        >
          1.Mirelman, et al. Executive Function and Falls in Older Adults: New
          Findings from a Five-Year Prospective Study Link Fall Risk to
          Cognition. (2012). PLoS One, Vol 7:6, e40297. DOI:
          10.1371/journal.pone.0040297,
          <a
            class="underline"
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
          ><br />
          <br />
          2.Papi E, Chiou S, McGregor AH. Feasibility and acceptability study on
          the use of a smartphone application to facilitate balance training in
          the aging population. BMJ Open 2020;10:e039054. doi:
          10.1136/bmjopen-2020-039054,
          <a
            class="underline"
            :style="`color: ${textColor}!important`"
            href="https://bmjopen.bmj.com/content/10/12/e039054"
            >https://bmjopen.bmj.com/content/10/12/e039054</a
          >
        </p>
        <p
          v-else-if="companyName === 'Blue KC'"
          class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words"
        >
          1.Mirelman, et al. Executive Function and Falls in Older Adults: New
          Findings from a Five-Year Prospective Study Link Fall Risk to
          Cognition. (2012). PLoS One, Vol 7:6, e40297. DOI:
          10.1371/journal.pone.0040297,
          <a
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
          ><br />
          2.Nymbl Research. Nymbl Research Summary. (January 2020). Nymbl
          Science,
          <a
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/Nymbl_ebook_2020_fnl.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/Nymbl_ebook_2020_fnl.pdf</a
          >
        </p>
        <p
          v-else-if="companyName === 'Humana'"
          class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words"
        >
          1. Nymbl Research. (2020).
          <span class="italic">Nymbl Research Summary.</span> Nymbl Science.<br />
          <a
            class="underline"
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/Nymbl_ebook_2020_fnl.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/Nymbl_ebook_2020_fnl.pdf</a
          ><br /><br />
          2. Mirelman, A., Herman, T., Brozgol, M., Dorfman, M., Sprecher, E.,
          Schweiger, A., Giladi, N., Hausdorff, J.M.(2012). Executive Function
          and Falls in Older Adults: New Findings from a Five-Year Prospective
          Link Fall Risk to Cognition.
          <span class="italic">PLoS One, Vol 7:6, e40297.</span> DOI:
          10.1371/journal.pone.0040297,<br />
          <a
            class="underline"
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
          >
        </p>
        <p
          v-else
          class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words"
        >
          1.Nymbl Research. Nymbl Research Summary. (January 2020). Nymbl
          Science,
          <a
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/Nymbl_ebook_2020_fnl.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/Nymbl_ebook_2020_fnl.pdf</a
          ><br />
          2.Mirelman, et al. Executive Function and Falls in Older Adults: New
          Findings from a Five-Year Prospective Study Link Fall Risk to
          Cognition. (2012). PLoS One, Vol 7:6, e40297. DOI:
          10.1371/journal.pone.0040297,
          <a
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
          >Y0040_GHHL2VSEN_2021
        </p>
        <p class="mt-4 mb-2 leading-3 text-sm md:text-base md:leading-4">
          Nymbl’s technology is intended to improve balance, strength and
          coordination, and to help individuals who have experienced, or are at
          risk for, weakness or loss of balance or mobility. It is important to
          work with a medical professional if you notice that you are developing
          weakness, mobility or balance problems, as this can indicate a serious
          medical condition. Nymbl is not intended to be a substitute for
          professional medical advice, diagnosis or treatment. The information
          provided by Nymbl is for general purposes and is not intended to
          constitute medical advice. You are encouraged to confirm any
          information provided by Nymbl with other sources and your healthcare
          provider. If you think you may have a medical emergency during use of
          the program, call your doctor or emergency medical phone number
          immediately.
        </p>
        <p
          v-if="companyName === 'Blue KC'"
          class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words"
        >
          Blue Cross and Blue Shield of Kansas City is an independent licensee
          of the Blue Cross and Blue Shield Association. The HMO products are
          offered by Blue-Advantage Plus of Kansas City, Inc., and the PPO
          products are offered by Missouri Valley Life and Health Insurance
          Company, both wholly-owned subsidiaries of Blue Cross and Blue Shield
          of Kansas City.
        </p>
        <p
          v-else-if="isDisclaimerEnabled"
          class="mb-3 leading-3 text-sm md:text-base md:leading-4 break-words"
        >
          {{
            companyName === "fallsfree" || companyName === "Sales Demo"
              ? "Nymbl"
              : companyName
          }}
          Inc. and its subsidiaries comply with all applicable federal civil
          rights laws and do not discriminate or exclude people on the basis of
          race, color, national origin, ancestry, religion, sex, marital status,
          gender, gender identity, sexual orientation, age, or disability. We
          also provide free language interpreter services. See our
          <a
            v-if="companyName === 'Humana'"
            class="underline text-blue-150 hover:text-blue-200"
            href="https://www.humana.com/legal/accessibility-resources"
            >full accessibility rights information and language options.</a
          ><a v-else
            >full accessibility rights information and language options.</a
          >
        </p>
      </div>
      <p
        v-if="DINNumber"
        class="text-center text-sm md:text-base md:leading-4 my-10"
      >
        {{ DINNumber() }}
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    companyName: String,
    companyDisplayName: String,
    textColor: String,
  },
  computed: {
    isDisclaimerEnabled() {
      if (this.companyName === "Blue MI") {
        return false;
      }
      return true;
    },
  },
  methods: {
    DINNumber() {
      if (this.companyName === "Blue KC") {
        return "Y0126_21-815";
      }
      if (this.companyName === "John Hancock") {
        return "MLI090921224-1";
      }
      return null;
    },
  },
};
</script>
