<template>
  <section class="my-5 px-2 pb-4">
    <div class="inner-container">
      <div class="lg:grid lg:grid-cols-2 md:px-5">
        <div class="flex justify-center items-start flex-col">
          <h1
            :style="`color: ${colors.title}!important`"
            class="my-2 md:my-4 text-xl md:text-4xl leading-6 md:leading-8 font-bold"
          >
            {{ title }}
          </h1>
          <p
            class="leading-5 pb-4 md:pb-6 md:leading-7"
            :class="
              companyName === 'John Hancock' ? 'font-bigger' : ' md:text-lg'
            "
          >
            <span v-html="body" />
          </p>

          <n-button
            v-if="!isEnrollmentDisabled"
            :style="`background-color: ${colors.ctaButton}!important`"
            variant="teal"
            class="md:mt-4 w-full sm:w-auto mb-4 sm:mb-6 xl:mt-0 lg:w-auto"
            @click="$emit('cta')"
          >
            Get started with Nymbl
          </n-button>
        </div>
        <div class="max-w-sm sm:mx-auto md:mx-auto lg:m-0 select-none">
          <div class="lg:h-full">
            <landing-video
              v-if="anonymousFeatureFlagsLoaded"
              :video-id="videoIdComputed"
              :video-key="videoKeyComputed"
              :company-name="companyName"
              @track-event="trackEvent"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LandingVideo from "../LandingVideo.vue";
export default {
  components: {
    LandingVideo,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    colors: Object,
    anonymousFeatureFlags: Object,
    anonymousFeatureFlagsLoaded: Boolean,
    isEnrollmentDisabled: Boolean,
    videoId: {
      default: null,
      type: String,
    },
    videoKey: {
      default: null,
      type: String,
    },
    isVariantA: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    title() {
      return "Good health starts with your balance";
    },
    body() {
      return `Humana is excited to let you know about Nymbl, an <nobr>at-home</nobr> balance improvement program available through your Humana plan at no cost to eligible members`;
    },
    videoIdComputed() {
      if (this.videoId) {
        return this.videoId;
      }
      if (this.videoKey) {
        return this.videoKey;
      }
      return "849266118";
    },
    videoKeyComputed() {
      return null;
    },
  },
  methods: {
    trackEvent(event, props) {
      this.$emit("trackEvent", event, props);
    },
  },
};
</script>
<style lang="scss">
.font-bigger {
  font-size: 1.35rem !important;
  @media (max-width: 850px) {
    font-size: 1rem !important;
  }
}
</style>
