<template>
  <div
    id="landing-page-demo"
    class="m-auto font-roboto"
    style="max-width: 3800px"
  >
    <header>
      <landing-logo :company-name="companyName" />
    </header>

    <landing-horizontal-line :color="colors.line" />

    <landing-embedded-video
      :company-name="companyName"
      @trackEvent="createEvent"
    />

    <landing-header
      class="mt-10 md:mt-10"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="'Sales Demo'"
      video-id="849266118"
      :company-display-name="companyDisplayName"
      :colors="colors"
      :anonymous-feature-flags="anonymousFeatureFlags"
      @cta="callToAction('header')"
      @track-event="createEvent"
    />

    <landing-how
      id="about"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('how it works')"
      @ctaSpot="callToAction('reserve spot')"
    />

    <landing-reviews
      id="testimonials"
      :company-name="companyName"
      :colors="colors"
    />

    <landing-reasons
      id="why"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      hide-sub-header
      :colors="colors"
      @cta="callToAction('reason icons')"
    />

    <landing-faq
      id="faq"
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('faq')"
    />

    <landing-support
      :company-name="companyName"
      :text-color="colors.supportText"
      :title-color="colors.supportTitle"
    />

    <landing-horizontal-line :color="colors.line" />

    <landing-terms
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :text-color="colors.text"
    />

    <footer>
      <landing-logo is-footer :company-name="companyName" />
    </footer>
  </div>
</template>
<script>
import tailwindConfig from "nymbl-ui/tailwind.config";
import LandingFaq from "./LandingFAQDemo.vue";
import LandingLogo from "../LandingLogo.vue";
import LandingHow from "./LandingHowDemo.vue";

import LandingReasons from "../LandingReasons.vue";
import LandingReviews from "./LandingReviewsDemo.vue";
import LandingTerms from "./LandingTermsDemo.vue";
import LandingSupport from "../LandingSupport.vue";
import LandingHeader from "./LandingHeaderDemo.vue";

import LandingEmbeddedVideo from "../LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "../LandingHorizontalLine.vue";
import { useStore } from "@/store.js";

import LandingPageBase from "../LandingPageBase.vue";

export default {
  components: {
    LandingReviews,
    LandingReasons,
    LandingFaq,
    LandingHow,
    LandingLogo,
    LandingSupport,
    LandingTerms,
    LandingHeader,
    LandingEmbeddedVideo,
    LandingHorizontalLine,
  },
  extends: LandingPageBase,

  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      companyName: "Sales Demo",
      anonymousFeatureFlagsLoaded: false,
      anonymousSettings: null,
      mobileDevice: null,
      scrollTopTimer: 0,
      scrollTopY: 0,
    };
  },
  computed: {
    colors() {
      const colors = {
        text: "#000",
        textOrange: "#f17d06",
        title: "#000",
        supportText: "#000",
        supportTitle: "#000",
        howItWorksBorder: this.colorPalette.green[150],
        howItWorksTitle: "#000",
        howItWorksTitleSecond: this.colorPalette.green[150],
        ctaButton: "#f17d00",
        ctaButtonSecondary: "#f17d00",
        reviewsBg: this.colorPalette.green[150],
        bg: this.colorPalette.green[150],
        faqBg: this.colorPalette.green[150],
        line: this.colorPalette.green[150],
      };

      return colors;
    },
    colorPalette() {
      return tailwindConfig.theme.extend.colors;
    },

    isEnrollmentDisabled() {
      return false;
    },
  },
};
</script>

<style lang="scss">
.new-ui {
  background: #fff !important;
  font-size: 24px !important;
}

#landing-page-demo {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
