<template>
  <section :style="`background-color: ${colors.faqBg}!important`" class="pb-5">
    <div class="inner-container max-w-3xl px-3 md:px-8 text-base">
      <h2 class="text-center mb-6 font-bold text-lg md:text-4xl leading-5 mt-5">
        Frequently asked questions
      </h2>
      <landing-faq-item small question="What is Nymbl?" :answer="faqAnswer1" />
      <landing-faq-item
        small
        question="Who is Nymbl for?"
        :answer="faqAnswer2"
      />

      <landing-faq-item
        small
        question="How does Nymbl work?"
        :answer="faqAnswer4"
      />
      <landing-faq-item
        small
        question="Why am I being offered balance training?"
        :answer="faqAnswer5"
      />
      <landing-faq-item
        small
        question="What kind of exercises will I be doing with Nymbl?"
        :answer="faqAnswer6"
      />
      <landing-faq-item
        small
        question="What special equipment do I need?"
        :answer="faqAnswer7"
      />
      <landing-faq-item
        small
        question="What if I don’t have a phone or tablet?"
        :answer="faqAnswer8"
      />
    </div>
  </section>
</template>

<script>
import LandingFaqItem from "../LandingFaqItem.vue";
export default {
  components: {
    LandingFaqItem,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    isEnrollmentDisabled: Boolean,
    colors: Object,
  },
  computed: {
    faqAnswer1() {
      // What is Nymbl?
      return `Nymbl is an easy, fun, and personalized app-based program that you can do in the privacy and comfort of your home - in just 10 minutes a day. All you need is a phone or tablet. Don’t have a phone or tablet? Enroll in Nymbl (with your email address) and gain access to Nymbl’s weekly educational email program. Nymbl is FREE and fully covered by ACC, so there’s no cost to you! `;
    },
    faqAnswer2() {
      // Who is Nymbl for?
      return `Nymbl is for older adults who are looking to improve or maintain their balance in the comfort and safety of their home. All you need is a smartphone or tablet.`;
    },
    faqAnswer4() {
      // How does Nymbl work?
      return "Research shows that falls happen when we are distracted, not when we are focused on our balance.² Nymbl helps prepare you for these real-life experi-ences and distractions by giving you a simple balance exercise to do at the same time as you play a fun brain game on your device. Challenging your brain and body to work together is what helps improve your balance reflex. Nymbl’s scientifically proven¹ app can improve balance by 30%!¹ Nymbl’s scientifically proven app improves your balance, steadiness in walking, and confidence. In fact, 7 out of 10 Nymbl members report improved confidence in their walking and balance since using Nymbl, and 9 out of 10 Nymbl members report they have maintained or improved their engagement with activities they love since using Nymbl. With Nymbl, you can keep moving and participating in activities you enjoy.";
    },
    faqAnswer5() {
      // Why am I being offered balance training?
      return "We want to help reduce your risk of a fall, so you can live without the worry of falling. Nymbl can help improve your balance so you can live confidently and independently.";
    },
    faqAnswer6() {
      // What kind of exercises will I be doing with Nymbl?
      return "Nymbl is enjoyable and doesn’t require strenuous activity. It involves simple body exercises such as marching in place and shifting your weight to each leg. For those who are seated, Nymbl offers exercises to best suit your needs. These simple body exercises are done in combination with fun brain games, like trivia.";
    },
    faqAnswer7() {
      // What special equipment do I need?
      return "To do Nymbl’s balance training app, all you need is a phone or tablet. No weights or other exercise equipment is needed!";
    },
    faqAnswer8() {
      // What if I don’t have a phone or tablet?
      return "No problem! Enroll in Nymbl (with your email address) and gain access to Nymbl’s weekly educational email program; it covers topics ranging from trip hazards to dizziness to how to make the most out of your general practitioner visits. Nymbl is FREE and fully covered by ACC, so there’s no cost to you!";
    },
  },
};
</script>
