<template>
  <section
    :style="`color: ${textColor}`"
    class="my-5 md:my-10 text-center text-sm md:text-base leading-5 md:leading-5 px-2"
  >
    <p
      class="font-bold text-xl md:text-4xl mb-2"
      :style="`color: ${titleColor}`"
    >
      {{ title }}
    </p>
    <p class="mb-2 md:text-lg">
      {{ desc }}
    </p>

    <div
      v-if="isSupportPhoneEnabled"
      class="mt-3"
      :style="`color: ${textColor}`"
    >
      {{ phoneNumber }}
    </div>
    <a :style="`color: ${textColor}`" :href="`mailto:${supportEmail}`">
      {{ supportEmail }}</a
    >
    <p>
      {{ $t("careplus.monday-friday") }}<br />
      {{ $t("careplus.8-a-m-5-p-m-mt") }}
    </p>
  </section>
</template>

<script>
export default {
  props: {
    companyName: String,
    textColor: String,
    titleColor: String,
  },
  computed: {
    title() {
      return this.$t("DRCOG.need-help");
    },
    desc() {
      return this.$t("nymbls-friendly-support-team-is-here-for-you");
    },
    phoneNumber() {
      return "(800) 672-6854 (TTY: 711)";
    },
    supportEmail() {
      let email = "support@nymblscience.com";
      return email;
    },

    isSupportPhoneEnabled() {
      let isEnabled = true;

      if (this.companyName === "fallsfree") {
        isEnabled = false;
      }
      return isEnabled;
    },
  },
};
</script>
