import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "@/views/FallsFree/LandingPage.vue";
import LandingPageDemo from "@/views/FallsFree/Demo/LandingPageDemo.vue";

import LandingPageDRCOG from "@/views/FallsFree/DRCOG/LandingPageDRCOG.vue";
import FreeTipsDRCOG from "@/views/FallsFree/DRCOG/FreeTips/FreeTipsDRCOG.vue";

import LandingPageHumana from "@/views/FallsFree/Humana/LandingPageHumana.vue";
import LandingPageBlueMi from "@/views/FallsFree/BlueMi/LandingPageBlueMi.vue";
import LandingPageCarePlus from "@/views/FallsFree/CarePlus/LandingPageCarePlus.vue";
import LandingPageACC from "@/views/FallsFree/ACC/LandingPageACC.vue";
// import LandingPageElevance from "@/views/FallsFree/Elevance/LandingPageElevance.vue";

import LandingEnrolled from "@/views/FallsFree/LandingEnrolled.vue";
import LandingBrandedVideo from "@/views/FallsFree/LandingBrandedVideo.vue";
import LandingPersonalizedVideo from "@/views/FallsFree/LandingPersonalizedVideo.vue";

import getCompanyName from "@/views/FallsFree/getCompanyName";

import EligibilitySolera from "@/views/Eligibility/EligibilityFlow/EligibilitySolera.vue";
import EligibilitySoleraHome from "@/views/Eligibility/EligibilityFlow/EligibilitySoleraHome.vue";
import EligibilitySoleraError from "@/views/Eligibility/EligibilityFlow/EligibilitySoleraError.vue";

import routesEligibility from "@/router/routes/routesEligibility.js";

// Legal
const Privacy = () => import("@/views/Legal/Privacy.vue");
const Terms = () => import("@/views/Legal/Terms.vue");

const Forbidden = () => import("@/views/403.vue");
const PageNotFound = () => import("@/views/404.vue");

const BalanceTestRedirect = () => import("@/views/BalanceTestRedirect.vue");

const LandingSignup = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilitySignUp.vue");

const LandingGetAppText = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityGetAppText.vue");

const LandingGetAppTextSuccess = () =>
  import(
    "@/views/Eligibility/EligibilityFlow/EligibilityGetAppTextSuccess.vue"
  );

const LandingGetAppStore = () =>
  import("@/views/Eligibility/EligibilityFlow/EligibilityGetAppStore.vue");

const LandingInstructionsAndroid = () =>
  import(
    "@/views/Eligibility/EligibilityFlow/EligibilityInstructionsAndroid.vue"
  );
const LandingInstructionsApple = () =>
  import(
    "@/views/Eligibility/EligibilityFlow/EligibilityInstructionsApple.vue"
  );

import DownloadPdf from "@/views/FallsFree/DownloadPdf.vue";

const getRootPage = (isName = false) => {
  const companyName = getCompanyName();

  if (companyName === "Sales Demo") {
    return isName ? "LandingPageDemo" : LandingPageDemo;
  }

  if (companyName === "Blue Shield of California") {
    return isName ? "EligibilitySolera" : EligibilitySolera;
  }

  if (companyName === "DRCOG") {
    return isName ? "LandingPageDRCOG" : LandingPageDRCOG;
  }

  if (companyName === "Blue MI") {
    return isName ? "LandingPageBlueMi" : LandingPageBlueMi;
  }

  if (companyName === "Humana") {
    return isName ? "LandingPageHumana" : LandingPageHumana;
  }

  if (companyName === "CarePlus") {
    return isName ? "LandingPageCarePlus" : LandingPageCarePlus;
  }

  if (companyName === "ACC") {
    return isName ? "LandingPageACC" : LandingPageACC;
  }

  if (companyName === "Elevance") {
    return isName
      ? "LandingPageElevance"
      : () => import("@/views/FallsFree/Elevance/LandingPageElevance.vue");
  }

  return isName ? "LandingPage" : LandingPage;
};

const routesLegal = [
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
  },
  {
    path: "/terms",
    name: "Terms",
    component: Terms,
  },
];

const routesLandingPage = [
  {
    path: "/:pathMatch(.*)*",
    component: PageNotFound,
    name: "404",
  },

  {
    path: "/403",
    name: "403",
    component: Forbidden,
  },
  {
    path: "/",
    name: getRootPage(true),
    component: getRootPage(),
    beforeEnter: (to, from, next) => {
      if (getCompanyName() === "fallsfree") {
        next({
          path: "/EligibilityFlow/Verify",
          query: { ...to.query, interestCategory: 3, region: "us" },
        });
      } else {
        next();
      }
    },

    ...(getCompanyName() === "Blue Shield of California" && {
      children: [
        {
          path: "/",
          name: "EligibilitySoleraHome",
          component: EligibilitySoleraHome,
        },
        {
          path: "/Solera/Error",
          name: "EligibilitySoleraError",
          component: EligibilitySoleraError,
        },
      ],
    }),
  },
  {
    path: "/esp",
    name: getRootPage(true) + "Es",
    component: getRootPage(),
    meta: {
      lang: "es",
    },
  },
  {
    ...(getCompanyName() === "DRCOG" && {
      path: "/freetips",
      name: "FreeTipsDRCOG",
      component: FreeTipsDRCOG,
    }),
  },
  {
    path: "/balance",
    name: "BalanceTestRedirect",
    component: BalanceTestRedirect,
  },
  {
    path: "/pdf",
    name: "DownloadPdf",
    component: DownloadPdf,
  },
  {},
  {
    path: "/Enrolled",
    component: LandingEnrolled,

    meta: {
      isNavbarDisabled: true,
    },
    children: [
      {
        path: "/",
        name: "LandingSignup",
        component: LandingSignup,
        meta: {
          isNavbarDisabled: true,
        },
      },
      {
        path: "AppText",
        name: "LandingGetAppText",
        component: LandingGetAppText,
        meta: {
          isNavbarDisabled: true,
        },
      },
      {
        path: "AppTextSuccess",
        name: "LandingGetAppTextSuccess",
        component: LandingGetAppTextSuccess,
        meta: {
          isNavbarDisabled: true,
        },
      },
      {
        path: "AppStore",
        name: "LandingGetAppStore",
        component: LandingGetAppStore,
        meta: {
          isNavbarDisabled: true,
        },
      },
      {
        path: "AppStoreAndroid",
        name: "LandingInstructionsAndroid",
        component: LandingInstructionsAndroid,
        meta: {
          isNavbarDisabled: true,
        },
      },
      {
        path: "AppStoreApple",
        name: "LandingInstructionsApple",
        component: LandingInstructionsApple,
        meta: {
          isNavbarDisabled: true,
        },
      },
    ],
  },
  {
    path: "/Video",
    name: "LandingBrandedVideo",
    component: LandingBrandedVideo,

    meta: {
      isNavbarDisabled: true,
    },
  },
  {
    path: "/User/Video",
    name: "LandingPersonalizedVideo",
    component: LandingPersonalizedVideo,

    meta: {
      isNavbarDisabled: true,
    },
  },
]
  .concat(routesEligibility)
  .concat(routesLegal);

const router = createRouter({
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
  history: createWebHistory(process.env.BASE_URL),
  routes: routesLandingPage,
});

export default router;
