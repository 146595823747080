<template>
  <div
    id="landing-page"
    class="m-auto"
    style="max-width: 3800px"
    :style="`color: ${colors.text}!important`"
  >
    <landing-embedded-video
      :company-name="companyName"
      @trackEvent="createEvent"
    />

    <div class="inner-container">
      <img class="ml-5" style="max-width: 200px" :src="getLogoPath()" />
    </div>

    <landing-horizontal-line :color="colors.line" />

    <landing-header-humana
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :company-display-name="companyDisplayName"
      :colors="colors"
      :anonymous-feature-flags="anonymousFeatureFlags"
      :anonymous-feature-flags-loaded="anonymousFeatureFlagsLoaded"
      @cta="callToAction('header')"
      @trackEvent="createEvent"
    />

    <landing-reviews :company-name="companyName" :colors="colors" />

    <landing-reasons
      hide-sub-header
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('reason icons')"
    />

    <landing-how
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('how it works')"
      @ctaSpot="callToAction('reserve spot')"
    />

    <landing-faq
      :is-enrollment-disabled="isEnrollmentDisabled"
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :colors="colors"
      @cta="callToAction('faq')"
    />

    <landing-support
      :company-name="companyName"
      :text-color="colors.supportText"
      :title-color="colors.supportTitle"
    />
    <div class="flex justify-center">
      <landing-logo is-footer :company-name="companyName" />
    </div>

    <landing-horizontal-line :color="colors.line" />

    <landing-terms
      :company-display-name="companyDisplayName"
      :company-name="companyName"
      :text-color="colors.text"
    />

    <footer>
      <p
        v-if="DINNumberFooter"
        class="text-center text-sm md:text-base md:leading-4 my-10"
      >
        {{ DINNumberFooter() }}
      </p>
    </footer>
  </div>
</template>

<script>
import tailwindConfig from "nymbl-ui/tailwind.config";
import LandingFaq from "./LandingFAQHumana.vue";
import LandingLogo from "../LandingLogo.vue";
import LandingHow from "./LandingHowHumana.vue";
import LandingReasons from "../LandingReasons.vue";
import LandingReviews from "../LandingReviews.vue";
import LandingTerms from "./LandingTermsHumana.vue";
import LandingSupport from "../LandingSupport.vue";
import LandingHeaderHumana from "./LandingHeaderHumana.vue";
import LandingEmbeddedVideo from "../LandingEmbeddedVideo.vue";
import LandingHorizontalLine from "../LandingHorizontalLine.vue";

import { useStore } from "@/store.js";
import LandingPageBase from "../LandingPageBase.vue";

export default {
  components: {
    LandingReviews,
    LandingReasons,
    LandingFaq,
    LandingHow,
    LandingLogo,
    LandingSupport,
    LandingTerms,
    LandingHeaderHumana,
    LandingEmbeddedVideo,
    LandingHorizontalLine,
  },
  extends: LandingPageBase,

  setup() {
    const store = useStore();
    return { store };
  },
  data() {
    return {
      companyName: "Humana",
      anonymousFeatureFlagsLoaded: false,
      anonymousSettings: null,
      mobileDevice: null,
    };
  },
  computed: {
    colors() {
      const colors = {
        text: "#000",
        title: "#000",
        supportText: "#000",
        supportTitle: "#000",
        howItWorksBorder: this.colorPalette.green[150],
        howItWorksTitle: "#000",
        howItWorksTitleSecond: this.colorPalette.green[150],
        ctaButton: this.colorPalette.green[150],
        reviewsBg: this.colorPalette.green[150],
        faqBg: this.colorPalette.green[150],
        line: this.colorPalette.green[150],
      };

      if (this.companyName === "Humana") {
        colors.title = "#114A21";
        colors.text = "#53575A";
        colors.supportTitle = "#102F54";
        colors.howItWorksTitleSecond = "#547634";
        colors.ctaButton = "#102F54";
        colors.howItWorksBorder = "#88C545";
        colors.reviewsBg = "#5c9a1b";
        colors.line = "#78be20";
        colors.faqBg = "#5c9a1b";
      }

      return colors;
    },
    colorPalette() {
      return tailwindConfig.theme.extend.colors;
    },

    isEnrollmentDisabled() {
      return false;
    },
  },

  methods: {
    DINNumberFooter() {
      return "Y0040_GHHLQCEEN_C";
    },
    getLogoPath() {
      return require("../../../assets/img/FallsFree/" + "HumanaLogo.png");
    },
  },
};
</script>

<style lang="scss">
.new-ui {
  background: #fff !important;
  font-size: 24px !important;
}
#landing-page {
  .n-button {
    padding-left: 25px !important;
    padding-right: 25px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  @media (min-width: 1024px) {
    .n-button {
      padding-left: 50px !important;
      padding-right: 50px !important;
      padding-top: 25px !important;
      padding-bottom: 25px !important;
    }
  }
}

.inner-container {
  max-width: 1400px;
  margin: 0 auto;
}
</style>
