<template>
  <div
    class="shadow md:rounded-xl text-center bg-white pl-8 pr-8 mt-12 md:mt-0"
  >
    <div class="transform -translate-y-8">
      <img
        class="rounded-full m-auto mx-auto"
        style="max-width: 100px"
        :src="getImgUrl(img)"
      />

      <h3 class="font-bold text-lg mb-2 leading-6">
        {{ name }}
      </h3>
      <p class="leading-5">“{{ review }}”</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    img: String,
    review: String,
  },
  methods: {
    getImgUrl(pic) {
      return require("../../assets/img/FallsFree/" + pic);
    },
  },
};
</script>
