import { reactive, computed } from "vue";

const stateInitial = {
  eligibleCompanyModels: [],
  selectedCompanyData: {},
  selectedCompany: null,
};

let state = reactive({ ...stateInitial });

const updateState = (property, value) => {
  state[property] = value;
};

const eligibleCompanyModels = computed(() => state.eligibleCompanyModels);
const selectedCompanyData = computed(() => state.selectedCompanyData);
const selectedCompany = computed(() => state.selectedCompany);

export {
  state,
  updateState,
  eligibleCompanyModels,
  selectedCompany,
  selectedCompanyData,
};
