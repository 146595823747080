<template>
  <section class="text-center my-4 pt-2 md:pt-5">
    <div class="inner-container px-2 md:px-4">
      <h2
        :style="`color: ${colors.title}`"
        class="font-bold md:leading-6 mb-3 md:mb-6"
        :class="
          companyName === 'Humana' || companyName === 'ACC'
            ? 'text-lg md:text-4xl'
            : 'md:text-lg'
        "
      >
        {{ title }}
      </h2>
      <p v-if="!hideSubHeader" class="leading-5 md:leading-7 md:text-lg">
        <span v-if="companyName === 'fallsfree' || companyName === 'Sales Demo'"
          >Your care team is committed to helping you reduce your risk of
          falling. Together, with Nymbl, our goal is to help you live your best
          life, free of falls.
        </span>
        <span v-else-if="companyName === 'DRCOG'"
          >{{
            $t(
              "DRCOG.here-in-colorado-were-always-looking-for-opportunities-to-help-you-achieve-your-best-health-together-with-nymbl-our-goal-is-to-help-you-live-your-best-life-free-of-falls"
            )
          }}
        </span>
        <span v-else-if="companyName === 'Blue KC'"
          >Your well-being is our top priority, and {{ companyDisplayName }} is
          always looking for opportunities to help you achieve your best health.
          Together, with Nymbl, our goal is to help you live your best life,
          free of falls.</span
        >
        <span v-else-if="companyName === 'John Hancock'">
          {{ companyDisplayName }} is always looking for opportunities to help
          you achieve your best health.
        </span>
        <span v-else-if="companyName === 'Blue MI'">
          Your health plan is always looking for opportunities to help you
          achieve your best health. Together with Nymbl, our goal is to help you
          live your best life, free of falls.</span
        >
        <span v-else-if="companyName === 'Humana'">
          At Humana, we’re always looking for opportunities to help you on your
          journey to achieve your best health. Together, with Nymbl, our goal is
          to help you live your best life, free of falls.</span
        >
        <span v-else>
          At {{ companyDisplayName }}, we’re always looking for opportunities to
          help you achieve your best health. Together, with Nymbl, our goal is
          to help you live your best life, free of falls.</span
        >
      </p>
      <div class="flex flex-col lg:flex-row mt-6">
        <landing-icon
          :padding="companyName === 'John Hancock' ? true : false"
          :img="valuePropIcon1"
          :text="valuePropText1"
          :company-name="companyName"
        />
        <landing-icon
          :company-name="companyName"
          :padding="companyName === 'John Hancock' ? true : false"
          :img="valuePropIcon2"
          :text="valuePropText2"
        />
        <landing-icon
          :company-name="companyName"
          :padding="companyName === 'John Hancock' ? true : false"
          :sup="
            companyName === 'Blue KC' || companyName === 'John Hancock'
              ? false
              : '1'
          "
          :img="valuePropIcon3"
          :text="valuePropText3"
        />

        <landing-icon
          :company-name="companyName"
          :padding="companyName === 'John Hancock' ? true : false"
          :sup="
            companyName === 'Blue KC' || companyName === 'John Hancock'
              ? false
              : '1'
          "
          :img="valuePropIcon4"
          :text="valuePropText4"
        />
        <landing-icon
          :company-name="companyName"
          :padding="companyName === 'John Hancock' ? true : false"
          :img="valuePropIcon5"
          :text="valuePropText5"
        />
      </div>
      <n-button
        v-if="!isEnrollmentDisabled"
        :style="`background-color: ${colors.ctaButton}!important`"
        class="mt-8"
        :class="
          companyName === 'DRCOG' || (companyName === 'Blue MI' && isVariantB)
            ? 'transform scale-110'
            : ''
        "
        variant="teal"
        :rounded-xxl="
          companyName === 'Humana' || companyName === 'CarePlus' ? false : true
        "
        @click="$emit('cta')"
      >
        {{ ctaText }}
      </n-button>
    </div>
  </section>
</template>

<script>
import LandingIcon from "./LandingIcon.vue";
export default {
  components: {
    LandingIcon,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    colors: Object,
    isEnrollmentDisabled: Boolean,
    hideSubHeader: {
      default: false,
      type: Boolean,
    },
    isVariantB: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    title() {
      if (this.companyName === "John Hancock") {
        return "Why you’ll love Nymbl";
      }
      return this.$t("DRCOG.reasons-why-youll-love-nymbl");
    },
    ctaText() {
      if (
        this.companyName === "DRCOG" ||
        this.companyName === "ACC" ||
        this.companyName === "Blue MI"
      ) {
        return this.$t("DRCOG.get-the-free-nymbl-app");
      }
      return "Get started with Nymbl";
    },
    valuePropText1() {
      const companyName = this.companyName;

      if (companyName == "fallsfree" || companyName == "Sales Demo") {
        return "Free";
      }
      if (companyName == "DRCOG") {
        return this.$t("DRCOG.provided-free-to-adults-ages-60-and-up");
      }
      if (companyName == "Blue KC") {
        return `Provided through your ${this.companyDisplayName} plan at no cost to you`;
      }
      if (companyName == "Blue MI") {
        return `Free, 100% paid by your health plan`;
      }
      if (companyName == "John Hancock") {
        return `Available to you by ${this.companyDisplayName} at no additional cost`;
      }
      if (companyName == "CarePlus") {
        return this.$t(
          "careplus.provided-through-your-careplus-plan-at-no-additional-cost-to-you"
        );
      }
      if (companyName == "ACC") {
        return `Fully covered by ACC. There's no cost to you.`;
      }
      return `Provided through your ${this.companyDisplayName} plan at no additional cost to you`;
    },
    valuePropText2() {
      if (this.companyName == "John Hancock") {
        return "In the comfort of your home";
      }
      return this.$t("safely-done-in-the-comfort-of-your-own-home");
    },
    valuePropText3() {
      if (this.companyName === "Blue KC") {
        return "Can increase independence and confidence";
      }

      if (this.companyName === "John Hancock") {
        return "Intended to help increase independence and confidence";
      }
      return this.$t("shown-to-increase-independence-and-confidence");
    },
    valuePropText4() {
      if (this.companyName === "Blue KC") {
        return "Can improve balance and decrease fall risk";
      }

      if (this.companyName === "John Hancock") {
        return "Designed to help improve balance and reduce your risk of falls";
      }
      return this.$t("shown-to-improve-balance-and-decrease-fall-risk");
    },
    valuePropText5() {
      if (this.companyName === "John Hancock") {
        return "Fits into your routine - just 10 minutes per day";
      }
      return this.$t("easily-fits-into-your-routine-only-10-minutes-day");
    },
    valuePropIcon1() {
      if (this.companyName === "Humana") {
        return "piggy-bank-humana.svg";
      }
      if (this.companyName === "CarePlus") {
        return "money-careplus.png";
      }
      if (this.companyName === "Blue KC") {
        return "blue-kc-icon-1.png";
      }
      if (this.companyName === "John Hancock") {
        return "piggy-bank-jh.svg";
      }
      if (
        this.companyName === "ACC" ||
        this.companyName === "DRCOG" ||
        this.companyName === "Blue MI"
      ) {
        return "NymblIcons_MossEmerald__PiggyBank-106.svg";
      }
      return "piggy-bank.svg";
    },
    valuePropIcon2() {
      if (this.companyName === "Humana") {
        return "house-humana.svg";
      }
      if (this.companyName === "CarePlus") {
        return "house-careplus.png";
      }
      if (this.companyName === "Blue KC") {
        return "blue-kc-icon-2.png";
      }
      if (this.companyName === "John Hancock") {
        return "house-jh.svg";
      }
      if (
        this.companyName === "ACC" ||
        this.companyName === "DRCOG" ||
        this.companyName === "Blue MI"
      ) {
        return "NymblIcons_MossEmerald__House-108.svg";
      }
      return "house.svg";
    },
    valuePropIcon3() {
      if (this.companyName === "Humana") {
        return "like-humana.svg";
      }
      if (this.companyName === "CarePlus") {
        return "thumbs-careplus.png";
      }
      if (this.companyName === "Blue KC") {
        return "blue-kc-icon-3.png";
      }
      if (this.companyName === "John Hancock") {
        return "like-jh.svg";
      }
      if (
        this.companyName === "ACC" ||
        this.companyName === "DRCOG" ||
        this.companyName === "Blue MI"
      ) {
        return "NymblIcons_MossEmerald__HeartTrust-107.svg";
      }
      return "trust.svg";
    },
    valuePropIcon4() {
      if (this.companyName === "Humana") {
        return "shoe-humana.svg";
      }
      if (this.companyName === "CarePlus") {
        return "shoe-careplus.png";
      }
      if (this.companyName === "Blue KC") {
        return "blue-kc-icon-4.png";
      }
      if (this.companyName === "John Hancock") {
        return "active-jh.svg";
      }
      if (
        this.companyName === "ACC" ||
        this.companyName === "DRCOG" ||
        this.companyName === "Blue MI"
      ) {
        return "NymblIcons_MossEmerald__Shoe-118.svg";
      }
      return "shoes.svg";
    },
    valuePropIcon5() {
      if (this.companyName === "Humana") {
        return "clock-humana.svg";
      }
      if (this.companyName === "CarePlus") {
        return "clock-careplus.png";
      }
      if (this.companyName === "Blue KC") {
        return "blue-kc-icon-5.png";
      }
      if (this.companyName === "John Hancock") {
        return "clock-jh.svg";
      }
      if (
        this.companyName === "ACC" ||
        this.companyName === "DRCOG" ||
        this.companyName === "Blue MI"
      ) {
        return "NymblIcons_MossEmerald__ClockQuick-109.svg";
      }
      return "clock.svg";
    },
  },
};
</script>
