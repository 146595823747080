<template>
  <section
    :style="{ background: colors.bgLight, borderColor: colors.green }"
    class="text-center my-2 pb-12 mx-0 md:mx-10 mt-3 relative z-50"
  >
    <h2
      class="pt-6 mb-4 mt-4 font-bold leading-6 md:leading-8 md:pt-10 text-lg lg:text-4xl px-2"
    >
      {{ title }}
    </h2>

    <div
      :style="`color: ${colors.text}!important; max-width: 1300px; margin: 0 auto;`"
      class="lg:grid lg:grid-cols-3 lg:gap-8 lg:pt-10 lg:mt-2"
    >
      <LandingReview
        :colors="colors"
        class="md:mb-12 md:mt-12 lg:m-0"
        name="Brenda W."
        review="Before Nymbl, my body was stiff. Nymbl helps me move and makes my body feel better."
      />
      <LandingReview
        :colors="colors"
        class="md:mb-12 lg:m-0"
        name="Hunter L."
        review="I am a new man after two weeks of using Nymbl. I’m having fun again."
      />
      <LandingReview
        :colors="colors"
        class="md:mb-2 lg:m-0"
        name="Dianne E. "
        review="Nymbl is a fun experience. I never thought playing Simon would improve my balance."
      />
    </div>
  </section>
</template>

<script>
import LandingReview from "./LandingReviewACC.vue";

export default {
  components: {
    LandingReview,
  },
  props: {
    colors: Object,
    companyName: String,
  },
  computed: {
    title() {
      return "See why people like you love Nymbl";
    },
  },
};
</script>
