export default [
  {
    videoId: "635466334",
    videoTitle: "Your new at-home balance program",
  },
  {
    videoId: "414895047",
    videoTitle: "Me + My Balance: How important is strength in my balance?",
  },
  {
    videoId: "414889055",
    videoTitle: "Me + My Balance: Does removing trip hazards make me safer?",
  },
  {
    videoId: "414867285",
    videoTitle: "Me + My Balance: Does walking improve my balance?",
  },
  {
    videoId: "414923838",
    videoTitle: "Me + My Balance: Is falling normal?",
  },
  {
    videoId: "414906744",
    videoTitle: "Me + My Balance: What is my brain’s role in balance?",
  },
  {
    videoId: "414879742",
    videoTitle: "Me + My Balance: How can I test my own balance?",
  },
  {
    videoId: "414903416",
    videoTitle: "Me + My Balance: Why should I have my feet checked?",
  },
  {
    videoId: "414861179",
    videoTitle: "Me + My Balance: Why do I get dizzy?",
  },
  {
    videoId: "414873137",
    videoTitle: "Me + My Balance: What are some common reasons for falling?",
  },
  {
    videoId: "414884076",
    videoTitle: "Me + My Balance: Why are falls more frequent at night?",
  },
  {
    videoId: "574526587",
    videoTitle: "Me + My Balance: Why does change of light make me unsteady?",
  },
  {
    videoId: "574533287",
    videoTitle: "Me + My Balance: How can I stay on track with Nymbl?",
  },
  {
    videoId: "574527097",
    videoTitle:
      "Me + My Balance: How do anxiety and depression relate to falling?",
  },
  {
    videoId: "574527690",
    videoTitle: "Me + My Balance: Why am I fearful of falling?",
  },
  {
    videoId: "574528808",
    videoTitle: "Me + My Balance: Why do I find myself avoiding curbs?",
  },
  {
    videoId: "574530131",
    videoTitle:
      "Me + My Balance: Is it normal to hold onto things while I walk?",
  },
  {
    videoId: "574531416",
    videoTitle: "Me + My Balance: How does vision affect my balance?",
  },
  {
    videoId: "574532345",
    videoTitle:
      "Me + My Balance: How can physical inactivity affect my balance?",
  },
  {
    videoId: "574534235",
    videoTitle:
      "Me + My Balance: Why should I talk to my doctor about falling?",
  },
  {
    videoId: "574535458",
    videoTitle: "Me + My Balance: How can I improve my diet?",
  },
  {
    videoId: "574536079",
    videoTitle: "Me + My Balance: How are my heart and brain health related?",
  },
  {
    videoId: "574536915",
    videoTitle: "Me + My Balance: How can I continue doing activities I enjoy?",
  },
  {
    videoId: "574537707",
    videoTitle: "Me + My Balance: What if I have pain when I move?",
  },
  {
    videoId: "574538885",
    videoTitle:
      "Me + My Balance: Why do I feel down at certain times of the year?",
  },
  {
    videoId: "574539893",
    videoTitle: "Me + My Balance: How can I set goals to stay mobile?",
  },
  {
    videoId: "574540933",
    videoTitle: "Me + My Balance: How can I improve my sleep?",
  },
  {
    videoId: "574541519",
    videoTitle: "Me + My Balance: What should I know about blood pressure?",
  },
];
