<template>
  <section class="px-3 md:px-6 pt-6 whitespace-pre-wrap">
    <div class="inner-container">
      <div class="font-bold mb-3">Sources:</div>
      <p class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words">
        <span
          >1.Papi E, Chiou S, McGregor AH. Feasibility and acceptability study
          on the use of a smartphone application to facilitate balance training
          in the aging population. BMJ Open 2020;10:e039054. doi:
          10.1136/bmjopen-2020-039054,
        </span>
        <a
          class="underline"
          :style="`color: ${textColor}!important`"
          href="https://bmjopen.bmj.com/content/10/12/e039054"
          >https://bmjopen.bmj.com/content/10/12/e039054</a
        >. <br />
        <br />

        <span
          >2.Mirelman, et al. Executive Function and Falls in Older Adults: New
          Findings from a Five-Year Prospective Study Link Fall Risk to
          Cognition. (2012). PLoS One, Vol 7:6, e40297. DOI:
          10.1371/journal.pone.0040297,
          <a
            class="underline"
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
          >.
          <br />
        </span>
      </p>
      <p class="mt-4 mb-2 leading-3 text-sm md:text-base md:leading-4">
        <span
          >Nymbl’s technology is intended to improve balance, strength and
          coordination, and to help individuals who have experienced, or are at
          risk for, weakness or loss of balance or mobility. It is important to
          work with a medical professional if you notice that you are developing
          weakness, mobility or balance problems, as this can indicate a serious
          medical condition. Nymbl is not intended to be a substitute for
          professional medical advice, diagnosis or treatment. The information
          provided by Nymbl is for general purposes and is not intended to
          constitute medical advice. You are encouraged to confirm any
          information provided by Nymbl with other sources and your healthcare
          provider. If you think you may have a medical emergency during use of
          the program, call your doctor or emergency medical phone number
          immediately.
        </span>
      </p>
      <p class="mb-3 leading-3 text-sm md:text-base md:leading-4 break-words">
        <span
          >Nymbl Inc. and its subsidiaries comply with all applicable federal
          civil rights laws and do not discriminate or exclude people on the
          basis of race, color, national origin, ancestry, religion, sex,
          marital status, gender, gender identity, sexual orientation, age, or
          disability. We also provide free language interpreter services. See
          our full accessibility rights information and language options.
        </span>

        <a>full accessibility rights information and language options.</a>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    textColor: String,
  },
};
</script>
