import modal from "./modal";
import queries from "./queries";
import { useStore } from "@/store.js";

const mixed = {
  computed: {
    apiError() {
      const store = useStore();
      return store.apiError;
    },
  },
  mounted() {
    let input = document.querySelector("[autofocus]");
    if (input) {
      input.focus();
    }
  },
};

const mixins = [mixed, modal, queries];

export default mixins;
