<template>
  <section
    :style="`border-color: ${colors.howItWorksBorder}!important`"
    class="shadow transform translate-y-10 md:rounded-xl p-5 md:pt-10 md:pb-10 border-solid bt-2 border-t-2 md:border-2 md:mx-8 bg-white"
  >
    <div class="inner-container">
      <h2
        :style="`color: ${colors.howItWorksTitle}!important`"
        class="font-bold text-center md:text-lg md:leading-5"
      >
        {{ title }}
      </h2>
      <h3
        :class="companyName == 'Humana' ? 'font-bold' : ''"
        :style="`color: ${colors.howItWorksTitleSecond}!important`"
        class="md:text-xl md:leading-6 my-4 text-center"
      >
        {{ titleSecond }}
      </h3>
      <p class="text-center mb-4 md:text-lg lg:text-left md:leading-7">
        {{ desc }}
      </p>
      <div class="flex flex-col lg:flex-row">
        <div class="max-w-2xl">
          <h3 class="font-bold md:text-lg mb-4">Nymbl is a great choice if:</h3>
          <ul class="leading-5">
            <landing-list-item :company="companyName" :text="listText1" />
            <landing-list-item :company="companyName" :text="listText2" />
            <landing-list-item :company="companyName" :text="listText3" />
            <landing-list-item :company="companyName" :text="listText4" />
            <landing-list-item :company="companyName" :text="listText5" />
          </ul>
        </div>
        <div class="lg:ml-20 mt-4 lg:max-w-lg">
          <img
            class="m-auto"
            src="../../assets/img/FallsFree/phone-exercise-full.png"
          />
        </div>
      </div>
      <div
        v-if="!isEnrollmentDisabled"
        class="flex flex-col lg:flex-row justify-center mt-5 md:px-16"
      >
        <n-button
          v-if="companyName !== 'John Hancock' && companyName !== 'Humana'"
          block
          :style="`color: ${colors.ctaButton}!important;border-color: ${colors.ctaButton}!important`"
          rounded-xxl
          variant="transparent-teal"
          class="mt-3 md:mt-6"
          @click="$emit('ctaSpot')"
        >
          Reserve your spot now!
        </n-button>

        <n-button
          v-if="companyName === 'Humana'"
          block
          :style="`color: ${colors.ctaButton}!important;border-color: ${colors.ctaButton}!important`"
          rounded-xxl
          variant="transparent-teal"
          class="mt-3 md:mt-6"
          @click="$emit('ctaSpot')"
        >
          Improve my balance now
        </n-button>

        <n-button
          variant="teal"
          :block="companyName === 'John Hancock' ? false : true"
          :style="`background-color: ${colors.ctaButton}!important`"
          rounded-xxl
          class="mt-3 md:mt-6 lg:ml-6"
          @click="$emit('cta')"
        >
          Get started with Nymbl
        </n-button>
      </div>
    </div>
  </section>
</template>

<script>
import LandingListItem from "./LandingListItem.vue";
export default {
  components: {
    LandingListItem,
  },
  props: {
    companyName: String,
    colors: Object,
    isEnrollmentDisabled: Boolean,
  },
  computed: {
    title() {
      if (this.companyName === "John Hancock") {
        return "How Nymbl Works";
      }
      return "How it works";
    },
    titleSecond() {
      if (
        this.companyName === "John Hancock" ||
        this.companyName === "Blue MI"
      ) {
        return "A personalized app for your unique balance needs";
      }
      return "A personalized app for your unique balance needs";
    },
    desc() {
      if (this.companyName === "John Hancock") {
        return "Nymbl adapts to your unique balance needs in the comfort and safety of your home.";
      }
      return "You are always growing and changing. Nymbl adapts to your unique balance needs in the comfort and safety of your home.";
    },
    listText1() {
      if (
        this.companyName === "John Hancock" ||
        this.companyName === "Blue MI"
      ) {
        return "You want to improve your balance";
      }
      return "You want to improve your balance";
    },
    listText2() {
      if (
        this.companyName === "John Hancock" ||
        this.companyName === "Blue MI" ||
        this.companyName === "Humana"
      ) {
        return "You want to maintain your independent lifestyle";
      }
      return "You want to maintain your lifestyle or balance";
    },
    listText3() {
      return "You are worried about falling";
    },
    listText4() {
      if (
        this.companyName === "John Hancock" ||
        this.companyName === "Blue MI" ||
        this.companyName === "Humana"
      ) {
        return "You have experienced a fall";
      }
      return "You have previously fallen";
    },
    listText5() {
      if (
        this.companyName === "John Hancock" ||
        this.companyName === "Blue MI"
      ) {
        return "You are looking for an alternative to an in-person or online balance classes";
      }
      if (this.companyName === "Humana") {
        return "You are looking for an alternative to an in-person or online balance class";
      }
      return "You are looking for an alternative to in-person balance class";
    },
  },
};
</script>
