import { defineStore } from "pinia";

import getCompanyName from "@/views/FallsFree/getCompanyName";

export const languages = ["en", "es"];

export const spanishEnabledCompanies = [
  "DRCOG",
  "CarePlus",
  "Elevance",
  "fallsfree",
];

export const gtmEnabledCompanies = ["enroll"];

export const isGtmEnabled = () => {
  // Is Google Tag manager enabled.
  const host = window.location.host.split(".")[0].toLowerCase();
  console.log("Host: ", host);
  return gtmEnabledCompanies.includes(host);
};

export const isLanguageSwitcherEnabled = () => {
  const companyName = getCompanyName();
  return spanishEnabledCompanies.includes(companyName);
};

export const useStore = defineStore("main", {
  state: () => {
    return {
      defaults: [],
      anonymousDefaults: {},
      anonymousFeatureFlags: null,
      apiError: false,
      tokenEligibilityFlow: null,
      region: null,
      loading: false,
      soleraUser: null,
    };
  },
  actions: {
    setApiError(apiError) {
      console.log("Api Error: ", apiError);
      this.apiError = apiError;
      setTimeout(() => {
        this.apiError = false;
      }, 1000);
    },
    setLoading(loading) {
      this.loading = loading;
    },
  },
  getters: {
    isTestEnv: () => {
      if (process.env.VUE_APP_ENV === "production") {
        return false;
      }
      return true;
    },
    isGtmEnabled: () => {
      return isGtmEnabled();
    },
    apiToCountryMappings: (state) => {
      const mapCountriesToLowerCase = (apiToCountryMappings) => {
        Object.keys(apiToCountryMappings).forEach((key) => {
          apiToCountryMappings[key.toLowerCase()] = apiToCountryMappings[key];

          if (key.toLowerCase() !== key) {
            delete apiToCountryMappings[key];
          }
        });
        return apiToCountryMappings;
      };

      return Object.keys(state.anonymousDefaults).length > 0
        ? mapCountriesToLowerCase(state.anonymousDefaults.apiToCountryMappings)
        : false;
    },
  },
});
