<template>
  <div class="mb-6">
    <h3
      class="mb-3 font-bold leading-4"
      :class="small ? 'md:text-base md:leading-5' : 'md:text-lg md:leading-6'"
    >
      {{ question }}
    </h3>
    <p
      class="text-sm leading-4"
      :class="
        small
          ? 'md:text-base md:leading-5'
          : 'md:leading-5 lg:leading-6 lg:text-lg'
      "
    >
      <span v-if="answer"><span v-html="answer" /></span>
      <slot v-else />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    question: String,
    answer: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
