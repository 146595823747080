export default {
  methods: {
    updateQuery(query) {
      this.$nextTick(() => {
        this.$router.push({
          query: { ...this.$route.query, ...query },
        });
      });
    },
    removeQuery(...queriesToRemove) {
      const query = Object.assign({}, this.$route.query);

      queriesToRemove.forEach((queryToRemove) => {
        if (!query[queryToRemove]) {
          return;
        }
        delete query[queryToRemove];
      });

      this.$router.push({
        query: { ...query },
      });
    },
  },
};
