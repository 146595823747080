<template>
  <section class="px-3 md:px-6 pt-6 whitespace-pre-wrap">
    <div class="inner-container">
      <div class="font-bold mb-3">{{ $t("sources") }}</div>
      <p class="mb-2 leading-3 text-sm md:text-base md:leading-4 break-words">
        <span
          >{{
            $t(
              "DRCOG.1-papi-e-chiou-s-mcgregor-ah-feasibility-and-acceptability-study-on-the-use-of-a-smartphone-application-to-facilitate-balance-training-in-the-aging-population-bmj-open-2020-10-e039054-doi-10-1136-bmjopen-2020-039054",
            )
          }}
        </span>
        <a
          class="underline"
          :style="`color: ${textColor}!important`"
          href="https://bmjopen.bmj.com/content/10/12/e039054"
          >https://bmjopen.bmj.com/content/10/12/e039054</a
        >. <br />
        <br />

        <span
          >{{
            $t(
              "DRCOG.2-mirelman-et-al-executive-function-and-falls-in-older-adults-new-findings-from-a-five-year-prospective-study-link-fall-risk-to-cognition-2012-plos-one-vol-7-6-e40297-doi-10-1371-journal-pone-0040297",
            )
          }}
          <a
            class="underline"
            :style="`color: ${textColor}!important`"
            href="https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf"
            >https://nymblscience.com/wp-content/uploads/2020/08/journal.pone_.0040297.pdf</a
          >.
          <br />
        </span>
      </p>
      <p class="mt-4 mb-2 leading-3 text-sm md:text-base md:leading-4">
        <span
          >{{
            $t(
              "DRCOG.nymbls-technology-is-intended-to-improve-balance-strength-and-coordination-and-to-help-individuals-who-have-experienced-or-are-at-risk-for-weakness-or-loss-of-balance-or-mobility-it-is-important-to-work-with-a-medical-professional-if-you-notice-that-you-are-developing-weakness-mobility-or-balance-problems-as-this-can-indicate-a-serious-medical-condition-nymbl-is-not-intended-to-be-a-substitute-for-professional-medical-advice-diagnosis-or-treatment-the-information-provided-by-nymbl-is-for-general-purposes-and-is-not-intended-to-constitute-medical-advice-you-are-encouraged-to-confirm-any-information-provided-by-nymbl-with-other-sources-and-your-healthcare-provider-if-you-think-you-may-have-a-medical-emergency-during-use-of-the-program-call-your-doctor-or-emergency-medical-phone-number-immediately",
            )
          }}
        </span>
      </p>
      <p class="mb-3 leading-3 text-sm md:text-base md:leading-4 break-words">
        <span
          >{{
            $t(
              "DRCOG.drcog-inc-and-its-subsidiaries-comply-with-all-applicable-federal-civil-rights-laws-and-do-not-discriminate-or-exclude-people-on-the-basis-of-race-color-national-origin-ancestry-religion-sex-marital-status-gender-gender-identity-sexual-orientation-age-or-disability-we-also-provide-free-language-interpreter-services-see-our-full-accessibility-rights-information-and-language-options",
            )
          }}
        </span>

        <a>{{
          $t("DRCOG.full-accessibility-rights-information-and-language-options")
        }}</a>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    companyName: String,
    companyDisplayName: String,
    textColor: String,
  },
};
</script>
