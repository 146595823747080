<template>
  <section
    id="landing-how-careplus"
    :style="`border-color: ${colors.howItWorksBorder}!important`"
    class="transform translate-y-10 p-5 md:pt-10 md:pb-10 border-solid bt-4 border-t-4 md:border-4 md:mx-8 bg-white"
  >
    <div class="inner-container">
      <h2
        :style="`color: ${colors.title}!important`"
        class="font-bold leading-6 text-xl md:text-4xl md:leading- mb-8 md:mb-10"
      >
        {{ title }}
      </h2>

      <div class="flex flex-col lg:flex-row">
        <div>
          <p class="text-left mb-4 md:text-lg md:leading-7">
            {{ desc }}
          </p>
          <h3 class="font-bold md:text-lg mb-4">
            {{ $t("careplus.nymbl-is-a-great-choice-if") }}
          </h3>
          <ul class="leading-5">
            <landing-list-item :company="companyName" :text="listText1" />
            <landing-list-item :company="companyName" :text="listText2" />
            <landing-list-item :company="companyName" :text="listText3" />
            <landing-list-item :company="companyName" :text="listText4" />
            <landing-list-item :company="companyName" :text="listText5" />
          </ul>
        </div>
        <div class="lg:ml-14 mt-0">
          <img
            v-if="$i18n.locale === 'es'"
            style="width: 1200px"
            src="../../../assets/img/FallsFree/landing-how-spanish-careplus.png"
          />
          <img
            v-else
            style="width: 1200px"
            src="../../../assets/img/FallsFree/nymbl-app-how-it-works.png"
          />
        </div>
      </div>
      <div
        v-if="!isEnrollmentDisabled"
        class="flex flex-col lg:flex-row justify-center mt-5 md:px-16"
      >
        <n-button
          block
          :style="`color: ${colors.ctaButton}!important;border-color: ${colors.ctaButton}!important`"
          variant="transparent-teal"
          class="mt-3 md:mt-6"
          @click="$emit('ctaSpot')"
        >
          {{ $t("careplus.improve-my-balance-now") }}
        </n-button>

        <n-button
          variant="teal"
          block
          :style="`background-color: ${colors.ctaButton}!important`"
          class="mt-3 md:mt-6 lg:ml-6"
          @click="$emit('cta')"
        >
          {{ $t("careplus.get-started-with-nymbl") }}
        </n-button>
      </div>
    </div>
  </section>
</template>

<script>
import LandingListItem from "../LandingListItem.vue";
export default {
  components: {
    LandingListItem,
  },
  props: {
    companyName: String,
    colors: Object,
    isEnrollmentDisabled: Boolean,
  },
  computed: {
    title() {
      return this.$t(
        "careplus.a-personalized-app-for-your-unique-balance-needs"
      );
    },
    desc() {
      return this.$t(
        "careplus.whether-youre-seated-or-standing-nymbl-adapts-to-your-unique-balance-needs-while-having-fun-it-reconnects-your-brain-and-body-with-simple-exercises-and-brain-games-so-you-can-improve-your-balance-and-enjoy-more-of-life"
      );
    },
    listText1() {
      return this.$t("careplus.you-want-to-improve-your-balance");
    },
    listText2() {
      return this.$t(
        "careplus.you-want-to-maintain-your-independent-lifestyle"
      );
    },
    listText3() {
      return this.$t("careplus.you-are-worried-about-falling");
    },
    listText4() {
      return this.$t("careplus.you-have-experienced-a-fall");
    },
    listText5() {
      return this.$t(
        "careplus.you-are-looking-for-an-alternative-to-an-in-person-or-online-balance-class"
      );
    },
  },
};
</script>
<style>
#landing-how-careplus img {
  transform: translate(0, -8px);
}
</style>
