<template>
  <section v-if="isVideoEnabled" class="px-3 pb-4 mt-4 md:mt-20">
    <div class="inner-container">
      <div
        class="grid grid-cols-1 my-2 mt-4"
        :class="noMargins ? 'md:m-0 md:my-0' : 'md:mt-10 md:my-5 '"
      >
        <div class="w-full sm:mx-auto md:mx-auto select-none">
          <div class="lg:h-full min-h-full">
            <slot />
            <Headline class="text-center mt-4">
              {{ videoTitle }}
            </Headline>
            <landing-video
              event="welcome page embeded newsletter"
              autoplay
              :company-name="companyName"
              :video-id="embedVideoId"
              :video-key="embedVideoKey"
              @track-event="trackEvent"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import LandingVideo from "./LandingVideo.vue";
import { validVideoIds } from "./validVideos.js";
import videoTitles from "./videoTitles.js";
import Headline from "@/components/Headline.vue";

export default {
  components: {
    LandingVideo,
    Headline,
  },
  props: {
    companyName: String,
    noMargins: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["trackEvent"],
  computed: {
    query() {
      return this.$route.query;
    },

    embedVideoId() {
      if (!this.query) {
        return null;
      }
      if (this.query && this.query.embedVideo) {
        return this.query.embedVideo;
      }
      return null;
    },

    embedVideoKey() {
      if (!this.query) {
        return null;
      }
      if (this.query && this.query.embedVideoKey) {
        return this.query.embedVideoKey;
      }
      return null;
    },
    isVideoIdValid() {
      return this.embedVideoId && validVideoIds.includes(this.embedVideoId);
    },
    isVideoEnabled() {
      if (this.embedVideoId && this.isVideoIdValid) {
        return true;
      }
      return false;
    },
    videoTitle() {
      const title = videoTitles.find(
        (title) => title.videoId === this.embedVideoId,
      );
      return title ? title.videoTitle : null;
    },
  },
  watch: {
    isVideoIdValid: {
      immediate: true,
      handler(newValue) {
        if (!newValue && this.query && this.query.embedVideoId) {
          this.$nMessage({
            type: "error",
            message: "Video ID not valid",
          });
        }
      },
    },
  },
  mounted() {
    this.$emit("trackEvent", "Viewed embedded video", {
      videoId: this.embedVideoId,
      videoKey: this.embedVideoKey,
      videoTitle: this.videoTitle,
    });
  },
  methods: {
    trackEvent(event, props) {
      this.$emit("trackEvent", event, props);
    },
  },
};
</script>
