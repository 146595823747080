const showChatWidget = () => {
  if (window.zE) {
    window.zE("messenger:set", "zIndex", 150);
  }
};

const hideChatWidget = () => {
  if (window.zE) {
    window.zE("messenger:set", "zIndex", -999);
  }
};

const removeSplashScreen = () => {
  // Remove Nymbl splash screen when vue is loaded.
  const splashScreen = document.getElementById("splash-screen");

  if (document.contains(splashScreen)) {
    splashScreen.classList.add("fade-out");
    setTimeout(() => {
      splashScreen.remove();
    }, 600);
  }

  setTimeout(() => {
    if (document.contains(document.getElementById("splash-screen-style"))) {
      document.getElementById("splash-screen-style").remove();
    }
  }, 4000);
};

const goToDeepLink = () => {
  let url = `https://${
    process.env.VUE_APP_ENV === "production" ? "my" : "test"
  }.nymblscience.com/InApp`;
  window.location.href = url;
};

export { showChatWidget, hideChatWidget, removeSplashScreen, goToDeepLink };
