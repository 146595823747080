<template>
  <section
    :style="{ background: colors.bgLight, borderColor: colors.green }"
    class="text-center my-2 pb-12 rounded-xl mx-0 md:mx-10 border-2 mt-3 relative z-50"
  >
    <h2
      class="pt-6 mb-4 mt-4 font-bold leading-6 md:leading-8 md:pt-10 text-lg px-2"
    >
      {{ title }}
    </h2>

    <div
      :style="`color: ${colors.text}!important; max-width: 1300px; margin: 0 auto;`"
      class="lg:grid lg:grid-cols-3 lg:gap-8 lg:pt-10 lg:mt-2"
    >
      <component
        :is="getComponentName()"
        :colors="colors"
        class="md:mb-12 md:mt-12 lg:m-0"
        :img="getReviewImage(0)"
        name="Brenda W."
        :review="
          $t(
            'DRCOG.before-nymbl-my-body-was-stiff-nymbl-helps-me-move-and-makes-my-body-feel-better',
          )
        "
      />
      <component
        :is="getComponentName()"
        :colors="colors"
        class="md:mb-12 lg:m-0"
        :img="getReviewImage(1)"
        name="Hunter L."
        :review="
          $t(
            'DRCOG.i-am-a-new-man-after-two-weeks-of-using-nymbl-im-having-fun-again',
          )
        "
      />
      <component
        :is="getComponentName()"
        :colors="colors"
        class="md:mb-2 lg:m-0"
        :img="getReviewImage(2)"
        name="Dianne E. "
        :review="
          $t(
            'DRCOG.nymbl-is-a-fun-experience-i-never-thought-playing-simon-would-improve-my-balance',
          )
        "
      />
    </div>
  </section>
</template>

<script>
import LandingReviewDRCOG from "./LandingReviewDRCOG.vue";

export default {
  components: {
    LandingReviewDRCOG,
  },
  props: {
    colors: Object,
    companyName: String,
  },
  computed: {
    title() {
      return this.$t("DRCOG.see-why-people-like-you-love-nymbl");
    },
    desc() {
      return this.$t(
        "DRCOG.happy-nymbl-users-across-the-world-are-improving-their-balance-and-reducing-their-fall-risk-in-the-comfort-and-safety-of-their-homes",
      );
    },
    descSecondLine() {
      return this.$t("DRCOG.nymbl-can-help-you-live-your-best-life");
    },
  },
  methods: {
    getComponentName() {
      return "LandingReviewDRCOG";
    },
    getReviewImage(index) {
      if (index === 0) {
        return "Nymbl-testimonial-CarePlus-1.jpg";
      }
      if (index === 1) {
        return "Nymbl-testimonial-CarePlus-2.jpg";
      }
      if (index === 2) {
        return "Nymbl-testimonial-CarePlus-3.jpg";
      }
    },
  },
};
</script>
