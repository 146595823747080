<template>
  <div
    class="w-full shadow rounded text-center text-white px-1 md:px-8 mt-12 py-6 md:mt-0 mx-auto"
    style="max-width: 500px; margin: 0 auto"
    :style="{
      maxWidth: '500px',
      background: colors.green,
    }"
  >
    <div class="">
      <p class="leading-5 mt-3 mb-2">“{{ review }}”</p>
      <h3 class="font-bold text-sm leading-6">
        {{ name }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    review: String,
    colors: Object,
  },
};
</script>
