<template>
  <div>
    <div v-if="!loading && user">
      <div class="font-bold mt-6">{{ user.firstName }} {{ user.lastName }}</div>
      <div class="text-base">
        <div class="text-gray-light text-sm font-medium mt-2">
          {{ $t("eligibility.date-of-birth") }}
        </div>
        {{ formatDate(user.dateOfBirth) }}
      </div>
      <div class="text-base mt-2 mb-4">
        <div class="text-gray-light text-sm font-medium">
          {{ $t("eligibility.email") }}
        </div>

        <div v-if="isEmailEditActive && !isEmailInUse">
          <n-textbox
            v-model="state.emailEdit"
            border
            autofocus
            input-classes="placeholder:italic text-xs py-0 h-8 border-gray-light"
            :invalid="state.emailEdit && isInvalid('emailEdit', v$)"
            :error="state.emailEdit && getError('emailEdit', v$)"
            :placeholder="$t('eligibility.e-g-name-domain-net')"
            container-classes="text-sm"
            feedback-classes="text-xs text-red"
            name="Email"
            autocorrect="off"
            autocapitalize="off"
            @change="v$.emailEdit.$touch"
          />
          <ButtonFull
            class="font-bold mr-2"
            :disabled="isInvalid('emailEdit', v$) || !state.emailEdit"
            @click="updateEmail"
          >
            {{ $t("eligibility.update") }}
          </ButtonFull>
          <ButtonTransparent class="font-bold" @click="cancelEmailEdit">
            {{ $t("eligibility.cancel") }}
          </ButtonTransparent>
        </div>
        <div v-else>
          {{ state.email }}
          <Link v-if="!isEmailInUse" class="mt-1 text-sm" @click="editEmail">
            {{ $t("eligibility.not-your-preferred-email") }}
          </Link>
        </div>
        <div v-if="isEmailInUse">
          <Paragraph class="font-medium mb-6 mt-2">
            {{ $t("eligibility.this-email-is-already-in-use") }}
          </Paragraph>

          <Paragraph class="mb-3">
            {{ $t("eligibility.hi-user-firstname", [user.firstName]) }}
          </Paragraph>
          <Paragraph class="mb-3">
            {{
              $t("eligibility.state-email-is-already-in-our-system", [
                state.email,
              ])
            }}
          </Paragraph>
          <Paragraph class="mb-3">
            {{
              $t(
                "eligibility.this-usually-happens-when-a-spouse-or-other-family-member-shares-an-email-address",
              )
            }}
          </Paragraph>
          <Paragraph class="mb-8">
            {{
              $t(
                "eligibility.if-youd-like-to-enroll-with-a-different-email-address-click-the-button-below",
              )
            }}
          </Paragraph>

          <ButtonFull
            v-if="isEmailInUse"
            class="font-bold"
            @click="enrollDifferentEmail"
          >
            {{ $t("eligibility.enroll-with-different-email") }}
          </ButtonFull>
        </div>
      </div>

      <div v-if="!isEmailInUse" class="text-base font-bold mt-2">
        <n-radio-group
          v-model="hasSmartDevice"
          :label="
            $t('eligibility.i-have-a-phone-or-tablet-connected-to-the-internet')
          "
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]"
        />
      </div>

      <ButtonFull
        v-if="!isEmailInUse"
        :disabled="hasSmartDevice === null || isEmailEditActive"
        class="font-bold mt-4 mb-6"
        @click="verify(user)"
      >
        {{ $t("eligibility.get-nymbl") }}
      </ButtonFull>

      <EligibilityNymblCommitment
        class="mb-6"
        style="margin-left: 0 !important"
      />

      <EligibilityTermsAndPrivacy
        company-name="Blue Shield of California"
        button-label="Get Nymbl"
        class="mb-0 pb-0"
      />

      <Paragraph class="text-xs mb-0 mt-0 leading-4">
        <div class="font-bold text-xs">{{ $t("eligibility.need-help") }}</div>
        {{ $t("eligibility.contact-our-friendly-support-team-at") }}
        <Link href="mailto:support@nymblscience.com">
          support@nymblscience.com
        </Link>
      </Paragraph>
    </div>
    <div
      v-else
      class="flex justify-center items-center"
      style="height: 240px !important"
    >
      <n-loading-circle :size="64" :stroke="2" />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, reactive } from "vue";
import EligibilityTermsAndPrivacy from "@/views/Eligibility/EligibilityFlow/EligibilityTermsAndPrivacyText.vue";
import EligibilityNymblCommitment from "@/views/Eligibility/EligibilityFlow/EligibilityNymblCommitment.vue";
import ButtonFull from "@/components/ButtonFull";
import ButtonTransparent from "@/components/ButtonTransparent";
import Link from "@/components/Link";
import Paragraph from "@/components/Paragraph.vue";
import { useValidation, getError, isInvalid } from "nymbl-ui/validation";
import { required, email, helpers } from "@vuelidate/validators";
import { trackEvent } from "@/common/events";
import { useGtm } from "@gtm-support/vue-gtm";

import api from "@/api";
import moment from "moment-mini";

import { updateState } from "@/flow-state.js";

import { useRoute, useRouter } from "vue-router";
import { useStore } from "@/store.js";

import { useI18n } from "vue-i18n";

const { t } = useI18n();
const gtm = useGtm();

const route = useRoute();
const router = useRouter();
const store = useStore();

const loading = ref(false);
const user = ref(null);

const hasSmartDevice = ref(null);

const state = reactive({
  email: null,
  emailEdit: null,
});

const validationRules = computed(() => {
  return {
    emailEdit: {
      email: helpers.withMessage(
        t("eligibility.the-email-field-must-be-a-valid-email"),
        email,
      ),
      required: helpers.withMessage(
        t("eligibility.the-email-field-is-required"),
        required,
      ),
    },
  };
});

const v$ = useValidation(validationRules, state, { $lazy: false });

const isEmailInUse = ref(false);

const isEmailEditActive = ref(false);

const editEmail = () => {
  isEmailEditActive.value = true;
  state.emailEdit = state.email;
};
const updateEmail = () => {
  state.email = state.emailEdit;
  isEmailEditActive.value = false;
};

const cancelEmailEdit = () => {
  isEmailEditActive.value = false;
};

const enrollDifferentEmail = () => {
  isEmailInUse.value = false;
  nextTick(() => {
    isEmailEditActive.value = true;
  });
};

const lookupKey = computed(() => route.query.lookupKey);

const formatDate = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

const getUser = () => {
  loading.value = true;
  const query = {
    companyName: "Blue Shield of California",
    referenceId: lookupKey.value,
  };
  api
    .post("/EligibilityFlow/EligibilityCheck/Reference", {}, query, true)
    .then(({ data }) => {
      user.value = data;
      isEmailInUse.value = data.emailInUse;
      state.email = data.email;
    })
    .catch((err) => {
      router.push({ name: "EligibilitySoleraError" });
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};

const verify = (user) => {
  store.setLoading(true);

  // Save user state for email already in use error state
  store.soleraUser = user;
  store.soleraUser.email = state.email;

  const data = {
    firstName: user.firstName,
    lastName: user.lastName,
    dateOfBirth: user.dateOfBirth,
    genderOption: user.genderOption,
    email: state.email,
    phoneNumber: user.mobilePhone,
    referralSource: "Wellvolution",
    hasSmartDevice: true,
    enrollmentSource: "Web",
    companyName: "Blue Shield of California",
    zipCode: user.zipCode,
    ethnicity: user.ethnicity,
  };

  const company = "Blue Shield of California";
  data.companyName = company;

  updateState("selectedCompany", company);
  updateState("selectedCompanyData", data);

  nextTick(() => {
    router.push({
      name: "EligibilityForm",
      query: {
        interestCategory: 3,
        company: "BlueShieldofCalifornia",
        lookupKey: route.query.lookupKey,
        firstRedirect: true,
      },
    });
  });
};

onMounted(() => {
  // Add temporary classes to complement Tailwind CSS
  const root = document.getElementsByTagName("html")[0];
  const body = document.body;
  root.classList.add("new-ui");
  body.classList.add("new-ui");

  // If coming from already email in use
  if (route.query.isEmailInUse) {
    isEmailInUse.value = true;
    user.value = store.soleraUser;
    state.email = store.soleraUser.email;
  } else {
    getUser();
  }

  trackEvent(
    {
      event: "Viewed the enrollment form page",
      name: "solera error",
    },
    gtm,
  );

  store.setLoading(false);
});
</script>
<style lang="scss">
.eligibility-solera {
  .n-textbox__input {
    border-width: 1px !important;
    border-color: #828282 !important;
    border-radius: 4px !important;
    font-size: 0.85rem !important;
  }
}
.eligibility-solera .n-radio__label-text {
  font-weight: 400;
  font-size: 0.9rem;
}
</style>
