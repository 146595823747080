<template>
  <section
    :style="`background-color: ${colors.faqBg}!important`"
    class="text-white pt-8 md:pt-12 pb-5"
  >
    <div class="inner-container max-w-3xl px-3 md:px-8 text-base">
      <h2
        :class="companyName == 'Humana' ? 'lg:text-xl' : ''"
        class="text-center mb-6 font-bold md:text-lg leading-5 mt-5"
      >
        {{ $t("DRCOG.frequently-asked-questions") }}
      </h2>
      <landing-faq-item
        small
        :question="$t('DRCOG.what-is-nymbl')"
        :answer="faqAnswer1"
      />
      <landing-faq-item
        small
        :question="$t('DRCOG.who-is-nymbl-for')"
        :answer="faqAnswer2"
      />

      <landing-faq-item
        small
        :question="
          $t(
            'DRCOG.what-cities-and-counties-in-colorado-are-considered-the-greater-denver-area',
          )
        "
      >
        <p>
          {{
            $t(
              "DRCOG.drcogs-greater-denver-area-includes-the-following-locations-any-of-the-zip-codes-within-these-cities-and-counties-are-eligible-for-nymbl-for-free",
            )
          }}
        </p>
        <ul class="list-disc pl-10 mt-4 mb-4">
          <li class="mb-1">{{ $t("DRCOG.adams-county") }}</li>
          <li class="mb-1">{{ $t("DRCOG.arapahoe-county") }}</li>
          <li class="mb-1">{{ $t("DRCOG.clear-creek-county") }}</li>
          <li class="mb-1">{{ $t("DRCOG.douglas-county") }}</li>
          <li class="mb-1">{{ $t("DRCOG.gilpin-county") }}</li>
          <li class="mb-1">{{ $t("DRCOG.jefferson-county") }}</li>
          <li class="mb-1">{{ $t("DRCOG.city-and-county-of-denver") }}</li>
          <li>{{ $t("DRCOG.city-and-county-of-broomfield") }}</li>
        </ul>
      </landing-faq-item>
      <landing-faq-item
        small
        :question="$t('DRCOG.what-if-i-dont-live-in-the-greater-denver-area')"
      >
        {{
          $t(
            "DRCOG.you-can-check-your-eligibility-with-your-medicare-advantage-plans-at",
          )
        }}
        <a href="https://fallsfree.com" class="underline">fallsfree.com</a>
      </landing-faq-item>
      <landing-faq-item
        small
        :question="$t('DRCOG.how-does-nymbl-work')"
        :answer="faqAnswer4"
      />
      <landing-faq-item
        small
        :question="$t('DRCOG.why-am-i-being-offered-balance-training')"
        :answer="faqAnswer5"
      />
      <landing-faq-item
        small
        :question="
          $t('DRCOG.what-kind-of-exercises-will-i-be-doing-with-nymbl')
        "
        :answer="faqAnswer6"
      />
      <landing-faq-item
        small
        :question="
          $t('DRCOG.what-special-equipment-do-i-need-for-balance-training')
        "
        :answer="faqAnswer7"
      />
      <landing-faq-item
        small
        :question="$t('DRCOG.what-if-i-dont-have-a-phone-or-tablet')"
        :answer="faqAnswer8"
      />

      <landing-faq-item
        small
        :question="$t('DRCOG.what-will-nymbl-do-with-my-data')"
      >
        <p class="mb-6">
          {{
            $t(
              "DRCOG.nymbl-collects-your-personal-information-and-engagement-data-within-the-nymbl-app-to-improve-the-product-track-progress-and-enhance-your-member-experience",
            )
          }}
        </p>
        <p>
          {{
            $t(
              "DRCOG.nymbls-commitment-to-you-we-promise-to-never-sell-your-information-or-spam-you-we-value-you-and-your-privacy-our-goal-is-to-help-you-improve-your-balance-and-mobility-so-we-take-the-responsibility-of-protecting-your-data-seriously-your-information-is-safe-and-secure-with-us",
            )
          }}
        </p>
      </landing-faq-item>

      <div v-if="!isEnrollmentDisabled" class="text-center">
        <n-button
          rounded-xxl
          variant="white"
          :style="{
            background: `${colors.ctaButtonSecondary}!important`,
            color: '#fff!important',
          }"
          class="mt-2 mb-4 md:mb-8"
          @click="$emit('cta')"
        >
          {{ $t("DRCOG.get-the-free-nymbl-app") }}
        </n-button>
      </div>
    </div>
  </section>
</template>

<script>
import LandingFaqItem from "../LandingFaqItem.vue";
export default {
  components: {
    LandingFaqItem,
  },
  props: {
    companyName: String,
    companyDisplayName: String,
    isEnrollmentDisabled: Boolean,
    colors: Object,
  },
  computed: {
    faqAnswer1() {
      // What is Nymbl?
      return this.$t(
        "DRCOG.nymbl-is-an-easy-fun-and-personalized-balance-training-app-that-you-can-do-in-the-privacy-and-comfort-of-your-home-in-just-10-minutes-a-day-all-you-need-is-a-phone-or-tablet-dont-have-a-phone-or-tablet-enroll-in-nymbl-with-your-email-address-and-gain-access-to-nymbls-weekly-educational-email-program-its-free-to-you-through-nymbl-and-drcog",
      );
    },
    faqAnswer2() {
      // Who is Nymbl for?
      return this.$t(
        "DRCOG.nymbl-is-available-to-adults-in-the-greater-denver-area-ages-60-and-up-its-a-great-choice-for-you-if-youre-looking-to-improve-or-maintain-your-balance-and-stay-active",
      );
    },
    faqAnswer4() {
      // How does Nymbl work?
      return this.$t(
        "DRCOG.research-shows-that-falls-happen-when-we-are-distracted-not-when-we-are-focused-on-our-balance-nymbl-helps-prepare-you-for-these-real-life-experiences-and-distractions-by-giving-you-a-simple-balance-exercise-to-do-at-the-same-time-as-you-play-a-fun-brain-game-on-your-device-challenging-your-brain-and-body-to-work-together-is-what-helps-improve-your-balance-reflex-nymbls-scientifically-proven-app-can-improve-balance-by-30-nymbls-scientifically-proven-app-improves-your-balance-steadiness-in-walking-and-confidence-in-fact-7-out-of-10-nymbl-members-report-improved-confidence-in-their-walking-and-balance-since-using-nymbl-and-9-out-of-10-nymbl-members-report-they-have-maintained-or-improved-their-engagement-with-activities-they-enjoy-since-using-nymbl-with-nymbl-you-can-keep-moving-and-participating-in-activities-you-enjoy",
      );
    },
    faqAnswer5() {
      // Why am I being offered balance training?
      return this.$t(
        "DRCOG.here-in-colorado-were-always-looking-for-opportunities-to-help-you-on-your-journey-to-achieve-your-best-health-thats-why-nymbl-and-drcog-teamed-up-to-make-nymbl-free-to-you-our-goal-is-to-help-you-live-your-best-life-free-of-falls",
      );
    },
    faqAnswer6() {
      // What kind of exercises will I be doing with Nymbl?
      return this.$t(
        "DRCOG.nymbl-is-enjoyable-and-doesnt-require-strenuous-activity-it-involves-simple-body-exercises-such-as-marching-in-place-and-shifting-your-weight-to-each-leg-for-those-who-are-seated-nymbl-offers-exercises-to-best-suit-your-needs-these-simple-body-exercises-are-done-in-combination-with-fun-brain-games-like-trivia",
      );
    },
    faqAnswer7() {
      // What special equipment do I need for balance training?
      return this.$t(
        "DRCOG.to-do-nymbls-balance-training-app-all-you-need-is-a-phone-or-tablet-no-weights-or-other-exercise-equipment-is-needed",
      );
    },
    faqAnswer8() {
      // What if I don’t have a phone or tablet?
      return this.$t(
        "DRCOG.no-problem-enroll-in-nymbl-with-your-email-address-and-gain-access-to-nymbls-weekly-educational-email-program-it-covers-topics-ranging-from-trip-hazards-to-dizziness-to-how-to-make-the-most-out-of-your-doctor-visits-its-free-to-you-through-nymbl-and-drcog",
      );
    },
  },
};
</script>
