import { apiUrl } from "@/common/apiUrl";
import { createApp } from "vue";
import { createHead } from "@vueuse/head";

import { createGtm } from "@gtm-support/vue-gtm";
import VueSmoothScroll from "vue3-smooth-scroll";
import { isGtmEnabled } from "./store";
import App from "./App.vue";
import router from "./router";

import { createPinia } from "pinia";

import Loading from "./directives/loading";
import EligibilityHeader from "@/views/Eligibility/EligibilityHeader.vue";

import { i18n } from "@/intl/useI18n";

const app = createApp(App);
const head = createHead();

app.use(i18n);

app.use(head);

app.use(router);
app.use(createPinia());

// Import Nymbl Vue UI kit from organization GitHub npm package.
import NymblUI from "nymbl-ui";
const { Components, Plugins } = NymblUI;
import "nymbl-ui/dist/nymbl-ui.css";

Object.keys(Components).forEach((name) => {
  app.component(name, Components[name]);
});

app.use(Plugins.nconfirm);
app.use(Plugins.nmessage);

app.use(Loading);

// Import Tailwind
import "@/assets/tailwind.css";

// Import Styles
import "./assets/sass/main.scss";

import mixins from "./mixins";
import "./assets/tailwind.css";

mixins.forEach((mixin) => {
  app.mixin(mixin);
});

app.component("EligibilityHeader", EligibilityHeader);

// Get active URL/ For secondary API support
let url = apiUrl;

import axios from "axios";
import VueAxios from "vue-axios";

// Google Tag Manager set-up
if (isGtmEnabled) {
  const isProduction = process.env.VUE_APP_ENV === "production";

  let gtmId = isProduction ? "GTM-KL2M38Z" : null;

  if (gtmId) {
    app.use(
      createGtm({
        id: gtmId,
        vueRouter: router,
      })
    );
  }
}

app.use(VueAxios, axios);

app.use(VueSmoothScroll);

axios.defaults.baseURL = url;

router.isReady().then(() => {
  app.mount("#app");
});
