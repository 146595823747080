<template>
  <legal-container :url="url" title="Nymbl Science Privacy Policy" />
</template>

<script setup>
import LegalContainer from "./LegalContainer.vue";
import { computed } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { useI18n } from "vue-i18n";

const { locale } = useI18n();

const props = defineProps({
  companyName: String,
});

const emit = defineEmits(["close"]);

const url = computed(() => {
  const url =
    locale.value === "es"
      ? "https://nymblscience.com/privacy-policy-es/"
      : "https://nymblscience.com/privacy-policy/";

  if (props.companyName === "ACC") {
    return url + "#nz-users";
  }
  return url;
});

onBeforeRouteLeave((to, from, next) => {
  emit("close");
  next();
});
</script>
