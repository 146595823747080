<template>
  <section
    :style="`background-color: ${colors.reviewsBg}!important`"
    class="text-white text-center my-2 pb-12"
    :class="
      companyName === 'Humana' || companyName === 'CarePlus'
        ? 'rounded-none'
        : 'rounded-xl'
    "
  >
    <div class="inner-container">
      <h2
        class="pt-6 mb-4 font-bold leading-6 md:leading-8 md:pt-10 px-2"
        :class="
          companyName === 'Humana' || companyName === 'CarePlus'
            ? 'text-xl md:text-4xl'
            : 'text-lg'
        "
      >
        {{ title }}
      </h2>
      <p
        class="leading-4 px-2 mb-4 md:leading-6 max-w-2xl mx-auto text-center"
        :class="
          companyName === 'Humana' || companyName === 'CarePlus'
            ? 'text-base md:text-lg'
            : 'md:text-base lg:px-20'
        "
      >
        {{ desc
        }}<span v-if="companyName !== 'Humana' && companyName !== 'CarePlus'"
          ><br />
          {{ descSecondLine }}</span
        >
      </p>
      <div
        v-if="companyName === 'CarePlus'"
        class="mt-12 mb-12 lg:mt-10 lg:mb-24"
      />

      <div
        :style="`color: ${colors.text}!important`"
        class="md:px-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:pt-16 lg:mt-2"
      >
        <component
          :is="getComponentName()"
          :colors="colors"
          class="md:mb-12 md:mt-12 lg:m-0"
          :img="getReviewImage(0)"
          name="Brenda W."
          review="Before Nymbl, my body was stiff. Nymbl helps me move and makes my body feel better."
        />
        <component
          :is="getComponentName()"
          :colors="colors"
          class="md:mb-12 lg:m-0"
          :img="getReviewImage(1)"
          name="Hunter L."
          review="I am a new man after two weeks of using Nymbl. I’m having fun again."
        />
        <component
          :is="getComponentName()"
          :colors="colors"
          class="md:mb-2 lg:m-0"
          :img="getReviewImage(2)"
          name="Dianne E. "
          review="Nymbl is a fun experience. I never thought playing Simon would improve my balance."
        />
      </div>
    </div>
  </section>
</template>

<script>
import LandingReview from "./LandingReview.vue";
import LandingReviewDRCOG from "./DRCOG/LandingReviewDRCOG.vue";
import LandingReviewHumana from "./Humana/LandingReviewHumana.vue";
import LandingReviewCarePlus from "./CarePlus/LandingReviewCarePlus.vue";

export default {
  components: {
    LandingReview,
    LandingReviewDRCOG,
    LandingReviewHumana,
    LandingReviewCarePlus,
  },
  props: {
    colors: Object,
    companyName: String,
  },
  computed: {
    title() {
      if (this.companyName === "John Hancock") {
        return "Get moving with Nymbl to help improve your balance";
      }
      return "Improve your balance and get moving with Nymbl";
    },
    desc() {
      if (this.companyName === "John Hancock") {
        return "Nymbl users around the world are reducing their risk of falls and improving their balance from the comfort and safety of their homes.";
      }
      if (this.companyName === "Humana" || this.companyName === "CarePlus") {
        return "Join happy Nymbl members across the world who are improving their balance, and reducing their fall risk, in the comfort and safety of their homes. Nymbl can help you live your best life.";
      }
      return "Happy Nymbl users across the world are improving their balance, and reducing their fall risk, in the comfort and safety of their homes.";
    },
    descSecondLine() {
      if (this.companyName === "John Hancock") {
        return "Nymbl can help you live your best life.*";
      }
      return "Nymbl can help you live your best life.";
    },
  },
  methods: {
    getComponentName() {
      if (this.companyName === "Humana") {
        return "LandingReviewHumana";
      }
      if (this.companyName === "DRCOG") {
        return "LandingReviewDRCOG";
      }
      if (this.companyName === "CarePlus") {
        return "LandingReviewCarePlus";
      }
      return "LandingReview";
    },
    getReviewImage(index) {
      if (index === 0) {
        if (this.companyName === "CarePlus") {
          return "Nymbl-testimonial-CarePlus-1.jpg";
        }
        return "Nymbl-testimonial-photo-couple.png";
      }
      if (index === 1) {
        if (this.companyName === "CarePlus") {
          return "Nymbl-testimonial-CarePlus-2.jpg";
        }
        return "Nymbl-testimonial-photo-man.png";
      }
      if (index === 2) {
        if (this.companyName === "CarePlus") {
          return "Nymbl-testimonial-CarePlus-3.jpg";
        }
        return "Nymbl-testimonial-photo-user.png";
      }
    },
  },
};
</script>
