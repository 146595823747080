<template>
  <section
    :style="`background-color: ${colors.reviewsBg}!important`"
    class="text-white text-center my-2 pb-12 rounded-none"
  >
    <div class="inner-container">
      <h2
        class="pt-6 mb-4 font-bold leading-6 md:leading-8 md:pt-10 px-2 text-xl md:text-4xl"
      >
        {{ title }}
      </h2>
      <p
        class="leading-4 px-2 mb-4 md:leading-6 max-w-2xl mx-auto text-center text-base md:text-lg"
      >
        {{ desc
        }}<span
          ><br />
          {{ descSecondLine }}</span
        >
      </p>
      <div class="mt-12 mb-12 lg:mt-10 lg:mb-24"></div>

      <div
        :style="`color: ${colors.text}!important`"
        class="md:px-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:pt-16 lg:mt-2"
      >
        <component
          :is="getComponentName()"
          :colors="colors"
          class="md:mb-12 md:mt-12 lg:m-0"
          :img="getReviewImage(0)"
          name="Brenda W."
          :review="$t('careplus.review-1')"
        />
        <component
          :is="getComponentName()"
          :colors="colors"
          class="md:mb-12 lg:m-0"
          :img="getReviewImage(1)"
          name="Hunter L."
          :review="$t('careplus.review-2')"
        />
        <component
          :is="getComponentName()"
          :colors="colors"
          class="md:mb-2 lg:m-0"
          :img="getReviewImage(2)"
          :review="$t('careplus.review-3')"
          name="Dianne E. "
        />
      </div>
    </div>
  </section>
</template>

<script>
import LandingReviewCarePlus from "./LandingReviewCarePlus.vue";

export default {
  components: {
    LandingReviewCarePlus,
  },
  props: {
    colors: Object,
    companyName: String,
  },
  computed: {
    title() {
      return this.$t("careplus.improve-your-balance-and-get-moving-with-nymbl");
    },
    desc() {
      return this.$t(
        "careplus.join-happy-nymbl-members-across-the-world-who-are-improving-their-balance-and-reducing-their-fall-risk-in-the-comfort-and-safety-of-their-homes-nymbl-can-help-you-live-your-best-life"
      );
    },
    descSecondLine() {
      return this.$t("DRCOG.nymbl-can-help-you-live-your-best-life");
    },
  },
  methods: {
    getComponentName() {
      return "LandingReviewCarePlus";
    },
    getReviewImage(index) {
      if (index === 0) {
        return "Nymbl-testimonial-CarePlus-1.jpg";
      }
      if (index === 1) {
        return "Nymbl-testimonial-CarePlus-2.jpg";
      }
      if (index === 2) {
        return "Nymbl-testimonial-CarePlus-3.jpg";
      }
    },
  },
};
</script>
