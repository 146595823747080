<template>
  <n-form-item>
    <p class="font-normal text-xxs sm:text-xs mb-2">
      {{
        $t("eligibility.by-clicking-buttonlabel-you-agree-to-our", [
          buttonLabel,
        ])
      }}
      <Link @click.prevent="openTerms">
        {{ $t("eligibility.terms-and-conditions") }}
      </Link>
      and
      <Link @click.prevent="openPrivacy">
        {{ $t("eligibility.privacy-policy") }} </Link
      >.
    </p>
    <n-modal ref="terms" title="" custom-class="legal-modal">
      <div style="min-height: 70vh" class="mx-auto px-1 sm:px-5 relative">
        <terms class="top-0 bottom-0 right-0 left-0 terms z-20 absolute" />
      </div>
      <template #footer>
        <n-button v-if="acceptButton" size="sm" @click="closeTerms">
          {{ $t("eligibility.accept") }}
        </n-button>
      </template>
    </n-modal>

    <n-modal ref="privacy" title="" custom-class="legal-modal">
      <div style="min-height: 70vh" class="mx-auto px-1 sm:px-5 relative">
        <privacy
          :company-name="companyName"
          class="top-0 bottom-0 right-0 left-0 privacy z-20 absolute"
        />
      </div>
      <template #footer>
        <n-button v-if="acceptButton" size="sm" @click="closePrivacy">
          {{ $t("eligibility.accept") }}
        </n-button>
      </template>
    </n-modal>
  </n-form-item>
</template>

<script>
import Privacy from "../../Legal/Privacy.vue";
import Terms from "../../Legal/Terms.vue";
import { useStore } from "@/store.js";
import Link from "@/components/Link";
export default {
  components: {
    Privacy,
    Terms,
    Link,
  },
  props: {
    companyName: String,
    acceptButton: {
      type: Boolean,
      default: false,
    },
    buttonLabel: {
      type: String,
      default: "Verify Me",
    },
  },
  setup() {
    const store = useStore();
    return { store };
  },
  methods: {
    openTerms() {
      this.$emit(
        "trackEvent",
        "Viewed the Terms And Conditions modal in the Entrollment flow",
      );

      this.$refs.terms.open();
    },
    closeTerms() {
      this.$refs.terms.close();
    },
    openPrivacy() {
      this.$emit(
        "trackEvent",
        "Viewed the Privacy modal in the Entrollment flow",
      );

      this.$refs.privacy.open();
    },
    closePrivacy() {
      this.$refs.privacy.close();
    },
  },
};
</script>
<style lang="scss">
.legal-modal {
  .n-modal-header {
    height: 2rem !important;
  }

  @media (max-width: 600px) {
    .n-modal-container {
      padding: 0 !important;
    }

    .n-modal-body {
      padding: 0 !important;
    }
  }
}

.terms,
.privacy {
  max-height: 20rem;

  @media (min-width: 600px) {
    max-height: 30rem;
  }
}
</style>
