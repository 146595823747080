<template>
  <div style="max-width: 1200px; margin: 0 auto" class="mt-8 md:mt-16 mb-10">
    <h2
      v-if="titleEnabled"
      class="md:text-lg text-center mv-6 md:mb-10 font-bold"
    >
      {{ $t("DRCOG.community-partners") }}
    </h2>
    <carousel
      :items-to-show="3"
      :items-to-scroll="3"
      :autoplay="5000"
      :wrap-around="true"
      :mouse-drag="false"
    >
      <slide v-for="partner in partners" :key="partner.logoFileName">
        <div class="px-4 md:px-14 flex justify-center">
          <a
            :href="partner.websiteUrl"
            target="_blank"
            rel="noopener"
            class="cursor-pointer"
            @click="handlePartnerClick(partner.name, partner.websiteUrl)"
          >
            <img
              :src="getImgUrl(partner.logoFileName)"
              :alt="partner.name"
              :title="partner.name"
          /></a>
          <div />
        </div>
      </slide>

      <template #addons>
        <navigation>
          <template #prev>
            <ArrowLeftDropCircle size="42" @click="handleArrowClick('prev')" />
          </template>
          <template #next>
            <ArrowRightDropCircle size="42" @click="handleArrowClick('next')" />
          </template>
        </navigation>
      </template>
    </carousel>
  </div>
</template>

<script setup>
import { computed } from "vue";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import ArrowLeftDropCircle from "vue-material-design-icons/ArrowLeftDropCircle.vue";
import ArrowRightDropCircle from "vue-material-design-icons/ArrowRightDropCircle.vue";

const emit = defineEmits("trackEvent");

defineProps({
  titleEnabled: {
    type: Boolean,
    default: true,
  },
});

const partners = computed(() => [
  {
    name: "The Denver Post",
    websiteUrl:
      "https://www.denverpost.com/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Denver_Post_Logo.svg",
  },
  {
    name: "Rocky Mountain PBS",
    websiteUrl:
      "https://www.rmpbs.org/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "RMPBS_Logo.png",
  },
  {
    name: "South Metro Fire",
    websiteUrl:
      "https://www.southmetro.org/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "South Metro Fire_Logo.jpg",
  },
  {
    name: "Senior Hub",
    websiteUrl:
      "https://seniorhub.org/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Senior_Hub_Logo.jpg",
  },
  {
    name: "Age Wise",
    websiteUrl:
      "https://agewisecolorado.org/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Age_Wise_Colorado_Logo.png",
  },
  {
    name: "Arvada Fire",
    websiteUrl:
      "https://www.arvadafireco.gov/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Arvada_Fire_Logo.png",
  },
  {
    name: "Prime Time News",
    websiteUrl:
      "https://www.myprimetimenews.com/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Prime_Time_News_Logo.png",
  },
  {
    name: "Cultivate",
    websiteUrl:
      "https://cultivate.ngo/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Cultivate_Logo.png",
  },
  {
    name: "A Little Help",
    websiteUrl:
      "https://www.alittlehelp.org/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "A_Little_Help_Logo.jpg",
  },
  {
    name: "City and County of Broomfield",
    websiteUrl:
      "https://www.broomfield.org/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Broomfield_Logo.png",
  },
  {
    name: "Broomfield Chamber of Commerce",
    websiteUrl:
      "https://www.broomfieldchamber.com/?utm_source=Nymbl&utm_medium=LP&utm_campaign=Community+partner",
    logoFileName: "Boomfield_Chamber_OF_Commerce_Logo.png",
  },
]);

const getImgUrl = (fileUrl) => {
  return require("../../../assets/img/PartnerLogosDRCOG/" + fileUrl);
};

const handleArrowClick = (arrow) =>
  emit("trackEvent", "Clicked on Community Partner Arrow", {
    arrow,
  });

const handlePartnerClick = (partnerName, partnerWebsiteUrl) =>
  emit("trackEvent", "Clicked on Community Partner Logo", {
    provider: "DRCOG",
    partnerName,
    partnerWebsiteUrl,
  });
</script>
<style lang="scss">
.carousel__next,
.carousel__prev {
  border: none;
  background: none;
  color: lightgray;
}
.carousel__next {
  @media (max-width: 600px) {
    right: 30px;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
  @media (max-width: 1200px) {
    right: 30px;
  }
}
.carousel__prev {
  @media (max-width: 600px) {
    left: 30px;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
  @media (max-width: 1200px) {
    left: 30px;
  }
}
</style>
