import axios from "axios";
import { mapActions } from "pinia";
import { useStore } from "./store.js";

export default {
  methods: {
    ...mapActions(useStore, ["setApiError"]),
    interceptorsSetup() {
      axios.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          const that = this;
          console.log("Error Main:", error);

          let status = null;
          status = error.response ? error.response.status : null;

          // Set the Error Api flag
          this.setApiError(true);

          if (error.response.config.isErrorMessageDisabled) {
            return Promise.reject(error);
          }

          // Unauthorized
          if (status === 401) {
            if (!that.isExpired) {
              that.isExpired = true;
              that.$nMessage.closeAll();
              setTimeout(() => {
                that.$nMessage({
                  type: "error",
                  message: "Session has expired. Please log in again.",
                  showClose: true,
                  duration: 5000,
                });
              }, 400);
              that.logout("expire");
            }

            return Promise.reject(error);
          }

          if (status === 404) {
            return Promise.reject(status);
          }

          // Server Error
          if (error.response && error.response.data.message) {
            that.$nMessage.closeAll();
            that.$nMessage({
              type: "error",
              message: error.response.data.message,
              showClose: true,
              duration: 5000,
            });
            return Promise.reject(error);
          }
          that.$nMessage.closeAll();

          that.$nMessage({
            type: "error",
            message: "API call error",
            showClose: true,
            duration: 5000,
          });

          return Promise.reject(error);
        },
      );
    },
  },
};
