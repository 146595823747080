<script setup>
import { onMounted } from "vue";
import { useStore } from "@/store.js";
import Paragraph from "@/components/Paragraph.vue";
import Link from "@/components/Link.vue";

import { trackEvent } from "@/common/events";
import { useGtm } from "@gtm-support/vue-gtm";

const gtm = useGtm();

const store = useStore();

onMounted(() => {
  trackEvent(
    {
      event: "web_screen_viewed",
      web_screen_name: "solera error",
    },
    gtm,
  );

  store.setLoading(false);
});
</script>
<template>
  <Paragraph class="leading-6 mb-4 mt-10">
    {{
      $t("eligibility.weve-run-into-an-issue-with-one-of-our-data-connections")
    }}
  </Paragraph>
  <Paragraph class="leading-6 mb-4">
    {{ $t("eligibility.please-contact-our-friendly-support-team-for-help") }}
  </Paragraph>
  <Link class="leading-6" href="mailto:support@nymblscience.com">
    support@nymblscience.com
  </Link>
</template>
