const getExternalSegment = () => {
  const host = window.location.host.split(".");

  if (
    host[0].toLowerCase() === "risewest21" ||
    host[0].toLowerCase() === "risewest21-test"
  ) {
    return "RiseWest21";
  }
  return null;
};

export { getExternalSegment };
