<template>
  <section
    :style="`color: ${textColor}`"
    :class="companyName == 'Humana' ? 'md:text-base md:leading-7' : ''"
    class="my-5 md:my-10 text-center text-sm md:text-base leading-5 md:leading-5 px-2"
  >
    <p
      class="font-bold text-xl md:text-4xl mb-2"
      :style="`color: ${titleColor}`"
    >
      {{ title }}
    </p>
    <p class="mb-2 md:text-lg">
      {{ desc }}
    </p>

    <div
      v-if="isSupportPhoneEnabled"
      class="mt-3"
      :style="`color: ${textColor}`"
    >
      {{ phoneNumber }}
    </div>
    <a :style="`color: ${textColor}`" :href="`mailto:${supportEmail}`">
      {{ supportEmail }}</a
    >
    <p v-if="companyName === 'Blue KC'">
      10:00 a.m. - 4:00 p.m. (CT)<br />
      Monday - Friday
    </p>
    <p v-if="companyName === 'Blue MI'">
      Monday - Friday <br />
      10 a.m. - 7 p.m. (ET)
    </p>
    <p v-if="companyName === 'John Hancock'">
      8:00 a.m. - 6:00 p.m. (ET)<br />
      Monday - Friday
    </p>
    <p v-if="companyName === 'Humana' || companyName === 'CarePlus'">
      Monday - Friday<br />
      8:00 a.m. - 5:00 p.m., MT
    </p>
    <p v-if="companyName === 'DRCOG' || companyName === 'Sales Demo'">
      {{ $t("DRCOG.monday-friday") }}<br />
      {{ $t("DRCOG.8-a-m-5-p-m-mt") }}
    </p>
  </section>
</template>

<script>
export default {
  props: {
    companyName: String,
    textColor: String,
    titleColor: String,
  },
  computed: {
    title() {
      return this.$t("DRCOG.need-help");
    },
    desc() {
      if (this.companyName === "John Hancock") {
        return "Our friendly support team is here for you.";
      }
      if (this.companyName === "Blue MI") {
        return "Nymbl’s support team is here for you:";
      }

      return this.$t("nymbls-friendly-support-team-is-here-for-you");
    },
    phoneNumber() {
      if (this.companyName === "John Hancock") {
        return "888-884-0881";
      }
      if (this.companyName === "Blue MI") {
        return "1-800-672-6854 (TTY: 711)";
      }
      if (this.companyName === "DRCOG" || this.companyName === "Sales Demo") {
        return "(800) 672-6854";
      }
      return "(800) 672-6854 (TTY: 711)";
    },
    supportEmail() {
      let email = "support@nymblscience.com";
      return email;
    },

    isSupportPhoneEnabled() {
      let isEnabled = true;

      if (this.companyName === "fallsfree") {
        isEnabled = false;
      }
      return isEnabled;
    },
  },
};
</script>
