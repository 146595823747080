import api from "@/api";
import { useStore } from "@/store.js";
import { v4 as uuid } from "uuid";
import { trackEvent } from "@/common/events";

export default {
  setup() {
    const store = useStore();
    return { store };
  },
  computed: {
    variantAQuery() {
      return this.$route.query["a"] ? this.$route.query["a"] == "true" : null;
    },
    variantBQuery() {
      return this.$route.query["b"] ? this.$route.query["b"] == "true" : null;
    },
    variantCQuery() {
      return this.$route.query["c"] ? this.$route.query["c"] == "true" : null;
    },
  },
  mounted() {
    this.getAnonymousFeatureFlags();
  },
  methods: {
    getAnonymousFeatureFlags() {
      const store = this.store;

      const id = localStorage.getItem("anonymousId") || uuid();
      localStorage.setItem("anonymousId", id);

      api.get("/System/FeatureFlagsSet/" + id).then(({ data }) => {
        store.anonymousFeatureFlags = this.overrideFeatureFlags(data);

        trackEvent(
          {
            event: "userCent",
            value: data.anonymousCent,
          },
          this.$gtm,
        );
      });
    },
    overrideFeatureFlags(data) {
      // Override for testing.

      if (this.variantAQuery !== null) {
        data.sysFlags["Anon.DrCogLanding202208_A"] = this.variantAQuery;
      }

      if (this.variantBQuery !== null) {
        data.sysFlags["Anon.DrCogLanding202208_B"] = this.variantBQuery;
      }

      if (this.variantCQuery !== null) {
        data.sysFlags["Anon.DrCogLanding202208_C"] = this.variantCQuery;
      }

      return data;
    },
  },
};
