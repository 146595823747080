<template>
  <div
    style="height: auto"
    class="mx-6 pt-1 shadow text-center bg-white pl-8 pr-8 mt-0 mb-14 lg:mb-0 lg:mt-10 md:mx-0 md:pt-0 lg:max-h-64"
  >
    <div class="transform -translate-y-10 lg:-translate-y-32">
      <img
        style="max-width: 250px !important"
        class="rounded-full m-auto"
        :src="getImgUrl(img)"
      />
      <p class="leading-5 mt-2 md:mt-4 font-semibold">“{{ review }}”</p>
      <h3 class="leading-6 mb-5 mt-5">
        {{ name }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    img: String,
    review: String,
  },
  methods: {
    getImgUrl(pic) {
      return require("../../../assets/img/FallsFree/" + pic);
    },
  },
};
</script>
