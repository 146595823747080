<template>
  <legal-container
    :url="
      $i18n.locale === 'es'
        ? 'https://nymblscience.com/tos-es/'
        : 'https://nymblscience.com/tos'
    "
    title="nymbl terms and conditions of Use"
  />
</template>

<script setup>
import LegalContainer from "./LegalContainer.vue";

import { onBeforeRouteLeave } from "vue-router";

const emit = defineEmits(["close"]);

onBeforeRouteLeave((to, from, next) => {
  emit("close");
  next();
});
</script>
