<template>
  <div
    id="eligibility-header"
    class="p-0 pt-0 mt-0 md:w-10/12 md:mx-auto pb-1 text-center"
  >
    <h1
      v-if="!hasNoTitle"
      class="mt-2 text-xs mb-1 pt-0 leading-5 font-bold min-w-full text-center"
      :class="lg ? 'text-lg' : 'md:text-base'"
    >
      {{ title }}
    </h1>
    <p
      v-if="desc && !hasNoBody"
      class="text-xs p-2 pb-0 mb-0 mt-0 text-center"
      :class="lg ? 'text-lg' : 'md:text-base'"
    >
      {{ desc }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    desc: {
      type: String,
      default: "",
    },
    lg: {
      type: Boolean,
      default: false,
    },
    hasNoBody: {
      type: Boolean,
      default: false,
    },
    hasNoTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
