<template>
  <div
    class="w-full shadow rounded-xl text-center text-white bg-green-100 px-1 md:px-8 mt-12 md:mt-0"
    style="max-width: 500px; margin: 0 auto"
  >
    <div class="transform -translate-y-8">
      <img
        class="rounded-full m-auto border-2 border-green-100"
        :src="getImgUrl(img)"
        style="max-width: 240px !important"
      />
      <p class="leading-5 mt-3 mb-2">“{{ review }}”</p>
      <h3 class="font-bold text-sm leading-6">
        {{ name }}
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    img: String,
    review: String,
  },
  methods: {
    getImgUrl(pic) {
      return require("../../../assets/img/FallsFree/" + pic);
    },
  },
};
</script>
