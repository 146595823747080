<script setup>
import { onMounted, ref } from "vue";
import { removeSplashScreen, goToDeepLink } from "@/helpers";
import { getMobileDevice } from "@/scripts/getMobileDevice.js";
import { useRouter } from "vue-router";
import LandingNav from "./LandingNav.vue";
import LandingPartnersDRCOG from "../LandingPartnersDRCOG.vue";
import FreeTipsSubscribe from "./FreeTipsSubscribe.vue";

import LandingVideo from "../../LandingVideo.vue";
import Headline from "../../../../components/Headline.vue";
import ArrowUpDropCircle from "vue-material-design-icons/ArrowUpDropCircle.vue";

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  removeSplashScreen();
});

const scrollTopTimer = ref(0);
const scrollTopY = ref(0);

const handleScroll = () => {
  if (scrollTopTimer.value) return;
  scrollTopTimer.value = setTimeout(() => {
    scrollTopY.value = window.scrollY;
    clearTimeout(scrollTopTimer);
    scrollTopTimer.value = 0;
  }, 100);
};

const toTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const router = useRouter();

const callToAction = () => {
  const isMobile = getMobileDevice() === null ? false : true;
  isMobile ? goToDeepLink() : router.push({ name: "LandingPageDRCOG" });
};

const colors = {
  primary: "#084D33",
  clay: "#E0E3BB",
  plum: "#B9336A",
};
</script>
<template>
  <div
    :style="{
      background: '#fff',
      minHeight: 'calc(100vh - 10px)',
      maxWidth: '2800px',
      margin: '0 auto',
    }"
    class="lg:mt-10"
  >
    <landing-nav :colors="colors" />

    <section id="tips" class="pt-4 pb-8 sm:pt-8 sm:pb-8 lg:mt-24">
      <div
        :style="{
          maxWidth: '1280px',
        }"
        class="mx-auto flex flex-col sm:flex-row text-center sm:justify-between text-sm sm:text-base md:text-lg xl:text-xl px-1 sm:px-3 md:px-10"
      >
        <span
          >Local Coloradans available to answer your questions, 8am-5pm MT</span
        >
        <a class="mt-2 sm:mt-0" href="tel:(800) 672-6854">(800) 672-6854</a>
      </div>
    </section>

    <section class="">
      <div class="grid grid-cols-1 xl:grid-cols-2">
        <div class="order-last xl:order-first">
          <img src="./nymbl-happy-family.jpg" alt="Free Tips" />
        </div>
        <div
          class="p-6 lg:p-10 xl:p-10"
          :style="{
            background: colors.clay,
          }"
        >
          <Headline
            :style="{ color: colors.primary + '!important' }"
            class="text-center xl:text-left text-lg md:text-xl lg:text-5xl mb-1 lg:mb-4 xl:mb-6"
          >
            Be empowered.
          </Headline>
          <Headline
            :style="{ color: colors.primary + '!important' }"
            class="text-center xl:text-left text-lg md:text-xl lg:text-5xl mb-1 lg:mb-4 xl:mb-6"
          >
            Be balanced.
          </Headline>
          <Headline
            :style="{ color: colors.primary + '!important' }"
            class="text-center xl:text-left text-lg md:text-xl lg:text-5xl mb-4 lg:mb-6 xl:mb-8"
          >
            Be Nymbl.
          </Headline>
          <p
            class="max-w-sm mx-auto md:max-w-md lg:max-w-xl xl:mx-0 text-center xl:text-left text-base leading-5 md:leading-8 md:text-lg"
          >
            Stay healthy and active with free access to Nymbl’s education emails
            with health tips from Nymbl’s fall prevention expert and physical
            therapist, Dr. Nathan Estrada.
          </p>
          <div class="flex justify-center items-center xl:justify-start pt-8">
            <FreeTipsSubscribe :colors="colors" />
          </div>
        </div>
      </div>
    </section>

    <section class="pt-0 pb-14 text-center">
      <img
        src="../../../../assets/img/FallsFree/2023_LOGO_DRCOG_NYMBL.png"
        alt="DRCOG and Nymbl logos"
        class="px-6 md:px-0 mx-auto"
      />
      <h4
        :style="{ maxWidth: '800px' }"
        class="px-10 text-lg md:text-4xl text-center mx-auto"
      >
        Nymbl and Denver Regional Council of Governments have teamed up to keep
        you thriving.
      </h4>
    </section>

    <section
      id="testimonials"
      class="p-10"
      :style="{
        background: colors.clay,
      }"
    >
      <Headline
        :style="{ color: colors.primary + '!important' }"
        class="text-lg md:text-xl lg:text-5xl text-center"
      >
        Weekly tips to keep you on your feet.
      </Headline>

      <div
        class="mx-auto"
        :style="{
          maxWidth: '1600px',
        }"
      >
        <div class="grid grid-cols-1 lg:grid-cols-2 w-full py-10">
          <div
            class="max-w-sm lg:max-w-none mx-auto lg:border-r lg:mr-10 flex flex-col lg:flex-row"
          >
            <div>
              <img
                class="rounded-full mx-auto w-32 h-32 lg:h-48 lg:w-48 mb-2 lg:mb-0"
                src="./dianne-nymbl.jpg"
              />
            </div>
            <div class="text-center lg:text-left">
              <div
                style="max-width: 420px"
                class="flex text-center lg:text-left lg:mr-20"
              >
                <span class="text-8xl font-bold" :style="{ color: colors.plum }"
                  >“</span
                >
                <p
                  class="font-bold text-base leading-6 md:leading-7 md:text-lg px-2"
                >
                  Nymbl has helped me regain confidence in my mobility.
                </p>
                <span
                  class="text-8xl font-bold self-end transform -translate-x-2 md:-translate-x-0 lg:translate-y-3"
                  style="line-height: 0 !important"
                  :style="{ color: colors.plum }"
                  >”</span
                >
              </div>
              <span class="lg:ml-6 mt-4 w-full font-bold md:text-lg"
                >- Dianne E., Nymbl Member</span
              >
              <div
                :style="{ color: colors.plum }"
                class="mt-2 lg:mt-2 lg:ml-8 text-center lg:text-left text-2xl lg:text-6xl"
              >
                ★★★★★
              </div>
            </div>
          </div>

          <div class="max-w-sm lg:max-w-lg mx-auto lg:mx-0">
            <h4
              class="mt-6 lg:mt-2 mb-4 lg:mb-6 lg:text-lg text-center lg:text-left"
            >
              Free weekly tips from experts.
            </h4>
            <FreeTipsSubscribe :colors="colors" />
          </div>
        </div>
        <div
          class="max-w-md lg:max-w-none mx-auto flex flex-col lg:flex-row text-center sm:text-left lg:text-center lg:justify-center"
        >
          <span class="text-5xl lg:text-8xl font-bold">90%</span>
          <p class="text-base md:text-xl lg:ml-8 md:leading-8 text-left">
            have maintained or increased their engagement<br
              class="hidden lg:block"
            />
            with activities they love since using Nymbl.
          </p>
        </div>
      </div>
    </section>

    <section id="partners" class="py-12 text-center">
      <Headline
        :style="{ color: colors.primary + '!important' }"
        class="text-lg md:text-xl lg:text-5xl text-center"
      >
        Trusted by the pros. Trusted by you.
      </Headline>

      <LandingPartnersDRCOG :title-enabled="false" />
    </section>

    <section
      id="get"
      :style="{
        background: colors.clay,
      }"
      class="p-10"
    >
      <Headline
        :style="{
          lineHeight: '1.3!important',
          color: colors.primary + '!important',
        }"
        class="text-lg md:text-xl lg:text-5xl text-center leading-16"
      >
        Ready to go beyond email?
      </Headline>

      <div
        :style="{ maxWidth: '1600px' }"
        class="grid grid-cols-1 lg:grid-cols-2 mx-auto"
      >
        <div class="px-4 flex justify-center items-center">
          <div>
            <Headline
              :style="{
                color: colors.plum + '!important',
              }"
              class="text-center md:text-left text-6xl md:text-8xl mt-10 mb-10"
            >
              25,000+
            </Headline>
            <Headline
              :style="{
                color: colors.plum + '!important',
              }"
              class="text-center md:text-left md:text-lg mb-8 mt-4"
            >
              Adults doing Nymbl in the Greater Denver Area
            </Headline>
            <p class="md:text-lg mb-8">
              Join thousands of Coloradans who are improving their balance at
              home, for free! In addition to Nymbl’s free emails, older adults
              60+ in the Greater Denver area have free access to Nymbl’s fun and
              scientifically proven balance training app.
            </p>
            <div class="text-center lg:text-left">
              <n-button
                :style="`background-color: ${colors.plum}!important`"
                class="lg:mt-24 mx-auto sm:w-auto mb-4 sm:mb-6 xl:mt-0 lg:w-auto self-start md:text-lg py-4"
                rounded-xxl
                @click="callToAction"
              >
                Get the free Nymbl app
              </n-button>
            </div>
          </div>
        </div>

        <div class="p-2 lg:p-20">
          <landing-video
            video-id="849266118"
            :video-key="null"
            :company-name="'DRCOG'"
          />
        </div>
      </div>
    </section>

    <section id="about" class="py-12 flex">
      <div
        :style="{ maxWidth: '1600px' }"
        class="mx-auto grid grid-cols-1 lg:grid-cols-2"
      >
        <div
          class="py-2 px-8 mb-2 md:mb-0 lg:p-20 flex justify-center items-center"
        >
          <img
            class="max-w-auto md:max-w-sm"
            src="./drcog-logo-nymbl.svg"
            alt="Denver Regional Council of Governments (DRCOG) logo"
          />
        </div>
        <div class="px-8 max-w-sm lg:max-w-none">
          <Headline
            :style="{
              lineHeight: '1.3!important',
              color: colors.primary + '!important',
            }"
            class="text-lg md:text-xl lg:text-5xl"
          >
            About your local Denver Regional Council of Governments (DRCOG)
          </Headline>

          <p class="text-base md:text-lg mt-4 md:mt-8 lg:leading-8">
            The Denver Regional Council of Governments is a planning
            organization where local governments collaborate to establish
            guidelines, set policy and allocate funding in the areas of
            transportation and personal mobility; growth and development; and
            aging and disability resources.
          </p>

          <p class="text-base md:text-lg mt-4 md:mt-8 lg:leading-8">
            DRCOG has teamed up with Nymbl to provide free access to Nymbl’s
            scientifcally proven program, so Denver adults can live thriving and
            independent lives.
          </p>
        </div>
      </div>
    </section>

    <section
      :style="{
        background: colors.clay,
      }"
      class="py-12"
    >
      <Headline
        :style="{
          color: colors.primary + '!important',
        }"
        class="text-lg md:text-xl lg:text-5xl text-center mb-2"
      >
        Reach out to our friendly Support team.
      </Headline>
      <p class="text-lg md:text-xl text-center mb-2 mt-8">
        <a href="tel:(800) 672-6854" class="mb-2">(800) 672-6854</a><br />
        <a href="mailto:support@nymblscience.com">support@nymblscience.com</a
        ><br />
        Monday - Friday<br />
        8 am - 5 pm MT
      </p>
    </section>

    <section class="py-12">
      <Headline
        :style="{
          color: colors.primary + '!important',
        }"
        class="mb-2 text-lg md:text-xl lg:text-5xl text-center"
      >
        Let's keep moving with Nymbl.
      </Headline>
      <div class="flex justify-center py-10">
        <FreeTipsSubscribe :colors="colors" />
      </div>
    </section>

    <footer class="px-2 text-white" :style="{ backgroundColor: '#3c3d41' }">
      <div
        :style="{ maxWidth: '1400px' }"
        class="grid md:grid-cols-2 lg:grid-cols-4 mx-auto py-10 px-10"
      >
        <div class="mb-6 lg:mb-0 text-gray-200">
          <a href="https://nymblscience.com" target="_blank" rel="noopener">
            <img
              class="w-20 md:w-32 mb-4"
              src="../../../../assets/img/nymblscience-logo.svg"
          /></a>
          <p class="mb-4">
            <a href="https://nymblscience.com">Nymbl Science, Inc.</a>
          </p>
          <p class="mb-4">
            <span>2000 S. Colorado Blvd.<br /></span>Denver, Colorado 80222
          </p>
          <p>
            © {{ new Date().getFullYear() }}
            <a href="https://nymblscience.com">Nymbl Science, Inc.</a>
          </p>
        </div>
        <div class="hidden lg:block" />
        <div class="mb-6">
          <h3 class="mb-4 text-gray-150">REFERENCE</h3>
          <ul>
            <li class="mb-2">
              <a
                href="https://nymblscience.com/nymbl-science-terms-and-conditions-2021/"
                >Terms and Conditions</a
              >
            </li>
            <li>
              <a href="https://nymblscience.com/privacy/">Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div>
          <h3 class="mb-4 text-gray-150">NEED HELP?</h3>
          <ul>
            <li class="mb-2">
              <a href="https://nymblscience.com/nymbl-schedule-consultation/"
                >Schedule a Consultation</a
              >
            </li>
            <li>
              <a href="https://nymblscience.com/contact-2021/"
                >Contact Support</a
              >
            </li>
          </ul>
        </div>
      </div>
    </footer>
    <transition name="fade">
      <div
        v-show="scrollTopY > 300"
        id="pagetop"
        class="hidden md:block fixed left-0 bottom-0 cursor-pointer ml-4 mb-4 z-50"
        @click="toTop"
      >
        <div
          :style="{
            background: '#fff',
            width: '58px',
            height: '58px',
            borderRadius: '50%',
          }"
          class="flex items-center justify-center rounded-full"
        >
          <ArrowUpDropCircle
            size="66"
            :fill-color="colors.plum"
            @click="handleArrowClick('prev')"
          />
        </div>
      </div>
    </transition>
  </div>
</template>
<style>
body {
  padding-bottom: 0 !important;
}
</style>
