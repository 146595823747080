<template>
  <hr :style="`background: ${color}; height: 0.2083rem; border: none`" />
</template>

<script>
export default {
  props: {
    color: String,
  },
};
</script>
