<script setup>
import { ref } from "vue";
import FormEmail from "@/components/Form/FormEmail.vue";
import api from "@/api";
defineProps({
  colors: {
    type: Object,
    required: true,
  },
});

const isFormInvalid = ref(false);
const email = ref(null);

const success = ref(false);
const loading = ref(false);

const subscribe = () => {
  loading.value = true;

  api
    .post("/MarketingEmail", null, {
      emailAddress: email.value,
      companyName: "DRCOG",
    })
    .then(() => {
      success.value = true;
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};
</script>
<template>
  <div class="flex flex-col">
    <div class="flex flex-col sm:flex-row items-center justify-center">
      <form-email
        v-model="email"
        class="sm:mr-10 free-tips-subscribe"
        :label="null"
        @update:is-form-invalid="isFormInvalid = $event"
        @keydown.enter="subscribe"
      />

      <n-button
        :style="`background-color: ${colors.plum}!important`"
        class="xl:mt-0 mx-auto sm:w-auto mb-4 sm:mb-6 lg:w-auto self-start md:text-lg py-4"
        rounded-xl
        :disabled="isFormInvalid && email !== null"
        :loading="loading"
        @click="subscribe"
      >
        Get free tips
      </n-button>
    </div>
    <transition name="fade">
      <div
        v-if="success"
        :style="{
          width: '200px',
        }"
        class="text-center justify-center px-2 py-3 mx-auto min-w-min bg-green-50 border border-green-200 flex items-center font-medium rounded-md mt-0"
      >
        Subscribed!
      </div>
    </transition>
  </div>
</template>
<style lang="scss">
.free-tips-subscribe {
  input {
    background: white !important;
    border: 1px solid gray !important;
    padding: 30px 30px !important;
  }
}
</style>
